import Vue from 'vue';
import Router from 'vue-router';

import FormsPage from '../components/FormsPage'
import SearchPage from '../components/SearchPage'
import StatisticsPage from '../components/StatisticsPage'
import DdmStatisticsPage from '../components/DdmStatisticsPage'
import AdminPage from '../components/AdminPage'
import LoginPage from '../components/LoginPage'
import NewPassword from "../components/NewPassword";
import { store } from "../store/index";

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login/:param?',
      name: 'login',
      component: LoginPage,
      beforeEnter: (to, from , next) => {
        // reset all stores on navigation to login
        store.commit('reset')
        next()
      }
    },
    { path: '/password-lost', redirect: '/login/password-lost' },
    { path: '/register', redirect: '/login/register' },
    { path: '/verzeichnis/:org_id?/:user_id?', name: 'verzeichnis', component: FormsPage },
    { path: '/suche', name: 'suche', component: SearchPage },
    { path: '/statistik', name: 'statistik', component: StatisticsPage },
    { path: '/ddm-statistik', name: 'ddm-statistik', component: DdmStatisticsPage },
    { path: '/admin', name: 'admin', component: AdminPage },
    { path: '/new-password/:token?', name: 'new-password', component: NewPassword },

    // otherwise redirect to home
    { path: '*', redirect: '/verzeichnis' }
  ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['login', 'new-password'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});
