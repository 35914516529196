import { render, staticRenderFns } from "./AccountCreationStats.vue?vue&type=template&id=8d63216e&scoped=true&"
import script from "./AccountCreationStats.vue?vue&type=script&lang=js&"
export * from "./AccountCreationStats.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d63216e",
  null
  
)

export default component.exports