import { passwordsService } from '../../services';

const state = {
  tokenValid: false,
  requestSuccess: false,
  changeSuccess: false
};

const actions = {
  request({ dispatch, commit }, data) {
    commit('requestSuccess', false)
    passwordsService.request(data)
      .then(
        () => {
          commit('requestSuccess', true)
          dispatch('alert/success', `Neues Passwort versendet.`, { root: true });
        },
        (res) => {
          commit('requestSuccess', false)
          dispatch(
            'alert/error',
            res && 'string' === typeof res && res.length > 0 ? res : `Fehler beim Versenden des neuen Passworts`,
            { root: true }
          );
        }
      );
  },

  change({ dispatch, commit }, data) {
    commit('changeSuccess', false)
    passwordsService.change(data)
      .then(
        () => {
          commit('changeSuccess', true)
          dispatch('alert/success', `Passwort gespeichert.`, { root: true });
        },
        () => {
          commit('changeSuccess', false)
          dispatch('alert/error', `Fehler beim Speichern des neuen Passworts`, { root: true });
        }
      )
  },

  checkToken({ dispatch, commit }, token) {
    passwordsService.checkToken(token)
      .then(
        () => {
          commit('tokenValid', true)
        },
        () => {
          commit('tokenValid', false)
          dispatch('alert/error', `Link ist ungültig oder wurde bereits verwendet.`, { root: true });
        }
      );
  },
};

const mutations = {
  tokenValid(state, valid) {
    state.tokenValid = valid;
  },
  requestSuccess(state, valid) {
    state.requestSuccess = valid;
  },
  changeSuccess(state, valid) {
    state.changeSuccess = valid;
  }
};

export const passwords = {
  namespaced: true,
  state,
  actions,
  mutations
};
