<template>
  <div>
    <v-row>
      <v-col cols="12">
        <d-combobox
          label="Funktionen"
          outlined
          multiple
          hide-selected
          chips
          deletable-chips
          return-object
          :search-input.sync="functionsSearch"
          item-text="name"
          v-model="associatedFunctions"
          :disabled="!canEditTasksAndFunktions"
          :items="functions"
          @blur.stop="saveFunctions"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Keinen Eintrag für "<strong>{{ functionsSearch }}</strong>" gefunden. Drücken Sie <kbd>enter</kbd> um einen zu erzeugen.
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </d-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <d-combobox
          label="Ansprechpartner für"
          outlined
          multiple
          hide-selected
          chips
          deletable-chips
          return-object
          :search-input.sync="tasksSearch"
          item-text="name"
          v-model="associatedTasks"
          :disabled="!canEditTasksAndFunktions"
          :items="tasks"
          @blur.stop="saveTasks"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Keinen Eintrag für "<strong>{{ tasksSearch }}</strong>" gefunden. Drücken Sie <kbd>enter</kbd> um einen zu erzeugen.
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </d-combobox>
      </v-col>
    </v-row>
    <v-form ref="jobtype" @input="validateJobType">
      <v-row class="px-2">
        <v-col cols="6">
          <d-checkbox
            label="Ehrenamtlich"
            ref="volunteer"
            v-model="volunteer"
            :rules="volunteerRules"
            @click.stop="updateVolunteer()"
          ></d-checkbox>
        </v-col>
        <v-col cols="6">
          <d-checkbox
            label="Hauptamtlich"
            ref="fulltime"
            v-model="fulltime"
            :rules="fulltimeRules"
            @click.stop="updateFulltime()"
          ></d-checkbox>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12">
        <d-autocomplete
          label="F.-Titel"
          hide-details
          v-model="title"
          :items="functionTitle"
          @input="updateFTitle"
        ></d-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <d-text-field label="Bemerkung" hide-details v-model="notes" @blur="updateNotes"></d-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { filter } from 'lodash'

export default {
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      associatedTasks: [],
      tasksSearch: null,

      associatedFunctions: [],
      functionsSearch: null,

      volunteer: 0,
      fulltime: 0,

      title: null,

      notes: null,

      volunteerRules: [
        (v) => {
          return !!v || !!this.fulltime || 'Mindestens eins muss ausgewählt werden'
        }
      ],
      fulltimeRules: [
        (v) => {
          return !!v || !!this.volunteer || ''
        }
      ],

      lastUser: null
    };
  },
  computed: {
    ...mapState({
      rawFunctionTitle: state => state.option.function_title,
      tasks: state => state.tasks.list,
      functions: state => state.functions.list,
      user: state => state.users.checked,
      acl: state => state.account.acl
    }),
    functionTitle() {
      return this.rawFunctionTitle.map((val, idx) => {
        return {
          text: val,
          value: idx
        }
      })
    },
    canEditTasksAndFunktions() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.user_funktion_edit;
    },
  },
  methods: {
    ...mapActions('option', ['loadOption']),
    ...mapActions('users', ['update']),
    ...mapActions('tasks', {'viewTasks': 'view', 'updateTasks': 'update'}),
    ...mapActions('functions', {'viewFunctions': 'view', 'updateFunctions': 'update'}),
    validateJobType() {
      if (this.$refs.jobtype !== undefined) {
        this.$refs.jobtype.validate();
      }
    },
    updateVolunteer() {
      if (this.$refs.jobtype.validate() && this.user) {
        this.update({
          id: this.user.user.id.value,
          honorary: this.volunteer ? 1 : 0
        })
      }
    },
    updateFulltime() {
      if (this.$refs.jobtype.validate() && this.user) {
        this.update({
          id: this.user.user.id.value,
          fulltime: this.fulltime ? 1 : 0
        })
      }
    },
    saveTasks() {
      this.updateTasks({userId: this.user.user.id.value, tasks: this.associatedTasks});
    },
    saveFunctions() {
      this.updateFunctions({userId: this.user.user.id.value, functions: this.associatedFunctions});
    },
    updateFTitle(title) {
      this.update({
        id: this.user.user.id.value,
        function_title: title
      })
    },
    updateNotes() {
      this.update({
        id: this.user.user.id.value,
        'user.comment': this.notes
      })
    },
    loadData() {
      this.lastUser = this.user.user.id.value;
      this.viewTasks(this.user.user.id.value);
      this.viewFunctions(this.user.user.id.value);
      this.notes = this.user.user.comment.value;
      this.title = this.user.user.function_title.value;
      this.volunteer = this.user.person.honorary.value;
      this.fulltime = this.user.person.fulltime.value;
    }
  },
  created () {
    this.loadOption({option: 'function_title'});
  },
  watch: {
    active: {
      immediate: true,
      handler(val) {
        if (val && this.user.user.id.value !== this.lastUser) {
          this.loadData();
        }
      }
    },
    user() {
      if (this.active && this.user.user.id.value !== this.lastUser) {
        this.loadData()
      }
    },
    tasks(tasks) {
      this.associatedTasks = filter(tasks, 'selected');
    },
    functions(functions) {
      this.associatedFunctions = filter(functions, 'selected');
    }
  }
}
</script>
