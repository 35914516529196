<template>
<d-action-dialog
  v-bind:value="value"
  v-on:input="$emit('input', $event)"
  :onSave="onSave"
  confirmText="Löschen"
  denyText="Abbrechen"
>
  <template slot="title">
    Benutzer löschen
  </template>
  <template>
    Möchten Sie {{ name !== undefined ? 'den Benutzer `' + name + '`' : 'diesen Benutzer' }} wirklich löschen?
  </template>
</d-action-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    id: {
      type: Number,
      required: false
    },
    name: {
      type: String,
      required: false
    }
  },
  methods: {
    ...mapActions('users', ['delete']),
    onSave() {
      this.delete(this.id);
    }
  }
}
</script>
