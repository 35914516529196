<style scoped>
.v-form >>> .item-layer-bottom {
  background-color: #f7f9fb;
  padding-left: 24px;
}
.v-form >>> .module-form-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.v-form >>> .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
.v-form >>> .v-input--radio-group {
  padding-top: 0;
}
.v-form >>> .row {
  margin-left: -16px;
  margin-right: -16px;
}
</style>

<template>
  <d-expansion-panel>
    <template slot="header">
      <v-toolbar-title
        :class="activeSwitchDisabled ? 'grey--text text--lighten-1' : null"
      >{{ value.longname }}</v-toolbar-title>
      <div v-if="info">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              small
              class="mx-2"
              v-on="on"
            >
              <v-icon color="warning">info</v-icon>
            </v-btn>
          </template>
          <span>{{ info }}</span>
        </v-tooltip>
      </div>
      <v-spacer></v-spacer>
      <div v-if="isActiveSwitch">
        <v-switch
          v-if="!activeSwitchDisabled"
          @click.stop="changeIsActive"
          hide-details
          v-model="value.isActive"
        ></v-switch>
        <v-switch
          v-else
          hide-details
          disabled
          color="black"
          v-model="pretendTrue"
        ></v-switch>
      </div>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon small class="mr-2" v-on="on"><v-icon small>info</v-icon></v-btn>
        </template>
        <span v-html="value.log"></span>
      </v-tooltip>
      <v-icon :disabled="value.isLocked || !$slots.default" small :color="edited ? 'primary' : undefined">save</v-icon>
    </template>
    <template v-if="$slots.default && !activeSwitchDisabled">
      <v-form :disabled="value.isLocked">
        <slot></slot>
      </v-form>
      <v-footer v-if="!value.isLocked" class="px-0" color="white">
        <v-spacer></v-spacer>
        <v-btn :disabled="!edited" @click="save" class="mr-2 mt-1" color="primary">Speichern</v-btn>
      </v-footer>
    </template>
  </d-expansion-panel>
</template>

<script>
import { mapState } from 'vuex'
import { moduleService } from '../../../services';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    info: {
      type: String,
      required: false,
    },
    isActiveSwitch: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      edited: false,
      initialValue: null,
      pretendTrue: false
    }
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl,
      checked: state => state.users.checked
    }),
    canActivateModules() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_general_activate;
    },
    activeSwitchDisabled() {
      return !this.canActivateModules || this.value.isLocked
    }
  },
  methods: {
    save() {
      if (this.edited) {
        moduleService.update(this.name, this.value, this.checked.user.id.value)
        .then(
          () => {
            this.createReferenceValue()
          },
          (res) => {
            this.$store.dispatch('alert/error', res, { root: true })
          }
        );
      }
    },
    changeIsActive() {
      moduleService.update(
        this.name,
        {isActive: this.value.isActive},
        this.checked.user.id.value
      );
    },
    createReferenceValue() {
      this.initialValue = this.$_.omit(this.$_.cloneDeep(this.value), ['isActive']);
    },
    compareCurValToRefVal() {
      const newVal = this.$_.omit(this.value, ['isActive']);
      // only set edited, if some other key than isActive got changed
      // change on isActive is requested to backend immediately
      this.edited = !this.$_.isEqual(newVal, this.initialValue);
    },
  },
  mounted () {
    this.createReferenceValue()
  },
  watch: {
    initialValue: {
      deep: true,
      handler() {
        this.compareCurValToRefVal()
      }
    },
    value: {
      deep: true,
      handler() {
        this.compareCurValToRefVal()
      }
    }
  }
}
</script>
