<template>
  <module-template
    :name="name"
    v-model="value"
    :is-active-switch="false"
  >
    <module-permissions
      v-for="(item, key) in filteredItem"
      :key="key"
      v-model="item.item"
      :role="value.role"
      :options="value.options"
      @role-changed="onRoleChanged"
    >
      <template v-slot:header-tools>
        <v-switch
          @click.stop="changeIsActive"
          hide-details
          :disabled="!canActivateModules || value.isLocked"
          v-model="value.options.active[item.item.short]"
        ></v-switch>
      </template>
    </module-permissions>
  </module-template>
</template>

<script>

// @todo active/inactive makes no sense here, its configured per schedtool part

import { mapState } from 'vuex'
import moduleTemplate from './module-template'
import modulePermissions from './module-permissions'
import {moduleService} from "../../../services";

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    moduleTemplate,
    modulePermissions
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl,
      checked: state => state.users.checked
    }),
    canEditPermissions() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_authorization_edit;
    },
    canActivateModules() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_general_activate;
    },
    filteredItem() {
      return this.value.module.map((i) => {return {item: i}});
    }
  },
  methods: {
    onRoleChanged(localState) {
      this.value.options.role[localState.short] = localState.value
    },
    changeIsActive() {
      moduleService.update(
        this.name,
        {options: this.value.options},
        this.checked.user.id.value
      );
    }
  }
}
</script>
