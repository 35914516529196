<template>
  <div>
    <v-card>
      <v-card-title>DDM Gliederung Export</v-card-title>
      <div class="fill-width pr-5">
        <v-row>
          <v-col cols="6">
            <v-subheader>verfügbare Bedingungen</v-subheader>
            <v-list two-line dense>
              <drop-list :items="conditions" @reorder="$event.apply(conditions)" @insert="insertCondition" mode="cut">
                <template v-slot:item="{item, reorder}">
                  <drag :key="item.title" :data="item" @cut="remove(conditions, item)">
                    <v-list-item
                      style="background-color: white"
                      :style="reorder ? {borderLeft: '2px solid #1976D2', marginLeft:'-2px'} : {}"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-html="item.title"/>
                        <v-list-item-subtitle v-html="item.subtitle"/>
                        <v-text-field
                          v-if="item.editable && 'text' === item.editable.type"
                          dense
                          filled
                          v-model="item.value"
                        ></v-text-field>
                        <v-autocomplete
                          v-if="item.editable && ('modules-active-ac' === item.editable.type || 'modules-inactive-ac' === item.editable.type)"
                          dense
                          filled
                          chips
                          deletable-chips
                          multiple
                          hide-no-data
                          hide-selected
                          return-object
                          item-text="text"
                          item-value="value"
                          :items="moduleNameItems"
                          v-model="item.value"
                        ></v-autocomplete>
                        <v-select
                          v-if="item.editable && 'rate_group-select' === item.editable.type"
                          dense
                          filled
                          :items="rateGroupItems"
                          v-model="item.value"
                        ></v-select>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider/>
                  </drag>
                </template>
                <template v-slot:feedback="{data}">
                  <v-skeleton-loader
                    type="list-item-two-line"
                    :key="data.title"
                    style="border-left: 2px solid #1976D2; margin-left: -2px;"
                  />
                </template>
              </drop-list>
            </v-list>
          </v-col>
          <v-col cols="6" class="d-flex flex-column">
            <v-row class="flex-grow-0">
              <v-col cols="12">
                <v-card elevation="1">
                  <v-card-subtitle>alle müssen erfüllt werden (Schnittmenge)</v-card-subtitle>
                  <drop-list
                    :items="mustMatch"
                    @reorder="$event.apply(mustMatch)"
                    @insert="insertMustMatch"
                    mode="cut"
                    class="chip-list blue-grey lighten-5 pa-5"
                  >
                    <template v-slot:item="{item,reorder}">
                      <drag :key="item.title" class="chip" :data="item" @cut="remove(mustMatch, item)">
                        <v-chip :color="reorder ? 'primary' : null">
                          {{item.title}}
                          <span v-if="item.editable" class="ml-2">({{getValue(item.value, item.key)}})</span>
                        </v-chip>
                      </drag>
                    </template>
                    <template v-slot:feedback="{data}">
                      <div class="chip" :key="data.title">
                        <v-chip color="primary">{{data.title}}</v-chip>
                      </div>
                    </template>
                    <template v-slot:inserting-drag-image="{data}">
                      <v-chip :key="data.title" style="transform: translate(-50%, -50%)">{{data.title}}
                      </v-chip>
                    </template>
                  </drop-list>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="flex-grow-0">
              <v-col cols="12">
                <v-card elevation="1">
                  <v-card-subtitle>keine darf erfüllt werden (Vereinigungsmenge)</v-card-subtitle>
                  <drop-list
                    :items="mustNotMatch"
                    @reorder="$event.apply(mustNotMatch)"
                    @insert="insertMustNotMatch"
                    mode="cut"
                    class="chip-list blue-grey lighten-5 pa-5"
                  >
                    <template v-slot:item="{item,reorder}">
                      <drag :key="item.title" class="chip" :data="item" @cut="remove(mustNotMatch, item)">
                        <v-chip :color="reorder ? 'primary' : null">
                          {{item.title}}
                          <span v-if="item.editable" class="ml-2">({{getValue(item.value, item.key)}})</span>
                        </v-chip>
                      </drag>
                    </template>
                    <template v-slot:feedback="{data}">
                      <div class="chip" :key="data.title">
                        <v-chip color="primary">{{data.title}}</v-chip>
                      </div>
                    </template>
                    <template v-slot:inserting-drag-image="{data}">
                      <v-chip :key="data.title" style="transform: translate(-50%, -50%)">{{data.title}}
                      </v-chip>
                    </template>
                  </drop-list>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="flex-grow-0">
              <v-col cols="12">
                <div class="d-flex">
                  <v-spacer />
                  <v-btn @click.stop="onExport">Exportieren</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <export-dialog
      v-model="exportDialog"
      :exportItem="exportRec"
    ></export-dialog>
  </div>
</template>

<script>
import ExportDialog from "../ExportDialog";
import {DropList, Drag} from "vue-easy-dnd";
import {mapActions, mapState} from "vuex";

export default {
  name: "DdmOrgExport",
  components: {
    ExportDialog,
    DropList,
    Drag
  },
  data () {
    return {
      exportDialog: false,
      exportRec: null,
      mustMatch: [],
      mustNotMatch: [],
      conditions: [{
        title: 'Landesverbände',
        subtitle: 'umfasst alle Landesverbände',
        key: 'type',
        value: 2,
      }, {
        title: 'Kreisverbände',
        subtitle: 'umfasst alle Kreisverbände',
        key: 'type',
        value: 3,
      }, {
        title: 'Ortsvereine',
        subtitle: 'umfasst alle Ortsvereine',
        key: 'type',
        value: 4,
      }, {
        title: 'GmbH / gGmbH',
        subtitle: 'umfasst alle GmbHs und gGmbHs',
        key: 'type',
        value: 5,
      }, {
        title: 'Verband der Schwesternschaften',
        subtitle: 'umfasst alle Schwesternschaften',
        key: 'type',
        value: 6,
      }, {
        title: 'alle Kreisverbände des angegebenen Landesverbands',
        subtitle: 'umfasst alle Kreisverbände des hier mit seiner Organisationsnummer angegebenen Landesverbands',
        editable: {
          type: 'text'
        },
        value: '',
        key: 'codeParent'
      }, {
        title: 'aktivierte Module',
        subtitle: 'umfasst alle Gliederungen, die alle gesetzten Module aktiviert haben',
        editable: {
          type: 'modules-active-ac'
        },
        value: [],
        key: 'modules_active'
      }, {
        title: 'deaktivierte Module',
        subtitle: 'umfasst alle Gliederungen, die alle gesetzten Module deaktiviert haben',
        editable: {
          type: 'modules-inactive-ac'
        },
        value: [],
        key: 'modules_inactive'
      }, {
        title: 'Beratungszentren',
        subtitle: 'umfasst alle Beratungszentren',
        key: 'type',
        value: 16
      }, {
        title: 'Generalsekretariat',
        subtitle: 'umfasst das Generalsekretariat',
        key: 'type',
        value: 1,
      }, {
        title: 'Sonstige',
        subtitle: 'umfasst alle sonstigen Gliederungen',
        key: 'type',
        value: 99,
      }, {
        title: 'Alle',
        subtitle: 'umfasst alle Gliederungen',
        key: 'type',
        value: -1,
      }, {
        title: 'Tarifgruppe',
        subtitle: 'umfasst alle Gliederungen der gewählten Tarifgruppe',
        key: 'rate_group',
        value: 0,
        editable: {
          type: 'rate_group-select'
        },
      },]
    }
  },
  computed: {
    ...mapState({
      rateGroups: state => state.option.rate_groups,
      moduleNames: state => state.option.module_names,
    }),
    rateGroupItems() {
      if (!this.rateGroups) {
        return [];
      }
      return this.rateGroups.map((item, key) => {
        return {
          value: key,
          text: item
        };
      });
    },
    moduleNameItems() {
      if (!this.moduleNames) {
        return [];
      }
      return this.$_.reduce(this.moduleNames, (res, text, value) => {
        res.push({text, value})
        return res
      }, [])
    },
  },
  methods: {
    ...mapActions('option', ['loadOption']),
    getValue(value, key) {
      if ('rate_group' === key) {
        return this.$_.nth(this.rateGroups, value)
      }
      if ('modules_inactive' === key || 'modules_active' === key) {
        return this.$_.map(value, 'text').join(', ')
      }
      if (!this.$_.isObject(value)) {
        return value
      }
    },
    insertCondition(event) {
      this.conditions.splice(event.index, 0, event.data);
    },
    insertMustMatch(event) {
      this.mustMatch.splice(event.index, 0, event.data);
    },
    insertMustNotMatch(event) {
      this.mustNotMatch.splice(event.index, 0, event.data);
    },
    remove(array, value) {
      let index = array.indexOf(value);
      array.splice(index, 1);
    },
    onExport () {
      let must = {}, mustNot = {}

      this.mustMatch.forEach(item => {
        if (!must[item.key]) {
          must[item.key] = []
        }
        must[item.key] = this.$_.concat(must[item.key], item.value)
      })

      this.mustNotMatch.forEach(item => {
        if (!mustNot[item.key]) {
          mustNot[item.key] = []
        }
        mustNot[item.key] = this.$_.concat(mustNot[item.key], item.value)
      })

      this.exportRec = {
        type: 'orgsByConditions',
        name: 'DDM Export',
        id: 0,
        conditions: {must, mustNot}
      }
      this.exportDialog = true
    }
  },
  mounted() {
    this.loadOption({option: 'rate_groups'});
    this.loadOption({option: 'module_names'});
  }
}
</script>

<style scoped>
.chip {
  margin: 10px;
}
.chip-list {
  height: 400px;
  display: flex;
  flex-direction: row;
  /* controls space between flex lines on the cross axis */
  align-content: flex-start;
  /* controls alignment of all items on the cross axis */
  align-items: flex-start;
  /* controls alignment of all items on the main axis */
  justify-content: flex-start;
  flex-wrap: wrap;
}
</style>
