<template>
<d-action-dialog
  v-bind:value="value"
  v-on:input="$emit('input', $event)"
  :onSave="onSave"
>
  <template slot="title">
    Profilbild ändern
  </template>
  <template>
    <v-row>
      <v-col cols="12">
        <v-file-input
          v-model="uploaded"
          accept="image/png, image/jpeg, image/bmp"
          prepend-icon="mdi-camera"
          clearable
          outlined
          show-size
          hide-details
          label="Bild hochladen"
        ></v-file-input>
      </v-col>
    </v-row>
  </template>
</d-action-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      uploaded: null
    }
  },
  methods: {
    ...mapActions('users', ['changeAvatar']),
    onSave() {
      if (null !== this.uploaded) {
        this.changeAvatar({id: this.id, file: this.uploaded});
      }
    }
  }
}
</script>
