import { accountService } from '../../services';
import { router } from '../../helpers';

const user = JSON.parse(localStorage.getItem('user'));
const state = user
  ? {
      status: { loggedIn: true },
      mandants: [],
      user,
      acl: null,
      sessionDeathAcknowledged: true
    }
  : {
      status: {},
      mandants: [],
      user: null,
      acl: null,
      sessionDeathAcknowledged: true
    };

const actions = {
  login({ /*dispatch,*/ commit }, { username, password }) {
    commit('loginRequest', { username });

    accountService.login(username, password)
      .then(
        user => {
          commit('loginSuccess', user);
          router.push('/');
        },
        error => {
          commit('loginFailure', error);
          commit('sessionDeathAcknowledged', true);
          //dispatch('alert/error', error, { root: true });
        }
      );
  },
  loginByTicket({ dispatch, commit }, ticket) {
    commit('loginRequest', { ticket: ticket });

    accountService.loginByTicket(ticket)
      .then(
        user => {
          commit('loginSuccess', user);
          router.push('/');
        },
        error => {
          commit('loginFailure', error);
          dispatch('alert/error', error, { root: true });
        }
      );
  },
  logout({ commit }) {
    accountService.logout();
    commit('logout');
    commit('getAclSuccess', null);
  },
  logoutBySessionDeath({ commit }, acknowledged = false) {
    accountService.logout();
    commit('logout');
    commit('sessionDeathAcknowledged', acknowledged);
  },
  changeUser({ dispatch, commit }, user_id) {
    return accountService.change(user_id)
      .then(
        user => {
          commit('changeUserSuccess', {user, user_id});
        },
        error => {
          commit('changeUserFailure', error);
          dispatch('alert/error', error, { root: true });
        }
      )
      .then(
        () => {
          accountService.getAcl()
            .then(
              acl => {
                commit('getAclSuccess', acl.acl)
              }
            )
        }
      );
  },
  getMandants( {dispatch, commit} ) {
    accountService.getMandants()
      .then(
        mandants => {
          commit('getMandantsSuccess', mandants)
        },
        error => {
          commit('getMandantsFailure', error);
          dispatch('alert/error', 'Konnte Liste der Orgs dieses Users nicht bestimmen.', { root: true });
        }
      );
  },
  getAcls( {commit} ) {
    accountService.getAcl()
      .then(
        acl => {
          commit('getAclSuccess', acl.acl)
        }
      );
  },
  register({ dispatch, commit }, user) {
    commit('registerRequest', user);

    accountService.register(user)
      .then(
        user => {
          commit('registerSuccess', user);
          router.push('/login');
          setTimeout(() => {
            // display success message after route change completes
            dispatch('alert/success', 'Registration successful', { root: true });
          })
        },
        error => {
          commit('registerFailure', error);
          dispatch('alert/error', error, { root: true });
        }
      );
  }
};

const mutations = {
  getAclSuccess(state, acl) {
    state.acl = acl;
  },
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = { loginError: true };
    state.user = null;
  },
  // eslint-disable-next-line no-unused-vars
  changeUserSuccess(state, {user, user_id}) {
    state.mandants.forEach(mandant => {
      mandant.selected = mandant.user_id === user_id
    });
  },
  changeUserFailure(state) {
    state;
  },
  getMandantsSuccess(state, mandants) {
    state.mandants = mandants
  },
  getMandantsFailure(state) {
    state.mandants = []
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
  sessionDeathAcknowledged(state, flag) {
    state.sessionDeathAcknowledged = flag
  },
  registerRequest(state) {
    state.status = { registering: true };
  },
  registerSuccess(state) {
    state.status = {};
  },
  registerFailure(state) {
    state.status = {};
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations
};
