<template>
<d-action-dialog
  v-bind:value="value"
  v-on:input="$emit('input', $event)"
  :onSave="onSave"
  confirmText="Löschen"
  denyText="Abbrechen"
>
  <template slot="title">
    Vertragsdatei löschen
  </template>
  <template>
    Möchten Sie {{ name !== undefined ? 'die Datei `' + name + '`' : 'diese Datei' }} wirklich löschen?
  </template>
</d-action-dialog>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    id: {
      type: Number,
      required: false
    },
    name: {
      type: String,
      required: false
    }
  },
  methods: {
    onSave() {
      this.$emit('deletetion-confirmed');
    }
  }
}
</script>
