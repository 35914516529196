<style>
/* row margins were changed in vuetify 2.4.0 */
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.row + .row {
  margin-top: 0;
}
</style>

<template>
  <v-app id="bvw-two-o">
    <v-main>
      <v-container fluid fill-height class="pa-0">
        <router-view></router-view>
        <v-snackbar
          app
          top
          :color="alert.color"
          :text="true"
          v-model="showAlert"
          :elevation="24"
          :timeout="15000"
        >
          <span class="mr-4" v-html="alert.message"></span>
          <template v-slot:action="{ attrs }">
            <v-icon
              text
              v-bind="attrs"
              @click="clearAlert"
            >close</v-icon>
          </template>
        </v-snackbar>
      </v-container>
    </v-main>
    <v-dialog
      v-model="acknowledgeSessionDeath"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Session abgelaufen</v-card-title>
        <v-card-text>Ihre Session ist abgelaufen. Mit OK kommen Sie zurück zur Anmeldung</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" small @click="backToLogin">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'app',
  computed: {
    ...mapState({
      alert: state => state.alert,
      account: state => state.account
    }),
    acknowledgeSessionDeath () {
      return this.$route.name !== 'login' && !this.account.sessionDeathAcknowledged
    },
    showAlert: {
      get: function () {
        return null !== this.alert.message
      },
      set: function () {
        this.clearAlert()
      }
    },
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      logoutBySessionDeath: 'account/logoutBySessionDeath'
    }),
    backToLogin () {
      this.logoutBySessionDeath(true)
      if ('login' !== this.$route.name) {
        this.$router.push({name: 'login'})
      }
    }
  },
  watch: {
    $route () {
      // clear alert on location change
      this.clearAlert();
    }
  }
};
</script>
