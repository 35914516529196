<template>
<d-action-dialog
  v-bind:value="value"
  v-on:input="$emit('input', $event)"
  :onSave="onSave"
  :validate="validate"
>
  <template slot="title">
    Kontaktdaten bearbeiten
  </template>
  <template>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row v-if="item">
        <v-col cols="6">
          <d-text-field label="Einricht./Gliederg." v-model="item.office_organisation" :rules="validation.string"></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field label="Strasse" v-model="item.office_street" :rules="validation.string"></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field label="Postleitzahl" v-model="item.office_zipcode" :counter="5" :rules="validation.zipcodeRequired"></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field label="Ort" v-model="item.office_city" :rules="validation.string"></d-text-field>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col class="py-0 pr-1" cols="6">
              <d-text-field label="Bereich" v-model="item.office_division" :rules="validation.string"></d-text-field>
            </v-col>
            <v-col class="py-0 pl-1" cols="6">
              <d-text-field label="Team" v-model="item.office_team" :rules="validation.string"></d-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <d-text-field label="Haus/Etage/Raum" v-model="item.office_room" :rules="validation.string"></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field label="Telefon" v-model="item.office_phone" :rules="validation.phone"></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field label="Mobil" v-model="item.office_mobile" :rules="validation.phone"></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field label="Fax" v-model="item.office_fax" :rules="validation.phone"></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field label="Website" v-model="item.webpage" :rules="validation.url"></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field label="Erreichbarkeit" v-model="item.office_time" :rules="validation.string"></d-text-field>
        </v-col>
        <!--v-col cols="6">
          <d-text-field label="Berechtigungen"></d-text-field>
        </v-col-->
      </v-row>
    </v-form>
  </template>
</d-action-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { validation } from '../../helpers'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    item: {
      type: Object,
      required: false,
      default: () => {
        return {
          id: null,
          office_organisation: null,
          office_street: null,
          office_zipcode: null,
          office_city: null,
          office_division: null,
          office_room: null,
          office_team: null,
          office_phone: null,
          office_mobile: null,
          office_fax: null,
          office_time: null,
          webpage: null
        }
      }
    }
  },
  data() {
    return {
      validation: validation,
      valid: true,
    }
  },
  methods: {
    ...mapActions('users', ['update']),
    validate() {
      this.valid = this.$refs.form.validate();
      return this.valid;
    },
    onSave() {
      if (this.valid) {
        this.update(this.item);
      }
    }
  }
}
</script>
