import {authHeader, config, handleBlobResponse, handleResponse} from "../helpers";

export const exportService = {
  index: index,
  add: add,
  download: download,
  delete: delete_export,
  cancel: cancel,
}

function index() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/export.json`, requestOptions).then(handleResponse);
}

function add(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/export.json`, requestOptions).then(handleResponse);
}

function download(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/export/${id}.json`, requestOptions).then(handleBlobResponse);
}

function delete_export(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/export/${id}.json`, requestOptions).then(handleResponse);
}

function cancel(id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({
      id: id
    })
  };

  return fetch(`${config.apiUrl}/export/${id}.json`, requestOptions).then(handleResponse);
}
