<template>
  <module-template :name="name" v-model="value">
    <v-row class="module-form-row">
      <v-col cols="12">
        <d-autocomplete
          label="Gruppen"
          multiple
          hide-details
          :disabled="!canEditPermissions"
          v-model="value.options.group"
          :items="groupItems"
        ></d-autocomplete>
      </v-col>
    </v-row>
  </module-template>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import moduleTemplate from './module-template'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    moduleTemplate
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl,
      groups: state => state.option.typo3usergroups
    }),
    groupItems () {
      let groups = []
      for (const [key, value] of Object.entries(this.groups)) {
        groups.push({value: parseInt(key), text: value})
      }
      return groups
    },
    canEditPermissions() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_authorization_edit;
    }
  },
  methods: {
    ...mapActions('option', ['loadOption'])
  },
  created () {
    this.loadOption({option: 'typo3usergroups'})
  }
}
</script>
