<template>
<d-action-dialog
  v-bind:value="value"
  v-on:input="$emit('input', $event)"
  :onSave="onSave"
  :validate="onValidate"
>
  <template slot="title">
    Stammdaten bearbeiten
  </template>
  <template>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-row>
        <v-col cols="12" sm="6">
          <d-autocomplete
            :disabled="!canEditAll"
            label="Gliederungstyp"
            v-model="item.type_name"
            :rules="rules.orgType"
            :items="types"
            @input="onChangeType"
          ></d-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <d-text-field
            label="Name"
            :rules="validation.stringRequired"
            :disabled="!canEditAll"
            v-model="item.name"
            @input="edited.name = true"
          ></d-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <d-text-field
            label="Nummer"
            :disabled="!canEditAll"
            :rules="rules.orgCode"
            :counter="10"
            v-model="item.code"
            @input="edited.code = true"
            :loading="codeChecking"
          ></d-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <d-text-field
            label="Straße"
            :rules="validation.stringRequired"
            v-model="item.street"
            @input="edited.street = true"
          ></d-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <d-text-field
            label="Postleitzahl"
            :rules="validation.zipcodeRequired"
            :counter="5"
            v-model="item.zipcode"
            @input="edited.zipcode = true"
          ></d-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <d-text-field
            label="Ort"
            :rules="validation.stringRequired"
            v-model="item.city"
            @input="edited.city = true"
          ></d-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <d-text-field
            label="Telefon"
            :rules="validation.phone"
            v-model="item.phone"
            @input="edited.phone = true"
          ></d-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <d-text-field
            label="Fax"
            :rules="validation.phone"
            v-model="item.fax"
            @input="edited.fax = true"
          ></d-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <d-text-field
            label="E-Mail"
            :rules="rules.email"
            v-model="item.email"
            @input="edited.email = true"
          ></d-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <d-text-field
            label="Website"
            :rules="validation.url"
            v-model="item.webpage"
            @input="edited.webpage = true"
          ></d-text-field>
        </v-col>
      </v-row>
      <v-row v-if="canSeeDDMFields">
        <v-col cols="6">
          <d-text-field
            label="Debitorennummer"
            :rules="rules.debitor"
            v-model="item.debitor"
            @input="edited.debitor = true"
          ></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-select
            label="Tarifgruppe"
            v-model="item.rate_group"
            :items="rateGroupItems"
            @input="edited.rate_group = true"
          ></d-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <d-select
            label="Bearbeiter Anmeldungen"
            v-model="item.application_reviser_id"
            :items="arUsers"
            @input="edited.application_reviser_id = true"
          ></d-select>
        </v-col>
      </v-row>
      <v-row v-if="canSeeDDMFields">
        <v-col cols="12">
          <d-textarea
            clearable
            no-resize
            height="120"
            label="Anmerkung"
            v-model="item.comment"
            @input="edited.comment = true"
          ></d-textarea>
        </v-col>
      </v-row>
    </v-form>
  </template>
</d-action-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {regex, validation} from '../../helpers'
import {organisationService} from "../../services";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    items: {
      type: Object,
      required: true
    },
    comment: {
      type: Object,
      required: true
    },
    orgId: {
      type: Number,
      required: true
    },
    types: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      valid: true,
      working: false,
      validation: validation,
      item: {
        code: null,
        name: null,
        street: null,
        zipcode: null,
        city: null,
        phone: null,
        fax: null,
        email: null,
        webpage: null,
        typ: null,
        comment: null,
        debitor: null,
        rate_group: null,
        apikey: null,
        application_reviser_id: null,
      },
      edited: {},
      codeChecking: false,
      codeUnique: true,
      debitorChecking: false,
      debitorUnique: true,
      rules: {
        debitor: [
          v => v == null || v == '' || parseInt(v || 0) >= 10000  || 'Debitorennummer muss mindestens 5 stellig sein.',
          v => v == null || v == '' || parseInt(v || 0) <= 999999 || 'Debitorennummer darf höchstens 6 stellig sein.',
          v => v == null || v == '' || this.debitorUnique || 'Debitorennummer schon vergeben',
        ],
        orgType: [
          v => {
            // on loading form with data, v is an object of form {value: '99', text: 'Sonstiges'}
            // on selecting a dropdown option, v is a numeric string
            v = this.$_.isObject(v)
              ? v.value && parseInt(v.value || 0) > 0
              : parseInt(v || 0) > 0

            return v
              ? true
              : 'Gliederungstyp muss angegeben werden'
          }
        ],
        orgCode: validation.digitsRequired.concat([
          v => 'string' === typeof v && 3 < v.length || 'Min. 4 Zeichen.',
          v => 'string' === typeof v && 11 > v.length || 'Max. 10 Zeichen.',
          () => this.codeUnique || 'Diese Nummer ist schon vergeben.',
        ]),
        email: [
          v => (!!v || this.isOV()) || 'Dieses Feld muss ausgefüllt sein',
          v => (!v || regex.email.test(v)) || 'Dieses Feld muss eine gültige E-Mail-Adresse enthalten'
        ],
      }
    }
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl,
      rateGroups: state => state.option.rate_groups,
      users: state => state.users.drk
    }),
    arUsers() {
      return this.users.map(user => {
        return {
          value: user.id,
          text: user.name
        }
      })
    },
    rateGroupItems() {
      if (!this.rateGroups) {
        return [];
      }
      return this.rateGroups.map((item, key) => {
        return {
          value: key,
          text: item
        };
      });
    },
    canEditAll() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_general_edit;
    },
    canSeeDDMFields() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_ddm_fields;
    }
  },
  methods: {
    ...mapActions('organisation', ['update']),
    ...mapActions('option', ['loadOption']),
    isOV() {
      return 4 == this.item.type_name
    },
    onChangeType() {
      this.edited.type_name = true
      this.onValidate()
    },
    onSave() {
      if (this.valid) {
        let changedData = {id: this.orgId};
        for (let [key, value] of Object.entries(this.item)) {
          if (this.edited[key]) {
            changedData[key === 'type_name' ? 'typ' : key] = value;
          }
        }
        this.update(changedData);
      }
    },
    onValidate() {
      this.valid = this.$refs.form.validate();
      return this.valid;
    },/*
    closeDialog() {
      this.$emit('input', false);
    },*/
    markAsEdited(item) {
      item.edited = true;
    },
    updateVisibleItems() {
      let baseData = Object.entries(this.items);
      baseData.push(['comment', this.comment]);
      for (let [key, value] of baseData) {
        // Clone object to avoid mutating vuex store
        let itemClone = Object.assign({}, value);
        if (value.show || key === 'comment') {
          if (key === 'type_name') {
            itemClone = this.types.find(el => el.text === itemClone.value)
          }
          this.item[key] = itemClone.value;
          this.edited[key] = false;
        }
      }
    },
    checkCodeIsUnique() {
      const code = this.item.code;
      if (code && 3 < code.length) {
        this.codeChecking = true
        organisationService.codeUnique({code, id: this.orgId})
          .then(
            res => {
              this.codeUnique = res
            }
          )
          .finally(() => {
            this.codeChecking = false
            this.onValidate()
          })
      }
    },
    checkDebitorIsUnique() {
      const debitor = parseInt(this.item.debitor);
      if (debitor && 10000 <= debitor && 999999 >= debitor) {
        this.debitorChecking = true
        organisationService.debitorUnique({debitor, id: this.orgId})
          .then(
            res => {
              this.debitorUnique = res
            }
          )
          .finally(() => {
            this.debitorChecking = false
            this.onValidate()
          })
      }
    },
  },
  mounted () {
    this.loadOption({option: 'rate_groups'});
    // wait for 500ms after last keystroke before sending requests
    this.debouncedCheckCodeIsUnique = this.$_.debounce(this.checkCodeIsUnique, 500)
    this.debouncedCheckDebitorIsUnique = this.$_.debounce(this.checkDebitorIsUnique, 500)
  },
  watch: {
    'item.code' () {
      this.debouncedCheckCodeIsUnique();
    },
    value: function(newVal) {
      if (newVal) {
        this.updateVisibleItems();
      }
    },
    'item.debitor' () {
      this.debouncedCheckDebitorIsUnique()
    },
  }
}
</script>
