<template>
<d-action-dialog
  v-bind:value="value"
  v-on:input="$emit('input', $event)"
  :onSave="onSave"
  :validate="onValidate"
  :onClose="onClose"
>
  <template slot="title">
    Organisation anlegen
  </template>
  <template>
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="6">
          <d-autocomplete
            label="Gliederungstyp"
            :rules="rules.orgType"
            :disabled="!canEditAll"
            v-model="item.typ"
            :items="typesArray"
            @input="onChangeType"
          ></d-autocomplete>
        </v-col>
        <v-col cols="6">
          <d-text-field
            label="Name"
            :rules="validation.stringRequired"
            :disabled="!canEditAll"
            v-model="item.name"
          ></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field
            label="Nummer"
            :rules="rules.orgCode"
            :counter="10"
            :disabled="!canEditAll"
            v-model="item.code"
            :loading="codeChecking"
          ></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field
            label="Straße"
            :rules="validation.stringRequired"
            v-model="item.street"
          ></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field
            label="Postleitzahl"
            :rules="validation.zipcodeRequired"
            :counter="5"
            v-model="item.zipcode"
          ></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field
            label="Ort"
            :rules="validation.stringRequired"
            v-model="item.city"
          ></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field
            label="Telefon"
            :rules="validation.phone"
            v-model="item.phone"
          ></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field
            label="Fax"
            :rules="validation.phone"
            v-model="item.fax"
          ></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field
            label="E-Mail"
            :rules="rules.email"
            v-model="item.email"
          ></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-text-field
            label="Website"
            :rules="validation.url"
            v-model="item.webpage"
          ></d-text-field>
        </v-col>
      </v-row>
      <v-row v-if="canSeeDDMFields">
        <v-col cols="6">
          <d-text-field
            label="Debitorennummer"
            :rules="rules.debitor"
            v-model="item.debitor"
          ></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-select label="Tarifgruppe" v-model="item.rate_group" :items="rateGroupItems"></d-select>
        </v-col>
        <v-col cols="12">
          <d-textarea
            clearable
            no-resize
            height="120"
            label="Anmerkung"
            v-model="item.comment"
          ></d-textarea>
        </v-col>
      </v-row>
    </v-form>
  </template>
</d-action-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import {regex, validation} from '../../helpers'
import {genericService, organisationService} from "../../services";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    parentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      item: {
        code: null,
        name: null,
        street: null,
        zipcode: null,
        city: null,
        phone: null,
        fax: null,
        email: null,
        webpage: null,
        typ: null,
        comment: null,
        debitor: null,
        rate_group: 0,
        apikey: null,
      },
      codeChecking: false,
      codeUnique: true,
      debitorChecking: false,
      debitorUnique: true,
      validation: validation,
      valid: true,
      rules: {
        debitor: [
          v => v == null || v == '' || parseInt(v || 0) >= 10000  || 'Debitorennummer muss mindestens 5 stellig sein.',
          v => v == null || v == '' || parseInt(v || 0) <= 999999 || 'Debitorennummer darf höchstens 6 stellig sein.',
          () => this.debitorUnique || 'Debitorennummer schon vergeben',
        ],
        orgType: [
          v => parseInt(v || 0) > 0 || 'Gliederungstyp muss angegeben werden'
        ],
        orgCode: validation.digitsRequired.concat([
          v => 'string' === typeof v && 3 < v.length || 'Min. 4 Zeichen.',
          v => 'string' === typeof v && 11 > v.length || 'Max. 10 Zeichen.',
          () => this.codeUnique || 'Diese Nummer ist schon vergeben.',
        ]),
        email: [
          v => (!!v || this.isOV()) || 'Dieses Feld muss ausgefüllt sein',
          v => (!v || regex.email.test(v)) || 'Dieses Feld muss eine gültige E-Mail-Adresse enthalten'
        ],
      }
    }
  },
  methods: {
    ...mapActions('organisation', ['create']),
    ...mapActions('option', ['loadOption']),
    isOV() {
      return 4 == this.item.typ
    },
    onChangeType() {
      this.onValidate()
    },
    onSave() {
      if (this.valid) {
        this.create({
          ...this.item,
          parent_id: this.parentId
        })
        .then(
          // on successful creation of an org, tell parent (org tree)
          () => this.$emit('org-created')
        )
      }
    },
    onValidate() {
      this.valid = this.$refs.form.validate();
      return this.valid;
    },
    checkCodeIsUnique() {
      const code = this.item.code;
      if (code && 3 < code.length) {
        this.codeChecking = true
        organisationService.codeUnique({code})
          .then(
            res => {
              this.codeUnique = res
            }
          )
          .finally(() => {
            this.codeChecking = false
            this.onValidate()
          })
      }
    },
    checkDebitorIsUnique() {
      const debitor = parseInt(this.item.debitor);
      if (debitor && 10000 <= debitor && 999999 >= debitor) {
        this.debitorChecking = true
        organisationService.debitorUnique({debitor})
          .then(
            res => {
              this.debitorUnique = res
            }
          )
          .finally(() => {
            this.debitorChecking = false
            this.onValidate()
          })
      }
    },
    getUniqueCode() {
      if (!this.item.code) {
        genericService.get(
          'organisations/get-unique-code.json',
          {type: this.item.typ, parent_id: this.parentId}
        )
        .then(
          data => {
            this.item.code = data.code
          }
        )
      }
    },
    onClose() {
      this.item = {
        code: null,
        name: null,
        street: null,
        zipcode: null,
        city: null,
        phone: null,
        fax: null,
        email: null,
        webpage: null,
        typ: null,
        comment: null,
        debitor: null,
        rate_group: 0,
        apikey: null,
      }
    },
  },
  computed: {
    ...mapGetters({
      typesArray: 'organisation/typesArray'
    }),
    ...mapState({
      acl: state => state.account.acl,
      rateGroups: state => state.option.rate_groups,
    }),
    rateGroupItems() {
      if (!this.rateGroups) {
        return [];
      }
      return this.rateGroups.map((item, key) => {
        return {
          value: key,
          text: item
        };
      });
    },
    canEditAll() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_general_edit;
    },
    canSeeDDMFields() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_ddm_fields;
    }
  },
  created () {
    this.loadOption({option: 'rate_groups'});
    // wait for 500ms after last keystroke before sending requests
    this.debouncedCheckCodeIsUnique = this.$_.debounce(this.checkCodeIsUnique, 500)
    this.debouncedGetUniqueCode = this.$_.debounce(this.getUniqueCode, 500)
    this.debouncedCheckDebitorIsUnique = this.$_.debounce(this.checkDebitorIsUnique, 500)
  },
  watch: {
    'item.code' () {
      this.debouncedCheckCodeIsUnique();
    },
    'item.typ' () {
      this.debouncedGetUniqueCode()
    },
    'item.debitor' () {
      this.debouncedCheckDebitorIsUnique()
    },
  },
}
</script>
