import { authHeader, config, handleResponse, util } from '../helpers';

export const userService = {
  getAll,
  getById,
  create,
  update,
  changeAvatar,
  isUsernameUnique,
  autocomplete,
  delete: _delete,
  emailUnique,
  getUniqueUsername,
};

function getAll(org_id, type) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const resource = `${config.apiUrl}/users.json` + util.encodeGetParams({org_id: org_id, type: type});

  return fetch(resource, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users/${id}.json`, requestOptions).then(handleResponse);
}

function create(user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };

  return fetch(`${config.apiUrl}/users.json`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };

  return fetch(`${config.apiUrl}/users/${user.id}.json`, requestOptions).then(handleResponse);
}

function changeAvatar(id, file) {
  let data = new FormData();
  data.append('file', file);
  data.append('id', id);

  const requestOptions = {
    method: 'POST',
    /**
     * no content type!
     * @see https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
     */
    headers: { ...authHeader() },
    body: data
  };

  return fetch(`${config.apiUrl}/users/avatar/${id}.json`, requestOptions).then(handleResponse);
}

function isUsernameUnique(param) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(param)
  };

  return fetch(`${config.apiUrl}/users/usernameUnique.json`, requestOptions).then(handleResponse);
}

function getUniqueUsername(param) {
  const requestOptions = {
    method: 'POST',
    headers: { /*...authHeader(), */'Content-Type': 'application/json' },
    body: JSON.stringify(param)
  };

  return fetch(`${config.apiUrl}/users/getUniqueUsername.json`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users/${id}.json`, requestOptions).then(handleResponse);
}

function autocomplete(search, persontype, organisation_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users/autocomplete/${organisation_id}/${persontype}/${search}`, requestOptions).then(handleResponse);
}

function emailUnique(data) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/users/emailUnique.json`, requestOptions).then(handleResponse);
}
