<template>
<d-action-dialog
  ref="dialog"
  v-bind:value="value"
  v-on:input="$emit('input', $event)"
  :onSave="onSave"
  :onClose="onClose"
  :validate="validate"
  confirmText="Daten senden"
  denyText="Abbrechen"
>
  <template slot="title">Mitarbeiteranmeldung</template>
  <template>
    <v-card flat>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col cols="12">
            <v-card flat class="flex-grow-1">
              <v-row>
                <v-col cols="12" class="pa-2">
                  <span class="font-weight-bold">Erläuterung:</span> Damit Sie einen Zugang zu den DRK-internen Datenbanken erhalten können, muss Ihr/e Geschäftsführer/in zustimmen. Wir erfragen diese Zustimmung bei der betreffenden DRK-Gliederung. Bitte haben Sie etwas Geduld, bis der Genehmigungsprozess abgeschlossen ist.
                </v-col>
              </v-row>
              <v-row><v-col cols="12" class="background-dark white--text pa-2">Allgemeine Daten</v-col></v-row>
              <v-row>
                <v-col cols="6">
                  <d-select label="Anrede" v-model="item.prefix" :items="prefixItems" :rules="validation.required"></d-select>
                </v-col>
                <v-col cols="6">
                  <d-select label="Titel" v-model="item.title" :items="titleItems"></d-select>
                </v-col>
                <v-col cols="6">
                  <d-text-field label="Vorname" v-model="item.firstname" :rules="validation.stringRequired"></d-text-field>
                </v-col>
                <v-col cols="6">
                  <d-text-field label="Nachname" v-model="item.lastname" :rules="validation.stringRequired"></d-text-field>
                </v-col>
                <v-col cols="6">
                  <d-autocomplete
                    label="Funktion"
                    v-model="item.funktion_id"
                    hide-no-data
                    hide-selected
                    item-text="name"
                    item-value="id"
                    :items="funktions"
                  ></d-autocomplete>
                </v-col>
                <v-col cols="6">
                  <d-autocomplete
                    label="Aufgabenbereich"
                    hide-no-data
                    hide-selected
                    v-model="item.task_id"
                    item-text="name"
                    item-value="id"
                    :items="tasks"
                  ></d-autocomplete>
                </v-col>
              </v-row>
              <v-row><v-col cols="12" class="background-dark white--text pa-2">Adresse Dienstlich</v-col></v-row>
              <v-row>
                <v-col cols="12" class="pa-2">
                  <span class="font-weight-bold">Hinweis:</span> aus Datenschutzgründen wollen wir nur dienstliche Daten speichern, nicht aber private, personenbezogene Daten. Für einen Account in der Benutzerverwaltung benötigen wir Ihre E-Mail-Adresse, die Sie uns für dienstliche Zwecke bereitstellen. Wir geben keine Daten weiter. Die Infobriefe der Wissensdatenbank senden wir an die von Ihnen angegebene E-Mail-Adresse.
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <d-text-field
                    label="E-Mail"
                    v-model="item.office_email"
                    :rules="rules.emailValid"
                    :loading="emailChecking"
                  ></d-text-field>
                </v-col>
                <v-col cols="6">
                  <d-text-field label="Straße" v-model="item.office_street" :rules="validation.stringRequired"></d-text-field>
                </v-col>
                <v-col cols="6">
                  <d-text-field label="PLZ" v-model="item.office_zipcode" :counter="5" :rules="validation.zipcodeRequired"></d-text-field>
                </v-col>
                <v-col cols="6">
                  <d-text-field label="Ort" v-model="item.office_city" :rules="validation.stringRequired"></d-text-field>
                </v-col>
                <v-col cols="6">
                  <d-text-field label="Mobil" v-model="item.office_mobile" :rules="validation.phone"></d-text-field>
                </v-col>
                <v-col cols="6">
                  <d-text-field label="Telefon" v-model="item.office_phone" :rules="validation.phone"></d-text-field>
                </v-col>
              </v-row>
              <v-row><v-col cols="12" class="background-dark white--text pa-2">Bei ehrenamtlicher Mitarbeit</v-col></v-row>
              <v-row>
                <v-col cols="6">
                  <d-radio-group label="Ehrenamt" v-model="item.honorary" row>
                    <d-radio label="Ja" value="1"></d-radio>
                    <d-radio label="Nein" value="0"></d-radio>
                  </d-radio-group>
                </v-col>
                <v-col cols="6">
                  <d-text-field label="Meine Einrichtung" v-model="item.office_organisation" :rules="validation.string"></d-text-field>
                </v-col>
              </v-row>
              <v-row><v-col cols="12" class="background-dark white--text pa-2">Weitere Informationen</v-col></v-row>
              <v-row>
                <v-col cols="6">
                  <d-autocomplete
                    label="Ihre Rotkreuz Gliederung"
                    hint="Tippen um Suche zu starten"
                    v-model="item.organisation_id"
                    hide-no-data
                    item-text="name"
                    item-value="id"
                    :return-object="false"
                    :items="organisationItems"
                    :loading="orgSearching"
                    :rules="[
                      v => !!v || 'Tragen Sie den Namen ohne Gliederungstyp ein.'
                    ]"
                  ></d-autocomplete>
                </v-col>
                <v-col cols="6">
                  <div class="requested_access">
                    <div>Zugriff benötigt auf:</div>
                    <v-checkbox
                      label="DRK-interne Datenbanken der Gliederung"
                      hide-details="auto"
                      value="intern"
                      v-model="item.requested_access"
                    ></v-checkbox>
                    <v-checkbox
                      label="DRK-Styleguide"
                      hide-details="auto"
                      value="styleguide"
                      v-model="item.requested_access"
                    ></v-checkbox>
                    <v-checkbox
                      label="DRK-Einkaufsportal"
                      hide-details="auto"
                      value="ekportal"
                      v-model="item.requested_access"
                    ></v-checkbox>
                  </div>
                </v-col>
                <!--v-col cols="6">
                  <d-text-field
                    readonly
                    :error-messages="usernameErrors"
                    :rules="validation.stringRequired"
                    v-model="item.username"
                    error-count="2"
                    label="Login"
                    v-bind:style="{'z-Index': overlayZIndex + 1}"
                  ></d-text-field>
                </v-col>
                <v-col cols="6">
                  <d-password
                    :readonly="passwordReadonly"
                    :rules="validation.stringRequired"
                    v-model="item.password"
                    :showPassword="showPassword"
                    label="Passwort"
                    v-bind:style="{'z-Index': overlayZIndex + 1}"
                  ></d-password>
                </v-col-->
              </v-row>
              <v-row>
                <v-col cols="12" class="pa-2">
                  <v-checkbox
                    v-model="privacyStatement"
                    :rules="[
                      v => v || 'Sie müssen zustimmen.'
                    ]"
                  >
                    <template v-slot:label>
                      <div>
                        Die
                        <a
                          href="https://www.drk-intern.de/datenbanken/benutzerverwaltung-bvw/teilnahmebestimmungen-fuer-die-bvw-im-drk.html"
                          target="_blank"
                          @click.stop
                          style="font-weight: bold"
                        >Datenschutz- und Nutzungsbestimmungs-Hinweise</a>
                        habe ich zur Kenntnis genommen.
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <!--v-overlay
      :zIndex="overlayZIndex"
      :value="showOverlay"
    >
      <v-btn
        class="white--text"
        color="primary"
        @click="closeOverlay"
      >Hab' ich mir gemerkt!</v-btn>
    </v-->
  </template>
</d-action-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { validation } from "../../helpers";
import { organisationService } from "../../services";
import { applicationService } from "../../services";
import { userService } from "../../services";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      validation: validation,
      valid: false,
      item: vm => {
        return {...vm.defaultItem}
      },
      privacyStatement: false,
      organisationItems: [],
      orgSearching: false,
      emailChecking: false,
      emailUnique: true,
      rules: {
        emailValid: validation.emailRequired.concat([
          () => this.emailUnique || 'Die E-Mailadresse ist schon vergeben.'
        ]),
      },
      passwordReadonly: false,
      showPassword: false,
      usernameErrors: [],
      showOverlay: false,
      overlayZIndex: 1
    }
  },
  computed: {
    ...mapState({
      prefixValues: state => state.option.prefix,
      titleValues: state => state.option.title,
      tasks: state => state.tasks.rawList,
      funktions: state => state.functions.rawList
    }),
    defaultItem: () => ({
      prefix: 1,
      title: 0,
      firstname: '',
      lastname: '',
      username: '',
      password: '',
      funktion_id: '',
      task_id: '',
      office_email: '',
      office_street: '',
      office_zipcode: '',
      office_city: '',
      office_mobile: '',
      office_phone: '',
      honorary: '0',
      office_organisation: '',
      organisation_id: 0,
      requested_access: ['intern']
    }),
    prefixItems() {
      if (!this.prefixValues) {
        return [];
      }
      let items = [];
      for (const [key, item] of Object.entries(this.prefixValues)) {
        items.push({
          value: 1*key,
          text: item
        });
      }
      return items;
    },
    titleItems() {
      if (!this.titleValues) {
        return [];
      }
      return this.titleValues.map((item, key) => {
        return {
          value: key,
          text: item
        };
      });
    },
  },
  methods: {
    ...mapActions('option', ['loadOption']),
    ...mapActions('tasks', {taskList: 'list'}),
    ...mapActions('functions', {fktList: 'list'}),
    validate() {
      this.valid = this.$refs.form.validate();
      return this.valid;
    },
    onClose () {
      this.privacyStatement = false
      this.showOverlay = false
      this.showPassword = false
      this.passwordReadonly = false
      this.item = {...this.defaultItem}
    },
    closeOverlay() {
      this.$refs.dialog.closeDialog()
    },
    onSave() {
      if (this.valid) {
        return applicationService.register(this.item)
        .then(
          () => {
            this.showOverlay = true
            this.showPassword = true
            this.passwordReadonly = true
            //return false
            return true
          },
          (res) => {
            this.$store.dispatch('alert/error', res, { root: true })
            return false;
          }
        );
      }
      return false;
    },
    checkEmailIsUnique() {
      const email = this.item.office_email;
      if (email && 5 < email.length) {
        this.emailChecking = true
        userService.emailUnique({email})
          .then(
            res => {
                this.emailUnique = res
            }
          )
          .finally(() => {
            this.emailChecking = false
            this.validate()
          })
      }
    },
    searchOrg (force = false) {
      if (force || (this.orgSearch && 3 < this.orgSearch.length)) {
        this.orgSearching = true;
        organisationService.listByName(this.orgSearch)
          .then(
            orgs => {
              this.organisationItems = orgs
            },
            () => {
              this.organisationItems = []
            }
          )
          .finally(() => {
            this.orgSearching = false;
          })
      }
    }
  },
  created () {
    // wait for 500ms after last keystroke before sending requests
    this.debouncedCheckEmailIsUnique = this.$_.debounce(this.checkEmailIsUnique, 500)
  },
  watch: {
    'item.office_email' () {
      this.debouncedCheckEmailIsUnique();
    },
    'item.organisation_id' () {
      userService.getUniqueUsername({
        firstname: this.item.firstname,
        lastname: this.item.lastname,
        organisation_id: this.item.organisation_id
      })
      .then(
        username => {
          this.item.username = username
          this.usernameErrors = []
        },
        error => {
          this.usernameErrors.push(error);
        }
      )
    },
    value () {
      this.searchOrg(true)
      this.loadOption({option: 'prefix'});
      this.loadOption({option: 'title'});
      this.taskList();
      this.fktList();
      this.item = {...this.defaultItem}
    }
  }
}
</script>

<style scoped>
  .requested_access {
    margin-top: 0;
    padding-top: 0;
  }
  .requested_access >>> legend {
    margin-bottom: 5px;
  }
  .requested_access >>> .v-input--checkbox {
    margin: 0 !important;
  }
</style>
