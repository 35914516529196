import { searchService } from '../../services';
import Vue from "vue";

const state = {
  searching: false,
  orgs: [],
  users: []
};

const actions = {
  search({ dispatch, commit }, query) {
    commit('searching', true)
    searchService.search(query)
      .then(
        (result) => {
          commit('result', result)
        },
        (response) => {
          dispatch(
            'alert/error',
            response && 'string' === typeof response && response.length > 0 ? response : `Fehler bei der Suche`,
            { root: true }
          );
        }
      )
      .finally(
        () => {
          commit('searching', false)
        }
      )
  },
};

const mutations = {
  searching(state, searching) {
    state.searching = searching;
  },
  result(state, result) {
    Vue.set(state, 'orgs', result.orgs ? result.orgs : []);
    Vue.set(state, 'users', result.users ? result.users : []);
  },
};

export const search = {
  namespaced: true,
  state,
  actions,
  mutations
};
