<template>
  <module-template :name="name" v-model="value">
    <!--v-row class="module-form-row">
      <v-col cols="12">
        <d-text-field
          label="Vertrag vom"
          hide-details
          disabled
          readonly
          v-model="moduleOptions.ProgramZlo.contractdate"
        ></d-text-field>
      </v-col>
    </v-row-->
    <v-row class="module-form-row">
      <v-col cols="12">
        <d-select
          label="Berechtigung"
          hide-details
          :disabled="!canEditPermissions"
          v-model="value.role"
          :items="permissionItems"
        ></d-select>
      </v-col>
    </v-row>
  </module-template>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import moduleTemplate from './module-template'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      permissionItems: [{
        value: 99,
        text: 'eigene Tickets'
      },{
        value: 3,
        text: 'alle Tickets'
      },{
        value: 2,
        text: 'Leiter'
      },{
        value: 1,
        text: 'DDL-Administrator'
      }]
    }
  },
  components: {
    moduleTemplate
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl
    }),
    ...mapGetters({
      moduleOptions: 'organisation/moduleOptions',
    }),
    canEditPermissions() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_authorization_edit;
    }
  }
}
</script>
