import {authHeader, config, handleResponse, handleBlobResponse, util} from '../helpers';

export const contractService = {
  index,
  download,
  upload,
  messages,
  sendMessage,
  delete: delete_contract
};

function index(org_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/contracts.json` + util.encodeGetParams({ org_id: org_id }), requestOptions).then(handleResponse);
}

function download(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/contracts/download/${id}.json`, requestOptions).then(handleBlobResponse);
}

function delete_contract(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/contracts/delete/${id}.json`, requestOptions).then(handleResponse);
}

function upload(org_id, type, files, is_contract, skip_messages) {
  let data = new FormData();
  data.append('org_id', org_id);
  files.forEach(file => data.append('file[]', file));
  data.append('type', type);
  data.append('is_contract', is_contract);
  data.append('skip_messages', skip_messages)

  const requestOptions = {
    method: 'POST',
    /**
     * no content type!
     * @see https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
     */
    headers: { ...authHeader() },
    body: data
  };

  return fetch(`${config.apiUrl}/contracts/upload.json`, requestOptions).then(handleResponse);
}

function messages(org_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/contracts/messages/${org_id}.json`, requestOptions).then(handleResponse);
}

function sendMessage(org_id, msg) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      organisation_id: org_id,
      message: msg
    })
  };

  return fetch(`${config.apiUrl}/contracts/message.json`, requestOptions).then(handleResponse);
}
