<style scoped>
.module-panels >>> .v-expansion-panel-content {
  background-color: white !important;
}
</style>

<template>
  <v-row v-if="!loaded" class="mt-4" justify="center">
    <v-progress-circular :value="itemsPercent" rotate="270" color="primary"></v-progress-circular>
  </v-row>
  <d-expansion-panels v-else multiple class="module-panels mt-3">
    <component
      v-for="(elem, key) in sortedItems"
      :key="key"
      :is="elem.component"
      :name="elem.component"
      v-model="elem.data"
    ></component>
  </d-expansion-panels>
</template>

<script>
import { mapState } from 'vuex'
import dldb from './module-partials/dldb'
import wd from './module-partials/wd'
import kdb from './module-partials/kdb'
import kdbfmv from './module-partials/kdbfmv'
import kdbktv from './module-partials/kdbktv'
import kdbkifam from './module-partials/kdbkifam'
import kdbls from './module-partials/kdbls'
import kdbds from './module-partials/kdbds'
import kdbtt from './module-partials/kdbtt'
import kdbschedtool from './module-partials/kdbschedtool'
import kdbanon from './module-partials/kdbanon'
import kdbav from './module-partials/kdbav'
import orgavision from './module-partials/orgavision'
import intern from './module-partials/intern'
import meportal from './module-partials/meportal'
import memberletter from './module-partials/memberletter'
import kvportal from './module-partials/kvportal'
import styleguide from './module-partials/styleguide'
import ekportal from './module-partials/ekportal'
import zlo from "./module-partials/zlo";
import printshop from "./module-partials/printshop";
import drkshop from "./module-partials/drkshop";
import formapp from "./module-partials/formapp.vue"

import { moduleService } from '../../services';

export default {
  components: {
    dldb,
    ekportal,
    intern,
    kdbav,
    kdbds,
    kdbtt,
    kdbkifam,
    kdbfmv,
    kdb,
    kdbktv,
    kdbls,
    kdbschedtool,
    kdbanon,
    kvportal,
    memberletter,
    meportal,
    orgavision,
    styleguide,
    wd,
    zlo,
    printshop,
    drkshop,
    formapp,
  },
  data() {
    return {
      loaded: false,
      apiModules: [
        // hidden default is false
        {name: 'dldb',         position: 2},
        {name: 'drkshop',      position: 22},
        {name: 'ekportal',     position: 16},
        {name: 'formapp',      position: 23},
        {name: 'intern',       position: 1},
        {name: 'kdbav',        position: 10},
        {name: 'kdbds',        position: 20},
        {name: 'kdbtt',        position: 7},
        {name: 'kdbkifam',     position: 8},
        {name: 'kdbfmv',       position: 5},
        {name: 'kdb',          position: 4},
        {name: 'kdbktv',       position: 6},
        {name: 'kdbls',        position: 9},
        {name: 'kdbschedtool', position: 11},//, hidden: () => this.currentMandant.role_id !== 1},
        {name: 'kdbanon',      position: 12},
        {name: 'kvportal',     position: 13},
        {name: 'memberletter', position: 15},
        {name: 'meportal',     position: 18},
        {name: 'orgavision',   position: 19},
        {name: 'printshop',    position: 21},
        {name: 'styleguide',   position: 17},
        {name: 'wd',           position: 3},
        {name: 'zlo',          position: 14},
      ],
      apiModuleItems: [
        'kdbav',
        'kdbds',
        'kdbtt',
        'kdbkifam',
        'kdbfmv',
        'kdb',
        'kdbktv',
        'kdbls',
        'kdbschedtool'
      ],
      itemsLoaded: null,
      itemsTotal: null,
      itemSort(a, b) {
        // group active, inactive and locked modules
        let group_a = a.data.isLocked ? 0 : (a.data.isActive ? 2 : 1);
        let group_b = b.data.isLocked ? 0 : (b.data.isActive ? 2 : 1);
        // sort groups
        if (group_a > group_b) {
          return -1;
        }
        if (group_a < group_b) {
          return 1;
        }
        // sort by position in group
        return a.position - b.position;
      },
      sortedItems: []
    }
  },
  computed: {
    ...mapState({
      checked: state => state.users.checked,
      mandants: state => state.account.mandants,
    }),
    itemsPercent() {
      if (this.itemsLoaded === null || this.itemsTotal === null) {
        return 0;
      }
      return 100 * this.itemsLoaded / this.itemsTotal;
    },
    currentMandant() {
      return this.mandants.find(mandant => mandant.selected)
    },
  },
  methods: {
    fetchModuleData() {
      this.sortedItems = [];
      this.loaded = false;
      let checkedId = this.checked.user.id.value;
      let promises = [];
      let apiData = [];
      this.itemsLoaded = 0;
      this.itemsTotal = this.apiModules.length + this.apiModuleItems.length;
      this.apiModules.forEach((module) => {
        if (module.hidden && module.hidden()) return;

        const value = module.name;
        const position = module.position;

        apiData.push({
          component: value,
          position: position,
          data: {}
        });

        promises.push(
          moduleService.getProgram(value, checkedId)
            .then(
              data => {
                let foundModule = apiData.find(elem => elem.component === value);

                if (foundModule !== undefined) {
                  foundModule.data.longname = data.longname ? data.longname : value;
                  foundModule.data.shortname = data.shortname ? data.shortname : value;
                  foundModule.data.isActive = !!data.isActive.value;
                  foundModule.data.isLocked = !!data.isLocked;
                  foundModule.data.log = data.log.value;
                  foundModule.data.role = data.role.value;
                  foundModule.data.options = data.options.value;
                  if (foundModule.data.options && foundModule.data.options.role !== undefined) {
                    foundModule.data.role = data.options.value.role;
                  }
                }

                this.itemsLoaded++;
              },
              () => {
                this.$store.dispatch('alert/error', `Fehler beim Laden des Moduls ${value}`, { root: true });
              }
            )
        );
      });

      this.apiModuleItems.forEach((value) => {
        promises.push(
          moduleService.getKdbModule(value)
            .then(
              data => {
                let foundModule = apiData.find(elem => elem.component === value);

                if (foundModule !== undefined) {
                  foundModule.data.module = data;
                }

                this.itemsLoaded++;
              },
              () => {
                this.$store.dispatch('alert/error', `Fehler beim Laden der Moduloptionen für ${value}`, { root: true });
              }
            )
        );
      });

      Promise.all(promises).then(() => {
        if (this.checked.user.id.value === checkedId) {
          this.sortedItems = [...apiData].sort(this.itemSort);
        }
        this.loaded = true;
      });
    }
  },
  mounted() {
    this.fetchModuleData();
  },
  watch: {
    'checked.user.id.value'() {
      this.fetchModuleData();
    }
  }
}
</script>
