<style scoped>
.admin-page {
  display: flex;
  flex-direction: row;
}
.fill-width {
  width: 100%;
}
</style>

<template>
  <div class="flex-grow-1 fill-height">
    <navbar />
    <div class="admin-page background-dark flex-grow-1 fill-height">
      <v-navigation-drawer
        permanent
        expand-on-hover
        mini-variant-width="60"
      >
        <v-list nav dense>
          <v-list-item
            v-for="item in items"
            :key="item.key"
            @click.stop="active = item.key"
            link
          >
            <v-list-item-icon>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <div class="fill-width">
        <v-row class="px-4 flex-grow-1 fill-width">
          <v-col cols="12">
            <applications v-if="active === 'accounts'"></applications>
            <ddm-staff-export v-if="active === 'staff-exports'"></ddm-staff-export>
            <ddm-org-export v-if="active === 'org-exports'"></ddm-org-export>
            <account-creation-stats v-if="active === 'accountStats'"></account-creation-stats>
            <change-logs v-if="active === 'logs'"></change-logs>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "./layout/Navbar";
import Applications from "./admin/Applications"
import DdmStaffExport from "./admin/DdmStaffExport";
import AccountCreationStats from "./admin/AccountCreationStats";
import ChangeLogs from "./admin/ChangeLogs";
import DdmOrgExport from "./admin/DdmOrgExport";

export default {
  name: "AdminPage",
  components: {
    ChangeLogs,
    DdmStaffExport,
    DdmOrgExport,
    Navbar,
    Applications,
    AccountCreationStats
  },
  data () {
    return {
      items: [{
        title: 'Mitarbeiteranmeldung',
        icon: 'mdi-account-multiple',
        key: 'accounts'
      }, {
        title: 'DDM Mitarbeiter Export',
        icon: 'file_download',
        key: 'staff-exports'
      }, {
        title: 'DDM Gliederung Export',
        icon: 'file_download',
        key: 'org-exports'
      }, {
        title: 'An- und Abmeldungen',
        icon: 'no_accounts',
        key: 'accountStats'
      },{
        title: 'Änderungen',
        icon: 'list',
        key: 'logs'
      },],
      active: null
    }
  }
}
</script>
