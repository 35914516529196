<template>
<d-action-dialog
  v-bind:value="value"
  v-on:input="$emit('input', $event)"
  :onSave="onSave"
  confirmText="Verschieben"
  denyText="Abbrechen"
>
  <template slot="title">
    Organisation verschieben
  </template>
  <template>
    Möchten Sie '{{ src.name }}' wirklich in '{{ dest.name }}' verschieben?
  </template>
</d-action-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    src: {
      type: Object,
      required: true
    },
    dest: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions('organisation', ['move']),
    onSave() {
      this.move({from: this.src.id, to: this.dest.id})
        .then(
          // tell on successful move
          () => {
            this.$store.dispatch('alert/success', `${this.src.name} verschoben in ${this.dest.name}`, {root: true})
            this.$emit('org-moved')
          }
      )
    }
  }
}
</script>
