<template>
  <module-template :name="name" v-model="value">
    <v-row class="module-form-row">
      <v-col cols="12">
        <d-checkbox
          label="darf eigene Daten ändern"
          hide-details
          :disabled="!canEditPermissions"
          v-model="value.options.chgData"
        ></d-checkbox>
      </v-col>
    </v-row>
    <v-row class="module-form-row">
      <v-col cols="12">
        <d-checkbox
          label="nur Leseberechtigung"
          hide-details
          :disabled="!canEditPermissions"
          v-model="value.options.readOnly"
        ></d-checkbox>
      </v-col>
    </v-row>
    <v-row class="module-form-row">
      <v-col cols="12">
        <d-autocomplete
          label="Handbücher"
          multiple
          hide-details
          return-object
          v-model="qmItems"
          item-value="qmId"
          :disabled="!canEditPermissions"
          :items="manualItems"
        ></d-autocomplete>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Handbuch</th>
                <th>schreiben</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in qmItems" :key="key">
                <td>{{ itemTextFromId(item.qmId) }}</td>
                <td>
                  <d-checkbox
                    class="mt-0"
                    hide-details
                    :disabled="!canEditPermissions"
                    v-model="item.write"
                  ></d-checkbox>
                </td>
                <td>
                  <v-btn :disabled="!canEditPermissions" @click="removeManual(item)" icon>
                    <v-icon>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </module-template>
</template>

<script>
import { mapState } from 'vuex'
import moduleTemplate from './module-template'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    moduleTemplate
  },
  data() {
    return {
      manualItems: [
        {
          qmId: 0,
          text: 'Ambulanter Sozialer Dienst',
          write: false
        },
        {
          qmId: 1,
          text: 'Breitenausbildung',
          write: false
        },
        {
          qmId: 2,
          text: 'Ehrenamt und Qualität',
          write: false
        },
        {
          qmId: 3,
          text: 'Kreisgeschäftsstelle',
          write: false
        },
        {
          qmId: 4,
          text: 'Rettungsdienst',
          write: false
        },
        {
          qmId: 5,
          text: 'Rotkreuz-Dienste',
          write: false
        },
        {
          qmId: 6,
          text: 'Sozialer Service',
          write: false
        },
        {
          qmId: 7,
          text: 'Stationäre Altenpflege',
          write: false
        }
      ]
    }
  },
  methods: {
    removeManual(item) {
      // find object in array and remove it from array
      this.value.options.qm.splice(this.value.options.qm.findIndex(el => el.qmId === item.qmId), 1);
    },
    itemTextFromId(qmId) {
      const item = this.manualItems.find(mi => mi.qmId == qmId );
      return item ? item.text : ''
    }
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl
    }),
    qmItems: {
      get() {
        return Array.from(this.value.options.qm).map((item) => {
          item.text = this.itemTextFromId(item.qmId)
          return item
        }, this)
      },
      set(val) {
        this.value.options.qm = val
      }
    },
    canEditPermissions() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_authorization_edit;
    }
  }
}
</script>
