import { authHeader, config, handleResponse } from '../helpers';

export const organisationService = {
  getAll,
  getById,
  getTypes,
  create,
  update,
  updateApiKey,
  delete: _delete,
  listByName,
  codeUnique,
  debitorUnique,
  programIdUnique,
  move
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/organisations.json`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/organisations/${id}.json`, requestOptions).then(handleResponse);
}

function getTypes() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/options/gliederungstyp.json`, requestOptions).then(handleResponse);
}

function create(org) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(org)
  };

  return fetch(`${config.apiUrl}/organisations.json`, requestOptions).then(handleResponse);
}

function update(org) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(org)
  };

  return fetch(`${config.apiUrl}/organisations/${org.id}.json`, requestOptions).then(handleResponse);
}

function updateApiKey(id, key) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ apikey: key })
  };

  return fetch(`${config.apiUrl}/organisations/apikey/${id}.json`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/organisations/${id}.json`, requestOptions).then(handleResponse);
}

function move(from, to) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify({from, to})
  };

  return fetch(`${config.apiUrl}/organisations/move.json`, requestOptions).then(handleResponse);
}

function listByName(name) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({name})
  };

  return fetch(`${config.apiUrl}/organisations/query.json`, requestOptions).then(handleResponse);
}

function codeUnique(data) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/organisations/codeUnique.json`, requestOptions).then(handleResponse);
}

function debitorUnique(data) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/organisations/debitorUnique.json`, requestOptions).then(handleResponse);
}

function programIdUnique(data) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/organisations/programIdUnique.json`, requestOptions).then(handleResponse);
}

