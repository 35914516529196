import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/es5/locale/de'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        primary: '#E60005',
        secondary: '#AACAE6',
        'background-dark': '#B4C3D7',
        'background-light': '#B4C3D7',
        'form-row-even': '#F7F9FB',
        'form-row-odd': '#FFFFFF',
      },
    },
  },
  lang: {
    locales: { de },
    current: 'de'
  }
});
