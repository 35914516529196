<template>
  <module-template
    :name="name"
    v-model="value"
    info="Im Gegensatz zu den anderen Zugriffseinstellungen gelten die hier gemachten Änderungen für alle Gliederungen, in denen der Mitarbeitende angemeldet ist."
  ></module-template>
</template>

<script>
import moduleTemplate from './module-template'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    moduleTemplate
  }
}
</script>
