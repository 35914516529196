<template>
  <div>
    <v-card>
      <v-card-title>An- und Abmeldungen</v-card-title>
      <div class="fill-width pa-5">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="3">
                <v-select
                  label="Jahr"
                  solo
                  :items="years"
                  v-model="year"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <apexchart :options="chartOptions" :series="series"></apexchart>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Monat</th>
                  <th
                    v-for="name in months"
                    :key="name"
                    class="text-left">
                    {{ name }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(data, sidx) in series"
                  :key="sidx"
                >
                  <td>{{data.name}}</td>
                  <td
                    v-for="(value, vidx) in data.data"
                    :key="vidx"
                  >{{ value }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <!--v-row class="flex-grow-0">
          <v-col cols="12">
            <div class="d-flex">
              <v-btn @click.stop="onExport">Exportieren</v-btn>
              <v-spacer />
            </div>
          </v-col>
        </v-row-->
      </div>
    </v-card>
    <export-dialog v-model="exportDialog" exportRec="exportRec"></export-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ExportDialog from "../ExportDialog";

export default {
  name: "AccountCreationStats",
  components: {ExportDialog},
  data() {
    return {
      year: null,
      exportDialog: false,
      exportRec: null,
      /** @see https://github.com/apexcharts/apexcharts.js*/
      series: [],
      chartOptions: {
        chart: {
          height: 500,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454', '#AA5555'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'An- und Abmeldungen',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          // update
          categories: [],
          title: {
            text: 'Monat'
          }
        },
        yaxis: {
          title: {
            text: 'An-/Abmeldungen'
          },
          //min: 0,
          // update
          //max: 10
          forceNiceScale: true,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },
      months: [],
    }
  },
  computed: {
    ...mapState({
      statistics: state => state.statistics.data
    }),
    years() {
      return this.$_.map(
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$_.fill(
          Array((new Date).getFullYear() - 2009),
          2010
        ),
        (val, idx) => val + idx
      )
    },
  },
  methods: {
    ...mapActions('statistics', ['getData']),
    onYearSelect() {
      this.getData({type: 'DrkUsers.creation', options: {year: this.year}})
    },
    onExport() {
      this.exportRec = {
        type: ''
      }
    },
  },
  created() {
    this.year = (new Date).getFullYear()
    this.onYearSelect()
  },
  watch: {
    statistics: {
      deep: true,
      handler: function(newVal) {
        if (newVal['DrkUsers.creation']) {
          let max = 0,
            categories = [],
            series = [{
              name: "Angemeldet",
              data: [],
            }, {
              name: "Abgemeldet",
              data: [],
            }, {
              name: "Differenz",
              data: [],
            }]

          this.statistics['DrkUsers.creation'].forEach(rec => {
            categories.push(rec.period)
            series[0].data.push(rec.create)
            series[1].data.push(rec.delete)
            series[2].data.push(rec.diff)
            max = Math.max(max, rec.create, rec.delete)
          })

          this.series = series

          let co = {...this.chartOptions}
          co.xaxis.categories = categories
          this.chartOptions = co
          this.months = categories
        }
      }
    },
    year() {
      this.onYearSelect()
    }
  }
}
</script>

<style scoped>

</style>
