<template>
<d-action-dialog
  v-bind:value="value"
  v-on:input="$emit('input', $event)"
  :onSave="onSend"
  :validate="validate"
  confirmText="Passwort anfordern"
  denyText="Abbrechen"
>
  <template slot="title">Passwort neu anfordern</template>
  <template>
    <p class="font-weight-bold">Alle Felder sind Pflichtfelder</p>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <!--d-text-field
        label="Vorname"
        v-model="item.firstname"
        :rules="validation.stringRequired"
      ></d-text-field>
      <d-text-field
        label="Nachname"
        v-model="item.lastname"
        :rules="validation.stringRequired"
      ></d-text-field>
      <d-text-field
        label="Büro PLZ"
        v-model="item.office_zipcode"
        :rules="validation.zipcodeRequired"
      ></d-text-field-->
      <d-text-field
        label="Büro E-Mail"
        v-model="item.office_email"
        :rules="validation.emailRequired"
      ></d-text-field>
    </v-form>
  </template>
</d-action-dialog>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import { validation } from "../helpers";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      validation: validation,
      valid: false,
      item: {
        //firstname: '',
        //lastname: '',
        //office_zipcode: '',
        office_email: ''
      }
    }
  },
  computed: {
    ...mapState({
      requestSuccess: state => state.passwords.requestSuccess,
      requesting: state => state.passwords.requesting
    })
  },
  methods: {
    ...mapActions('passwords', ['request']),
    validate() {
      this.valid = this.$refs.form.validate();
      return this.valid;
    },
    onSend() {
      if (this.valid) {
        this.request(this.item)
      }
      return false
    }
  },
  watch: {
    requestSuccess(val) {
      if (val) {
        this.value = false;
      }
    }
  }
}
</script>
