import {authHeader, config, handleResponse/*, util*/} from '../helpers';

export const applicationService = {
  register,
  list,
  refuse,
  edit,
  accept,
  sendRefusalEmail,
  sendRequestEmail,
};

function sendRequestEmail(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  return fetch(`${config.apiUrl}/applications/sendRequest/${id}.json`, requestOptions).then(handleResponse);
}

function sendRefusalEmail(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  return fetch(`${config.apiUrl}/applications/sendRefusal/${id}.json`, requestOptions).then(handleResponse);
}

function register(data) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/applications.json`, requestOptions).then(handleResponse);
}

function list() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(`${config.apiUrl}/applications.json`, requestOptions).then(handleResponse);
}

function refuse(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(`${config.apiUrl}/applications/${id}.json`, requestOptions).then(handleResponse);
}

function edit(data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/applications/${data.id}.json`, requestOptions).then(handleResponse);
}

function accept(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(`${config.apiUrl}/applications/accept/${id}.json`, requestOptions).then(handleResponse);
}
