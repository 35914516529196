<template>
  <v-row justify="center">
  <v-dialog
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    max-width="750"
  >
    <v-card>
      <v-card-title>
        Exporte
      </v-card-title>
      <d-hr />
      <v-card-text class="card-text">
        <p
          v-if="null !== exportItem"
          class="text-md-subtitle-1 font-weight-black"
        >
          Export der {{fromType(exportItem.type)}} von {{exportItem.name}}
        </p>
        <p>
          Hier haben Sie die Möglichkeit, neue Exporte zu starten, bestehende zu löschen oder nochmals auf Ihrer Festplatte zu speichern.
        </p>
        <v-data-table
          :headers="header"
          :items="exportList"
          :footer-props="{
            itemsPerPageOptions: [5,10,15,25,-1]
          }"
          :items-per-page="25"
          loading-text="wird geladen"
          no-data-text="Keine Exporte vorhanden"
          height="350"
          dense
        >
          <template v-slot:item.state="{ item }">
            <v-tooltip v-if="0 < item.msg.length" top>
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="ml-1"
                  v-bind="attrs"
                  v-on="on"
                >{{item.state}}</span>
              </template>
              <span>{{item.msg}}</span>
            </v-tooltip>
            <span v-else>{{item.state}}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-row justify="space-around">
              <span
                v-if="item.running"
              >
                <v-progress-circular
                  size="16"
                  indeterminate
                  class="md6"
                ></v-progress-circular>
                <v-icon
                  medium
                  @click="cancel(item)"
                >highlight_off</v-icon>
              </span>
              <span v-else>
                <v-icon
                  medium
                  class="md6"
                  @click="download(item)"
                >file_download</v-icon>
                <v-icon
                  medium
                  @click="deleteExport(item)"
                >mdi-delete</v-icon>
              </span>
            </v-row>
          </template>
        </v-data-table>
        <p>
          Bitte haben Sie etwas Geduld, Exporte können eine gewisse Zeit dauern. Wenn ein Export fertiggestellt ist, haben Sie die Möglichkeit ihn auf Ihrer Festplatte zu speichern. Klicken Sie hierzu auf das Downloadsymbol neben der erstellten Exportdatei.
          Falls noch kein Export ausgeführt wird, können Sie nun einen starten.
        </p>
      </v-card-text>
      <d-hr />
      <v-card-actions>
        <v-spacer />
        <v-btn class="form-btn" color="primary" :disabled="!valid" @click="startExport">
          Export starten
        </v-btn>
        <v-btn text class="form-btn" @click="$emit('input', false)">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-row>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ExportDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    exportItem: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      header: [{
        text: 'Datum',
        align: 'start',
        value: 'date',
        width: 100,
      }, {
        text: 'Name',
        align: 'start',
        value: 'name',
      }, {
        text: 'Status',
        align: 'center',
        value: 'state',
        width: 80,
      }, {
        text: 'Datensätze',
        align: 'end',
        value: 'size',
        width: 70,
      }, {
        text: '',
        align: 'center',
        value: 'actions',
        width: 70,
      }],
      updater: null
    }
  },
  computed: {
    ...mapState({
      exportList: state => state.exports.exports
    }),
    valid() {
      return null !== this.exportItem
    },
  },
  methods: {
    ...mapActions('exports', {
      index: 'index',
      add: 'add',
      download: 'download',
      deleteExport: 'delete',
      cancel: 'cancel',
    }),
    fromType(type) {
      return /user/.test(type) ? 'Mitarbeiter' : 'Gliederungsdaten'
    },
    startExport() {
      this.add(this.exportItem)
    }
  },
  watch: {
    value: function(newVal) {
      // on open
      if (true === newVal) {
        this.index()
        this.updater = setInterval(() => {
          this.index()
        }, 15*1000)
      } else {
        clearInterval(this.updater)
      }
    }
  },
  created () {

  }
}
</script>

<style scoped>
.card-text {
  padding: 10px 24px !important;
}
.form-btn {
  padding: 0 16px !important;
  margin: 0 16px 0 0 !important;
  min-width: 120px !important;
}
</style>
