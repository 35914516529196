import { userService } from '../../services';
import { upperFirst, isArray } from "lodash";
import Vue from "vue";

const state = {
  drk: [],
  koop: [],
  checked: null,
  loading: false
};

const actions = {
  getAll({ dispatch, commit }, {id, type}) {
    commit('loading', true)
    userService.getAll(id, type)
      .then(
        users => commit('getAllSuccess', {users, type}),
        () => {
          dispatch('alert/error', `Fehler beim Laden der ${upperFirst(type)} Mitarbeiter.`, { root: true });
        }
      )
      .finally(() => commit('loading', false));
  },

  getChecked({ dispatch, commit }, id) {
    if (id === null) {
      commit('getCheckedSuccess', null)
    } else {
      commit('loading', true)
      userService.getById(id)
        .then(
          user => commit('getCheckedSuccess', user),
          msg => {
            dispatch(
              'alert/error',
              `Fehler beim Laden des Mitarbeiters mit der ID ${id}.<br>${msg}`,
              { root: true }
            );
          }
        )
        .finally(
          () => commit('loading', false)
        );
    }
  },

  create({ dispatch, commit }, user) {
    commit('loading', true)
    userService.create(user)
      .then(
        user => {
          commit('getCheckedSuccess', user)
          commit('createSuccess', user)
          dispatch('alert/success', `Mitarbeiter erfolgreich erstellt.`, { root: true });
        },
        msg => {
          dispatch(
            'alert/error',
            `Fehler beim Erstellen des Mitarbeiters.<br>${msg}`,
            { root: true }
          );
        }
      )
      .finally(() => {
        commit('loading', false)
      });
  },

  changeAvatar({ dispatch, commit }, { id, file }) {
    userService.changeAvatar(id, file)
      .then(
        file => commit('changeAvatarSuccess', file),
        () => {
          dispatch('alert/error', `Fehler beim Speichern des Profilbildes.`, { root: true });
        }
      )
  },

  deleteAvatar({ dispatch, commit }, id) {
    userService.changeAvatar(id, null)
      .then(
        file => commit('changeAvatarSuccess', file),
        () => {
          dispatch('alert/error', `Fehler beim Speichern des Profilbildes.`, { root: true });
        }
      )
  },

  update({ dispatch, commit }, user) {
    userService.update(user)
      .then(
        user => commit('getCheckedSuccess', user),
        msg => {
          dispatch(
            'alert/error',
            `Fehler beim Speichern von Mitarbeiter ID ${user.id}.<br>${msg}`,
            { root: true }
          );
        }
      );
  },

  delete({ commit }, id) {
    commit('deleteRequest', id);

    userService.delete(id)
      .then(
        () => commit('deleteSuccess', id),
        error => commit('deleteFailure', { id, error: error.toString() })
      );
  }
};

const mutations = {
  loading(state, value) {
    Vue.set(state, 'loading', value);
  },
  getAllSuccess(state, {users, type}) {
    Vue.set(state, type, isArray(users) ? users : []);
  },
  createSuccess(state, user) {
    const rec = {
      id: user.user.id.value,
      active: user.user.isActive.value,
      name: user.person.lastname.value + ', ' + user.person.firstname.value,
      function: ''
    }
    state[1 === user.person.persontype_id.value ? 'drk' : 'koop'].unshift(rec)
  },
  getCheckedSuccess(state, user) {
    Vue.set(state, 'checked', user);
    if (null !== user) {
      // remove regular user, that got deactivated (if at all) form regular user list
      state.drk.map(drkuser => {
        if (drkuser.id === user.user.id.value) {
          drkuser.active = user.user.isActive.value;
        }
        return drkuser;
      });
      // remove koop user, that got deactivated (if at all) from koop user list
      state.koop.map(koopuser => {
        if (koopuser.id === user.user.id.value) {
          koopuser.active = user.user.isActive.value;
        }
        return koopuser;
      });
    }
  },
  changeAvatarSuccess(state, file) {
    state.checked.person.picture.value = file.file;
  },
  deleteRequest(state, id) {
    // add 'deleting:true' property to user being deleted
    state.drk = state.drk.map(user =>
      user.id === id
        ? { ...user, deleting: true }
        : user
    )

    state.koop = state.koop.map(user =>
      user.id === id
        ? { ...user, deleting: true }
        : user
    )
  },
  deleteSuccess(state, id) {
    // remove deleted user from state
    state.drk = state.drk.filter(user => user.id !== id)
    state.koop = state.koop.filter(user => user.id !== id)
    // no user currently viewed
    state.checked = null;
  },
  deleteFailure(state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    state.drk = state.drk.map(user => {
      if (user.id === id) {
        // make copy of user without 'deleting:true' property
        let userCopy = {...user};
        delete userCopy.deleting;
        // return copy of user with 'deleteError:[error]' property
        return { ...userCopy, deleteError: error };
      }

      return user;
    })

    state.koop = state.koop.map(user => {
      if (user.id === id) {
        // make copy of user without 'deleting:true' property
        let userCopy = {...user};
        delete userCopy.deleting;
        // return copy of user with 'deleteError:[error]' property
        return { ...userCopy, deleteError: error };
      }

      return user;
    })
  }
};

export const users = {
  namespaced: true,
  state,
  actions,
  mutations
};
