import { appService } from "../../services";

const state = {
  version: null
};

const actions = {
  getVersion({ commit }) {
    appService.getVersion()
      .then(
        version => {
          commit('versionSuccess', version)
        }
      )
  }
};

const mutations = {
  versionSuccess(state, version) {
    state.version = version;
  }
};

const getters = {
  getVersionString: state =>
    !state.version
      ? ''
      : `${state.version.Major}.${state.version.Minor}.${state.version.Stage}.${state.version.Build}`
}

export const app = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
