import { applicationService } from "../../services";
import Vue from "vue";
import { filter, get, assign } from "lodash";

const state = {
  applicants: [],
  applicant: null,
  stati: [
    {id: 0, value: 'Neu'},
    {id: 1, value: 'Angeschrieben'},
    {id: 2, value: 'Abgelehnt'},
  ]
};

const actions = {
  list({ dispatch, commit }) {
    applicationService.list()
      .then(
        applicants => {
          commit('listSuccess', applicants);
        },
        () => {
          commit('listSuccess', []);
          dispatch('alert/error', 'Fehler beim Laden der Anmeldungen.', { root: true });
        }
      );
  },
  refuse({ dispatch, commit }, id) {
    applicationService.refuse(id)
      .then(
        () => {
          commit('refuseSuccess', id);
        },
        () => {
          dispatch('alert/error', 'Fehler beim Löschen der Anmeldung.', { root: true });
        }
      );
  },
  localEdit({ commit }, data) {
    commit('editSuccess', data)
  },
  edit({ dispatch }, data) {
    applicationService.edit(data)
      .then(
        res => {
          dispatch('localEdit', res)
        },
        () => {
          dispatch('alert/error', 'Fehler beim Speichern der Anmeldung.', { root: true })
        }
      )
  },
  accept({ dispatch, commit }, id) {
    applicationService.accept(id)
      .then(
        () => {
          commit('acceptSuccess', id);
        },
        () => {
          dispatch('alert/error', 'Fehler beim Übernehmen der Anmeldung.', { root: true });
        }
      );
  },
};

const mutations = {
  listSuccess(state, applicants) {
    Vue.set(state, 'applicants', applicants);
  },
  refuseSuccess(state, id) {
    Vue.set(state, 'applicants', state.applicants.filter(applicant => applicant.id !== id))
  },
  acceptSuccess(state, id) {
    Vue.set(state, 'applicants', state.applicants.filter(applicant => applicant.id !== id))
  },
  editSuccess(state, data) {
    assign(
      state.applicants.find(applicant => applicant.id === data.id),
      data
    )
  }
};

const getters = {
  status: state => id => {
    return get(filter(
      state.stati, status => status.id === id
    ), '0.value', '')
  }
}

export const applications = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
