<style>
@media only screen and (max-width: 1920px) {
  .hidden-fullhd-and-down {
    display: none !important;
  }
}
</style>

<template>
  <div>
  <v-card>
    <v-card-title>
      Anmeldungen
      <v-spacer></v-spacer>
      <v-text-field
        v-model="filter"
        append-icon="mdi-magnify"
        label="Filter"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="applicants"
      :search="filter"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [5,10,15,25,-1]
      }"
      :items-per-page="25"
      loading-text="wird geladen"
      dense
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-start">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
              <span
                v-on="on"
                class="d-inline-block text-truncate"
                style="width: 230px;"
              >{{item.typed_org}}</span>
              </template>
              <span>{{item.typed_org}}</span>
            </v-tooltip>
          </td>
          <td class="hidden-fullhd-and-down text-start">{{ getPersontype(item.persontype_id) }}</td>
          <td class="text-start">{{item.lastname}}</td>
          <td class="text-start">{{item.firstname}}</td>
          <td class="text-start">{{item.office_zipcode}}</td>
          <td class="text-start">{{item.office_city}}</td>
          <td class="text-start">{{item.office_street}}</td>
          <td class="hidden-fullhd-and-down text-start">{{item.office_phone}}</td>
          <td class="text-start">{{item.office_email}}</td>
          <td class="text-start">{{ getFunktion(item.funktion_id) }}</td>
          <td class="text-start">{{ getStatus(item.status) }}</td>
          <td class="text-start">{{ dateFromString(item.fromdate) }}</td>
          <td class="text-center">
            <span class="d-inline-block" style="width: 100px;">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="openEditDialog(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    :value="!item.acceptable"
                    color="red"
                    dot
                    overlap
                  >
                    <v-icon
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="openAcceptDialog(item)"
                    >
                      done_all
                    </v-icon>
                  </v-badge>
                </template>
                <span v-if="item.acceptable">Übernehmen</span>
                <span v-if="!item.acceptable" v-html="item.problems.join('<br>')"></span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="openDeleteDialog(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Löschen</span>
              </v-tooltip>
            </span>
          </td>
        </tr>
      </template>

      <!--template v-slot:item.typed_org="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span
              v-on="on"
              class="d-inline-block text-truncate"
              style="width: 230px;"
            >{{item.typed_org}}</span>
          </template>
          <span>{{item.typed_org}}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.persontype_id="{ item }">
        {{ getPersontype(item.persontype_id) }}
      </template>
      <template v-slot:item.funktion_id="{ item }">
        {{ getFunktion(item.funktion_id) }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ getStatus(item.status) }}
      </template>
      <template v-slot:item.fromdate="{ item }">
        {{ dateFromString(item.fromdate) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <span class="d-inline-block" style="width: 100px;">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="openEditDialog(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              :value="!item.acceptable"
              color="red"
              dot
              overlap
            >
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="openAcceptDialog(item)"
              >
                done_all
              </v-icon>
            </v-badge>
          </template>
          <span v-if="item.acceptable">Übernehmen</span>
          <span v-if="!item.acceptable" v-html="item.problems.join('<br>')"></span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="openDeleteDialog(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Löschen</span>
        </v-tooltip>
        </span>
      </template-->
    </v-data-table>
  </v-card>

  <d-action-dialog
    v-model="deleteDialog"
    :onSave="deleteItem"
    confirmText="Löschen"
    denyText="Abbrechen"
  >
    <template slot="title">
      Anmeldung löschen
    </template>
    <template>
      Möchten Sie die Anmeldung {{ selected && selected.firstname !== undefined && selected.lastname !== undefined ? `von ${selected.firstname} ${selected.lastname}` : 'dieses Benutzers' }} wirklich löschen?
    </template>
  </d-action-dialog>

  <d-action-dialog
    v-model="acceptDialog"
    :onSave="acceptItem"
    confirmText="Übernehmen"
    denyText="Abbrechen"
  >
    <template slot="title">
      Anmeldung übernehmen
    </template>
    <template>
      Möchten Sie die Anmeldung {{ selected && selected.firstname !== undefined && selected.lastname !== undefined ? `von ${selected.firstname} ${selected.lastname}` : 'dieses Benutzers' }} wirklich übernehmen?
    </template>
  </d-action-dialog>

  <edit-application
    v-model="editDialog"
    :item="selected"
  ></edit-application>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import EditApplication from "../application/EditApplication";

export default {
  name: "Applications",
  components: {
    EditApplication
  },
  data () {
    return {
      filter: '',
      loading: false,
      editDialog: false,
      deleteDialog: false,
      acceptDialog: false,
      selected: null,
      headers: [{
        text: 'Gliederung',
        align: 'start',
        sortable: true,
        value: 'typed_org',
      },{
        text: 'MATyp',
        align: 'start',
        sortable: true,
        value: 'persontype_id',
        class: 'hidden-fullhd-and-down',
      },{
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'lastname',
      },{
        text: 'Vorname',
        align: 'start',
        sortable: true,
        value: 'firstname',
      },{
        text: 'PLZ',
        align: 'start',
        sortable: true,
        value: 'office_zipcode',
      },{
        text: 'Ort',
        align: 'start',
        sortable: true,
        value: 'office_city',
      },{
        text: 'Straße',
        align: 'start',
        sortable: true,
        value: 'office_street',
      },{
        text: 'Telefon',
        align: 'start',
        sortable: true,
        value: 'office_phone',
        class: 'hidden-fullhd-and-down',
      },{
        text: 'E-Mail',
        align: 'start',
        sortable: true,
        value: 'office_email',
      },{
        text: 'Funktion',
        align: 'start',
        sortable: true,
        value: 'funktion_id',
      },{
        text: 'Status',
        align: 'start',
        sortable: true,
        value: 'status',
      },{
        text: 'Anmeldung',
        align: 'start',
        sortable: true,
        value: 'fromdate',
      },{
        text: '',
        align: 'center',
        sortable: false,
        value: 'actions',
      },],
      items: []
    }
  },
  methods: {
    ...mapActions('applications', {
      appList: 'list',
      refuse: 'refuse',
      accept: 'accept',
    }),
    ...mapActions('functions', {fktList: 'list'}),
    getFunktion (id) {
      return this.funktion(id)
    },
    getStatus (id) {
      return this.status(id)
    },
    getPersontype (id) {
      if (1 === id) {
        return 'regulärer Mitarbeiter'
      }
      if (2 === id) {
        return 'Koop Mitarbeiter'
      }
      return ''
    },
    dateFromString(datestring) {
      /**
       * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
       */
      return new Intl.DateTimeFormat('de-DE',{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(Date.parse(datestring))
    },
    openEditDialog (item) {
      this.setCurrentItem(item)
      this.editDialog = true;
    },
    openAcceptDialog (item) {
      if (!item.acceptable) return
      this.setCurrentItem(item)
      this.acceptDialog = true;
    },
    openDeleteDialog (item) {
      this.setCurrentItem(item)
      this.deleteDialog = true
    },
    acceptItem () {
      this.accept(this.selected.id)
    },
    deleteItem () {
      this.refuse(this.selected.id)
    },
    setCurrentItem(item) {
      this.selected = {...item}
    },
  },
  computed: {
    ...mapState({
      applicants: state => state.applications.applicants,
      //funktions: state => state.functions
    }),
    ...mapGetters({
      status: 'applications/status',
      funktion: 'functions/funktion',
    })
  },
  mounted () {
    this.appList();
    this.fktList();
  }
}
</script>
