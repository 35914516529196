<template>
  <div>
    <v-row>
      <v-col cols="12">
        <d-combobox
          label="Stichworte"
          outlined
          multiple
          hide-selected
          chips
          deletable-chips
          return-object
          :search-input.sync="tagsSearch"
          item-text="name"
          v-model="associatedTags"
          :items="tags"
          @blur.stop="saveTags"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Keinen Eintrag für "<strong>{{ tagsSearch }}</strong>" gefunden. Drücken Sie <kbd>enter</kbd> um einen zu erzeugen.
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </d-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <d-text-field label="Mobil Privat" hide-details v-model="mobile" @blur="updateMobile"></d-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <d-textarea
          label="int. Bemerkung"
          v-model="notes"
          @blur="updateNotes"
          counter
          :rules="rules.comment"
        ></d-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <d-checkbox label="kann OV Daten bearbeiten" hide-details v-model="can_edit_ov" @click.stop="updateCanEditOV"></d-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" @click.stop="sendPassword">Passwort versenden</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {filter} from "lodash";

export default {
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      mobile: null,
      notes: null,
      can_edit_ov: 0,

      associatedTags: [],
      tagsSearch: null,

      lastUser: null,
      rules: {
        comment: [v => 'string' === typeof v && v.length <= 350 || 'Max. 350 Zeichen']}
    }
  },
  computed: {
    ...mapState({
      tags: state => state.tags.list,
      user: state => state.users.checked
    })
  },
  methods: {
    ...mapActions('users', ['update']),
    ...mapActions('passwords', ['request']),
    ...mapActions('tags', {'viewTags': 'view', 'updateTags': 'update'}),
    loadData() {
      this.lastUser = this.user.user.id.value;
      this.viewTags(this.user.user.id.value);
      this.notes = this.user.person.comment.value;
      this.can_edit_ov = this.user.person.edit_ov.value;
      this.mobile = this.user.person.private_mobile.value;
    },
    saveTags() {
      this.updateTags({userId: this.user.user.id.value, tags: this.associatedTags});
    },
    updateMobile() {
      this.update({
        id: this.user.user.id.value,
        private_mobile: this.mobile
      })
    },
    updateNotes() {
      if (this.$_.every(this.rules.comment, fn => true === fn(this.notes))) {
        this.update({
          id: this.user.user.id.value,
          'person.comment': this.notes
        })
      }
    },
    updateCanEditOV() {
      this.update({
        id: this.user.user.id.value,
        edit_ov: this.can_edit_ov ? 1 : 0
      })
    },
    sendPassword() {
      this.request({id: this.user.user.id.value});
    }
  },
  watch: {
    active: {
      immediate: true,
      handler(val) {
        if (val && this.user.user.id.value !== this.lastUser) {
          this.loadData();
        }
      }
    },
    user() {
      if (this.active && this.user.user.id.value !== this.lastUser) {
        this.loadData()
      }
    },
    tags(tags) {
      this.associatedTags = filter(tags, 'selected');
    },
  }
}
</script>
