<template>
  <module-template :name="name" v-model="value"></module-template>
</template>

<script>
import moduleTemplate from './module-template'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    moduleTemplate
  }
}
</script>
