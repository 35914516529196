import Vue from 'vue';

import DrkVuetifyjsTheme from 'drk-vuetifyjs-theme';
import AsyncComputed from 'vue-async-computed'
import VueApexCharts from 'vue-apexcharts'

Vue.use(AsyncComputed)
Vue.use(DrkVuetifyjsTheme);
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import _ from 'lodash';
Object.defineProperty(Vue.prototype, '$_', { value: _ });

import vuetify from './plugins/vuetify';

import { store } from './store';
import { router } from './helpers';
import App from './components/App';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

// eslint-disable-next-line no-constant-condition
if (process.env.VUE_APP_ENABLE_SENTRY === 'true' || process.env.VUE_APP_ENABLE_SENTRY === true) {
  Sentry.init({
    //dsn: '',
    dsn: 'https://ffda8dd22a814899adc5bcfc18f0a182@sentry.drk-intern.de/3',
    integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
    /**
     * @see https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-1233146122
     * @param event
     * @param hint
     * @returns {Event|null}
     */
    // eslint-disable-next-line no-unused-vars
    beforeSend(event, hint) {
      // filter out UnhandledRejection errors that have no information
      if (
        event !== undefined
        && event.exception !== undefined
        && event.exception.values !== undefined
        && 1 === event.exception.values.length
      ) {
        const e = event.exception.values[0];
        if (e.type === 'UnhandledRejection' && e.value.match(/Non-Error promise rejection captured with value/)) {
          return null
        }
      }
      return event
    }
  });
}
