import { authHeader, config, handleResponse/*, util*/ } from '../helpers';

export const searchService = {
  search
};

function search(query) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(query)
  };

  return fetch(`${config.apiUrl}/search/search.json`, requestOptions).then(handleResponse);
}
