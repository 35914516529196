import { config, handleResponse } from '../helpers';

export const appService = {
  getVersion,
};

function getVersion() {
  const requestOptions = {
    method: 'GET',
  };

  return fetch(`${config.apiUrl}/app/version.json`, requestOptions)
    .then(handleResponse);
}
