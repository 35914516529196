import Vue from 'vue';
import Vuex from 'vuex';

import { app } from "./modules/app.module";
import { alert } from './modules/alert.module';
import { account } from './modules/account.module';
import { users } from './modules/users.module';
import { slides } from './modules/slides.module';
import { option } from './modules/option.module';
import { organisation } from './modules/organisation.module';
import { tasks } from './modules/tasks.module';
import { functions } from './modules/functions.module';
import { tags } from './modules/tags.module';
import { passwords } from './modules/passwords.module';
import { search } from "./modules/search.module";
import { contracts } from "./modules/contracts.module"
import { applications } from "./modules/applications.module"
import { statistics } from "./modules/statistics.module";
import { exports } from "./modules/export.module";

Vue.use(Vuex);

let initialState = {
  app: { ...app.state },
  alert: { ...alert.state },
  account: { ...account.state },
  users: { ...users.state },
  slides: { ...slides.state },
  option: { ...option.state },
  organisation: { ...organisation.state },
  tasks: { ...tasks.state },
  tags: { ...tags.state },
  functions: { ...functions.state },
  passwords: { ...passwords.state },
  search: { ...search.state },
  contracts: { ...contracts.state },
  applications: { ...applications.state },
  statistics: { ... statistics.state },
  exports: { ... exports.state }
}

export const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    app,
    alert,
    account,
    users,
    slides,
    option,
    organisation,
    tasks,
    tags,
    functions,
    passwords,
    search,
    contracts,
    applications,
    statistics,
    exports,
  },
  mutations: {
    reset(state) {
      // reset all states of all modules to initial state
      Object.keys(initialState).forEach(key => {
        Object.assign(state[key], initialState[key])
      })
    }
  }
});
