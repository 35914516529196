import { authHeader, config, handleResponse } from '../helpers';

export const functionsService = {
  view,
  update,
  list
};

function view(userId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/functions/${userId}.json`, requestOptions).then(handleResponse);
}

function update(userId, functions) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(functions)
  };

  return fetch(`${config.apiUrl}/functions/${userId}.json`, requestOptions).then(handleResponse);
}

function list() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(`${config.apiUrl}/functions/list-all.json`, requestOptions).then(handleResponse);
}
