import {authHeader, config, handleBlobResponse, handleResponse, util} from '../helpers';

export const genericService = {
    get,
    post,
    request
};

function post(resource, params, options)
{
    return request(resource, params, {...options, ...{method: 'POST'}})
}

function get(resource, params, options)
{
    return request(resource, params, {...options, ...{method: 'GET'}})
}

function request(resource, params, options)
{
    const defaults = {
            auth: true,
            method: 'GET',
            blob: false,
            encode: true
        },
        o = {...defaults, ...options},
        isFormdata = /FormData/.test(params + '')

    /**
     * no content type for FormData
     * @see https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
     */
    const headers = {
        ...(o.auth ? authHeader() : {}),
        ...(!isFormdata ? {'Content-Type' : 'application/json'} : {})
    }

    const requestOptions = {
        method: o.method,
        headers: headers
    };

    if (null !== params) {
        if ('GET' === o.method) {
            resource += util.encodeGetParams(params);
        } else {
            requestOptions.body = !o.encode ? params : JSON.stringify(params)
        }
    }

    return fetch(`${config.apiUrl}/${resource}`, requestOptions)
      .then(o.blob ? handleBlobResponse : handleResponse);
}
