import { organisationService } from "../../services";
import Vue from "vue";
import { pickBy } from 'lodash';

const state = {
  org: null,
  types: null
};

const actions = {
  loadById({ dispatch, commit }, id) {
    organisationService.getById(id)
      .then(
        org => commit('loadSuccess', org),
        () => {
          dispatch('alert/error', 'Fehler beim Laden der Organisation.', { root: true });
        }
      );
  },
  loadTypes({ dispatch, commit }) {
    organisationService.getTypes()
      .then(
        types => commit('loadTypesSuccess', types),
        () => {
          dispatch('alert/error', 'Fehler beim Laden der Gliederungstypen.', { root: true });
        }
      );
  },
  create({ dispatch, commit }, org) {
    return organisationService.create(org)
      .then(
        data => commit('loadSuccess', data),
        () => {
          dispatch('alert/error', 'Fehler beim Erstellen der Organisation.', { root: true });
        }
      );
  },
  update({ dispatch, commit }, org) {
    organisationService.update(org)
      .then(
        data => commit('loadSuccess', data),
        () => {
          dispatch('alert/error', 'Fehler beim Speichern der Stammdaten.', { root: true });
        }
      );
  },
  updateApiKey({ dispatch }, { id, key }) {
    organisationService.updateApiKey(id, key)
      .then(
        () => {},
        () => {
          dispatch('alert/error', 'Fehler beim Speichern des API-Schlüssels.', { root: true });
        }
      );
  },
  delete({ dispatch }, org) {
    return organisationService.delete(org)
      .then(
        () => {},
        () => {
          dispatch('alert/error', 'Fehler beim Löschen der Organisation.', { root: true });
        }
      );
  },
  move({ dispatch }, {from, to}) {
    return organisationService.move(from, to)
      .then(
        () => {},
        () => {
          dispatch('alert/error', 'Fehler beim Verschieben der Organisation.', { root: true });
        }
      );
  }
};

const mutations = {
  loadSuccess(state, org) {
    Vue.set(state, 'org', org);
  },
  loadTypesSuccess(state, types) {
    Vue.set(state, 'types', types);
  }
};

const getters = {
  fullRec: (state) => {
    return state.org
  },
  fullTypes: (state) => {
    return state.types
  },
  typesArray: (state, getters) => {
    if (!getters.fullTypes) {
      return null;
    }
    let result = [];
    for (let [key, value] of Object.entries(getters.fullTypes)) {
      result.push({
        value: key,
        text: value
      });
    }
    return result;
  },
  coreData: (state, getters) => {
    return pickBy(getters.fullRec, field => field.show)
  },
  activeModules: (state, getters) => {
    return Array.from(getters.fullRec._activeModules.value).sort()
  },
  moduleOptions: (state, getters) => getters.fullRec.moduleOptions.value,
  isBz: (state, getters) => 16 === getters.fullRec.typ.value,
}

export const organisation = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
