import { tasksService } from '../../services';
import Vue from "vue";
import {filter, get} from "lodash";

const state = {
  list: [],
  rawList: []
};

const actions = {
  view({ dispatch, commit }, userId) {
    return tasksService.view(userId)
      .then(
        tasks => commit('viewSuccess', tasks),
        () => {
          dispatch('alert/error', `Fehler beim Laden der Aufgabenliste.`, { root: true });
        }
      );
  },

  update({ dispatch, commit }, {userId, tasks}) {
    tasksService.update(userId, tasks)
      .then(
        () => commit('updateSuccess'),
        () => {
          dispatch('alert/error', `Fehler beim Speichern der Aufgabenliste.`, { root: true });
        }
      );
  },

  list({ dispatch, commit}, force= false) {
    if (state.rawList.length > 0 && !force) return
    tasksService.list()
      .then(
        tasks => commit('listSuccess', tasks),
        () => {
          dispatch('alert/error', `Fehler beim Laden der Aufgabenliste.`, { root: true });
        }
      )
  }
};

const mutations = {
  viewSuccess(state, tasks) {
    Vue.set(state, 'list', tasks);
  },
  // eslint-disable-next-line no-unused-vars
  updateSuccess(state) {},
  listSuccess(state, tasks) {
    Vue.set(state, 'rawList', tasks)
  }
};

const getters = {
  task: state => id => {
    return get(filter(
      state.rawList, task => task.id === id
    ), '0.name', '')
  }
}

export const tasks = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
