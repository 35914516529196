import { tagsService } from '../../services';
import Vue from "vue";

const state = {
  list: []
};

const actions = {
  view({ dispatch, commit }, userId) {
    return tagsService.view(userId)
      .then(
        tags => commit('viewSuccess', tags),
        () => {
          dispatch('alert/error', `Fehler beim Laden der Stichwortliste.`, { root: true });
        }
      );
  },

  update({ dispatch, commit }, {userId, tags}) {
    tagsService.update(userId, tags)
      .then(
        () => commit('updateSuccess'),
        () => {
          dispatch('alert/error', `Fehler beim Speichern der Stichwortliste.`, { root: true });
        }
      );
  }
};

const mutations = {
  viewSuccess(state, tags) {
    Vue.set(state, 'list', tags);
  },
  // eslint-disable-next-line no-unused-vars
  updateSuccess(state) {}
};

export const tags = {
  namespaced: true,
  state,
  actions,
  mutations
};
