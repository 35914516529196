import {authHeader, config, handleResponse, util} from '../helpers';

export const accountService = {
  login,
  loginByTicket,
  logout,
  change,
  getMandants,
  register,
  getAcl
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    //mode: 'cors',
    body: JSON.stringify({ username, password })
  };

  return fetch(`${config.apiUrl}/sessions.json`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
}

function loginByTicket(ticket) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  const resource = `${config.apiUrl}/sessions/login-by-ticket.json` + util.encodeGetParams({ticket})

  return fetch(resource, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
}

function change(user_id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return fetch(`${config.apiUrl}/sessions/change-user/${user_id}.json`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // patch token
        const cu = JSON.parse(localStorage.getItem('user'));
        cu.token = user.token;
        localStorage.setItem('user', JSON.stringify(cu));
      }

      return user;
    });
}

function getMandants() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return fetch(`${config.apiUrl}/sessions/get-mandants.json`, requestOptions).then(handleResponse);
}

function logout() {
  // inform backend of logout
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };
  // remove users and its jwt token from local storage to log user out
  localStorage.removeItem('user');

  return fetch(`${config.apiUrl}/sessions/logout.json`, requestOptions).then(handleResponse);
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };

  return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
}

function getAcl() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return fetch(`${config.apiUrl}/sessions/get-acl-properties.json`, requestOptions).then(handleResponse);
}
