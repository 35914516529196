<template>
  <module-template :name="name" v-model="value"></module-template>
</template>

<script>
import { mapState } from 'vuex'
import moduleTemplate from './module-template'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    moduleTemplate
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl
    }),
    canEditPermissions() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_authorization_edit;
    }
  }
}
</script>
