import { authHeader, config, handleResponse, util } from '../helpers';

export const statisticsService = {
  getList,
  getStatistic,
};

function getList(type) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/statistics.json?type=${type}`, requestOptions).then(handleResponse);
}

function getStatistic(type, options) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const p = util.encodeGetParams(options)

  return fetch(`${config.apiUrl}/statistics/data/${type}.json${p}`, requestOptions).then(handleResponse);
}

