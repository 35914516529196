import { isEmpty } from 'lodash';

export const util = {
  encodeGetParams,
  escapeHtml,
  genHexString
};

/**
 *
 * @param {object} p
 * @returns {string}
 */
function encodeGetParams (p) {
  return isEmpty(p) ?
    '' :
    '?' + Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
}

function escapeHtml (i) {
  return i.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;");
}

function genHexString(len) {
  let hex = '';
  for (let i = 0; i < len; i++) {
    hex += (Math.floor(Math.random() * 16)).toString(16);
  }
  return hex;
}
