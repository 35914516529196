<template>
<d-action-dialog
  v-bind:value="value"
  v-on:input="$emit('input', $event)"
  :onSave="onSave"
  confirmText="Löschen"
  denyText="Abbrechen"
>
  <template slot="title">
    Organisation löschen
  </template>
  <template>
    Möchten Sie die Organisation '{{ name }}' wirklich löschen?
  </template>
</d-action-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('organisation', ['delete']),
    onSave() {
      this.delete(this.id)
      .then(
        // tell on successful deletion
        () => this.$emit('org-deleted')
      )
    }
  }
}
</script>
