<template>
  <div v-if="filteredItem !== null">
    <v-row class="module-form-row">
      <v-col><v-label><b>{{ filteredItem.title }}</b></v-label></v-col>
      <v-col class="d-flex justify-end"><slot name="header-tools"></slot></v-col>
    </v-row>
    <d-radio-group
      column
      hide-details
      :disabled="!canEditPermissions"
      name="permission"
      v-model="filteredItem.value"
    >
      <div v-for="(item, key) in filteredItem.groups" :key="key">
        <v-row class="module-form-row">
          <v-col cols="12">
            <v-radio
              @click="handleRoleChange(item)"
              name="permission"
              :value="item.role"
            >
              <template slot="label">
                <span v-html="item.title"></span>
              </template>
            </v-radio>
          </v-col>
        </v-row>
        <v-row v-if="item.description !== ''" class="item-layer-bottom module-form-row">
          <v-col cols="12">
            <v-label :disabled="filteredItem.value !== item.role || !canEditPermissions">
              <span v-html="item.description"></span>
            </v-label>
          </v-col>
        </v-row>
        <slot name="groupTools" v-bind:item="item"></slot>
        <div v-for="(subitem, subkey) in item.items" :key="subkey">
          <d-radio-group
            v-if="subitem.radioId !== undefined"
            :disabled="filteredItem.value !== item.role || !canEditPermissions"
            column
            hide-details
            :name="subitem.radioId"
            v-model="subitem.value"
          >
            <v-row v-for="(radioitem, radiokey) in subitem.children" :key="radiokey" class="item-layer-bottom module-form-row">
              <v-col cols="12">
                <v-radio
                  @click="handleRadioChange(item, subitem)"
                  :name="subitem.radioId"
                  :value="radioitem.name"
                >
                  <template slot="label">
                    <span v-html="radioitem.label"></span>
                  </template>
                </v-radio>
              </v-col>
            </v-row>
          </d-radio-group>
          <v-row v-else class="item-layer-bottom module-form-row">
            <v-col cols="12">
              <v-checkbox
                :disabled="filteredItem.value !== item.role || !canEditPermissions"
                :label="subitem.label"
                hide-details
                v-model="subitem.value"
                @change="handleCheckboxChange($event, item, subitem)"
              >
                <template slot="label">
                  <span v-html="subitem.label"></span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </div>
      </div>
    </d-radio-group>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { upperFirst, every } from 'lodash'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    role: {
      required: true
    }
  },
  data() {
    return {
      filteredItem: null
    };
  },
  mounted() {
    // Process object to an object more usable by vue

    // eslint-disable-next-line no-unused-vars
    let { groups, ...filtered } = this.value;

    filtered.groups = [];
    this.value.groups.forEach((group) => {

      // eslint-disable-next-line no-unused-vars
      let { groupConditions, itemConditions, items, ...groupOther } = group;

      groupOther.items = [];
      group.items.forEach((item) => {
        let extendedItem = item;
        let addItem = true;

        // Check if item needs other items to be checked
        extendedItem.requiresOther = group.itemConditions.filter((condition) => {
          if (condition.name !== 'requires' || condition.param[0] !== item.name) {
            return false;
          }
          return true;
        }).map(a => a.param[1]);

        // Check if other items depend on this being checked
        extendedItem.requiredByOther = group.itemConditions.filter((condition) => {
          if (condition.name !== 'requires' || condition.param[1] !== item.name) {
            return false;
          }
          return true;
        }).map(a => a.param[0]);

        // Check if item is in a radio group
        let radioGroup = group.itemConditions.find((condition) => {
          if (condition.name !== 'singleSelect' || condition.param.find(c => c === item.name) === undefined) {
            return false;
          }
          return true;
        });

        if (radioGroup === undefined) {
          // Checkbox
          extendedItem.value = this.options.aclForm ? !!this.options.aclForm[extendedItem.name] : false;
        } else {
          // Radio element
          let foundRadio = groupOther.items.find((elem) => {
            return radioGroup.param.includes(elem.radioId);
          });
          if (foundRadio !== undefined) {
            // Belongs to other radio group
            foundRadio.children.push(extendedItem);

            // Select radio button
            /**
             * as the values of the checkboxes are derived from sets of acl values, multiple radios could be true,
             * although only one must be selected
             * e.g.:
             * radio 1 set is (acl1)
             * radio 2 set is (acl1, acl2)
             * radio 3 set is (acl1, acl2, acl3)
             * and (acl1, acl2, acl3) = (1, 1, 1) => all radios could be set
             *
             *  multiple possible strategies:
             *   1. group value is value of first selected radio
             *   2. group value is value of last  selected radio
             *
             *  2. is preferred, as radios encountered later in group, are usually (mostly) associated with larger sets
             *  of acl values
             */
            if (this.options.aclForm && !!this.options.aclForm[extendedItem.name]) {
              foundRadio.value = extendedItem.name;
            }

            // Dont add item to list since it is already in children array
            addItem = false;
          } else {
            // New radio group
            extendedItem = {
              radioId: extendedItem.name,
              children: [extendedItem],
              value: (this.options.aclForm && !!this.options.aclForm[extendedItem.name]) ? extendedItem.name : null
            };
          }
        }

        if (addItem) {
          groupOther.items.push(extendedItem);
        }
      });
      if (this.groupConditionsMet(groupConditions)) {
        filtered.groups.push(groupOther);
      }
    });

    // Set chosen role value
    if (typeof this.role === "object") {
      filtered.value = Number(this.role[filtered.short]);
    } else {
      filtered.value = Number(this.role);
    }
    this.filteredItem = filtered;
  },
  methods: {
    handleRoleChange(group) {
      // deactivate items of all groups
      this.filteredItem.groups.forEach(group => {
        group.items.forEach(item => {
          if (item.radioId) {
            // radiogroup
            // skip as radiogroups always have one radio selected
            //item.value = item.children[0].name
            //this.handleRadioChange(group, item)
          } else {
            //checkbox
            item.value = false
            this.options.aclForm[item.name] = 0
          }
        })
      })

      // activate items of chosen group
      group.items.forEach(item => {
        // radiogroup
        if (item.radioId) {
          item.value = item.children[0].name
          this.handleRadioChange(group, item)
        } else {
          // checkbox
          item.value = true
          this.options.aclForm[item.name] = 1
        }
      })
      /**
       *    filteredItem.short + filteredItem.value
       */
      this.$emit('role-changed', this.filteredItem);
    },
    handleCheckboxChange(e, group, item) {
      this.options.aclForm[item.name] = item.value ? 1 : 0
      if (e) {
        this.selectRequiredInputs(group, item.requiresOther);
      } else {
        this.deselectRequiredInputs(group, item.requiredByOther);
      }
    },
    handleRadioChange(group, item) {
      item.children.forEach((elem) => {
        if (elem.name === item.value) {
          this.options.aclForm[elem.name] = 1
          this.selectRequiredInputs(group, elem.requiresOther);
        } else {
          this.options.aclForm[elem.name] = 0
          this.deselectRequiredInputs(group, elem.requiredByOther);
        }
      });
    },
    deselectRequiredInputs(group, items) {
      if (items.length <= 0) {
        return;
      }

      // Uncheck all checkboxes
      // @todo value change does not uncheck boxes
      group.items
        .filter(elem => elem.radioId === undefined && items.includes(elem.name))
        .forEach(elem => {
          elem.value = false
          this.options.aclForm[elem.name] = 0
        }, this);

      // Deselect all radio buttons
      group.items
        .filter(elem => elem.radioId !== undefined && elem.children.map(c => c.name).filter(val => items.includes(val)).length > 0)
        .forEach(elem => {
          elem.value = null
          this.options.aclForm[elem.name] = 0
        }, this);
    },
    selectRequiredInputs(group, items) {
      if (items.length <= 0) {
        return;
      }

      // Check all checkboxes
      // @todo value change does not check boxes
      group.items
        .filter(elem => elem.radioId === undefined && items.includes(elem.name))
        .forEach(elem => {
          elem.value = true
          this.options.aclForm[elem.name] = 1
        }, this);

      // Select all radio buttons
      group.items
        .filter(elem => elem.radioId !== undefined)
        .forEach((elem) => {
          let intersection = elem.children
            .map(c => c.name)
            .filter(val => items.includes(val));

          if (intersection.length > 0) {
            elem.value = intersection[0];
            this.options.aclForm[intersection[0]] = 1
          }
        }, this);
    },

    groupConditionsMet(groupConditions) {
      return every(
        groupConditions,
        condition => {
          let c = condition.split(' '),
            cond = c.pop(),
            mod = c.pop();
          return this.checkCondition(cond, mod);
        }
      )
    },
    modNot(inVal) {
      return !inVal
    },
    modNop(inVal) {
      return inVal
    },
    checkCondition(condition, modifier) {
      let cfn = 'is' + upperFirst(condition),
        mfn = 'mod' + (modifier ? upperFirst(modifier) : 'Nop');
      return this[mfn](this[cfn]) || false;
    },
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl,
      mandants: state => state.account.mandants,
    }),
    ...mapGetters({
      isBz: 'organisation/isBz'
    }),
    currentMandant() {
      return this.mandants.find(mandant => mandant.selected)
    },
    isAdmin() {
      return 1 === this.currentMandant.role_id
    },
    /*isBz() {
      return this.isBz
    },*/
    isDmwr() {
      return 3 >= this.currentMandant.role_id
    },
    canEditPermissions() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_authorization_edit;
    }
  }
}
</script>
