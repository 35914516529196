import {exportService} from "../../services";
import { filter } from 'lodash'
import Vue from "vue";

const state = {
  exports: []
}

const actions = {
  index({ dispatch, commit }) {
    commit('indexSuccess', [])
    exportService.index()
      .then(
        data => commit('indexSuccess', data),
        () => {
          dispatch('alert/error', 'Fehler beim Laden der Exporte.', { root: true })
        }
      )
  },
  add({ commit, dispatch}, data) {
    commit('indexSuccess', [])
    exportService.add(data)
      .then(
        data => {
          commit('indexSuccess', data)
          dispatch('alert/success', 'Export erfolgreich gestartet.', { root: true })
        },
        () => commit('alert/error', 'Fehler beim Starten des Exports.', { root: true })
      )
  },
  download({ dispatch }, item) {
    exportService.download(item.id)
      .then(
        blob => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = item.name
          link.click()
          URL.revokeObjectURL(link.href)
        },
        msg => {
          dispatch('alert/error', msg, { root: true})
        }
      )
  },
  delete({ commit, dispatch}, item) {
    exportService.delete(item.id)
      .then(
        () => commit('deleteSuccess', item),
        () => dispatch('alert/errer', 'Fehler beim Löschen des Exports.', { root: true })
      )
  },
  cancel({ commit, dispatch }, item) {
    commit('indexSuccess', [])
    exportService.cancel(item.id)
      .then(
        data => {
          commit('indexSuccess', data)
          dispatch('alert/success', 'Export erfolgreich abgebrochen.', { root: true })
        },
        () => commit('alert/error', 'Fehler beim Abbruch des Exports.', { root: true })
      )
  }
}

const mutations = {
  indexSuccess(state, data) {
    Vue.set(state, 'exports', data)
  },
  deleteSuccess(state, item) {
    Vue.set(
      state,
      'exports',
      filter(state.exports, listItem => listItem.id !== item.id)
    )
  }
}

export const exports = {
  namespaced: true,
  state,
  actions,
  mutations
}
