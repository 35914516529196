<style scoped>
.form-drawer-btn {
  position: fixed;
  top: 104px;
  left: 0;
  width: 45px !important;
  height: 45px !important;
  min-width: unset !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 5;
  transition-duration: 0.2s;
  transition-property: transform, visibility, left;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.form-drawer-btn--open {
  left: 400px;
}
.bvw-two-o-form {
  display: flex;
  flex-direction: row;
}
.bvw-two-o-form >>> .v-toolbar__content {
  padding: 0 !important;
}
.search-toolbar {
  margin-left: 45px;
}
.chevron-icon {
  font-size: 36px;
}
.fill-width {
  width: 100%;
}
</style>

<template>
  <div class="flex-grow-1 fill-height">
    <navbar />
    <div class="bvw-two-o-form background-dark flex-grow-1 fill-height">
      <navigation-drawer
        v-if="canSeeOrgTree"
        width="400"
        v-model="drawer"
        v-bind:selected-user="currentMandant"
        color="blue-grey lighten-5"
        @updateActive="updateOrgId"
      >
      </navigation-drawer>
      <d-button
        v-if="canSeeOrgTree"
        class="flex-shrink-1 form-drawer-btn"
        :class="{'form-drawer-btn--open': drawer}"
        @click.stop="drawer = !drawer"
      >
        <v-icon dark v-if="drawer" class="chevron-icon">chevron_left</v-icon>
        <v-icon dark v-else>account_tree</v-icon>
      </d-button>
      <div class="fill-width">
        <v-row class="px-4">
          <v-col cols="12">
            {{breadcrumb.identity}} / {{breadcrumb.path}} / <span class="red--text">{{breadcrumb.user}}</span>
          </v-col>
        </v-row>
        <v-toolbar flat tile dense class="transparent pl-0" :class="{'search-toolbar': canSeeOrgTree}">
          <v-toolbar-items class="d-flex flex-row flex-grow-1">
            <d-select
              v-model="selectedUser"
              :items="mandants"
              :outlined="false"
              @change="mandantChanged"
              return-object
              solo
              flat
              dense
              item-text="org_name"
              item-value="user_id"
              class="mx-3 flex-grow-1"
            ></d-select>
          </v-toolbar-items>
        </v-toolbar>
        <v-row class="pa-4 flex-grow-1 fill-width">
          <v-col
            cols="12"
            md="6"
            lg="3"
            :offset-md="canSeeEmployeeData === 1 ? 3 : undefined"
            :offset-lg="canSeeEmployeeData === 1 ? 1 : undefined"
          >
            <BaseDataForm
              v-bind:current-org-id="currentOrgId"
            ></BaseDataForm>
          </v-col>
          <v-col v-if="canSeeEmployeeData >= 2" cols="12" md="6" lg="3">
            <UserList
              v-bind:current-org-id="currentOrgId"
            ></UserList>
          </v-col>
          <v-col v-if="canSeeEmployeeData >= 1" cols="12" md="12" lg="6" :offset-lg="canSeeEmployeeData === 1 ? 1 : undefined">
            <employee-data></employee-data>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { genericService } from "../services";
import BaseDataForm from "./organisation/BaseDataForm";
import UserList from "./user/UserList";
import EmployeeData from "./employee/EmployeeData";
import NavigationDrawer from "./layout/NavigationDrawer";
import Navbar from "./layout/Navbar";

export default {
  name: "FormsPage",
  components: {
    UserList,
    BaseDataForm,
    EmployeeData,
    NavigationDrawer,
    Navbar
  },
  data () {
    return {
      drawer: false,
      breadcrumb: '',
      // selected user of logged in person
      selectedUser: undefined,

      mandantChangedByUser: false,
      currentMandant: null,
      currentSelection: {
        // currently selected (other) user as reflected in the url
        user: undefined,
        // currently selected org     as reflected in the url
        org: undefined,
      },
    }
  },
  beforeRouteUpdate(to, from, next) {
    // gets called whenever the url changes (not on page reload!)

    if (to.params.org_id === undefined && from.params.org_id !== undefined) {
      this.currentSelection.org = undefined;
    }
    if (
      to.params.org_id !== undefined
      && to.params.org_id !== from.params.org_id
    ) {
      this.currentSelection.org = parseInt(to.params.org_id);
    }

    if (to.params.user_id === undefined && from.params.user_id !== undefined) {
      this.currentSelection.user = undefined;
    }
    if (
         to.params.user_id !== undefined
      && to.params.org_id !== undefined
      && to.params.user_id !== from.params.user_id
    ) {
      this.currentSelection.user = parseInt(to.params.user_id);
    }

    next();
  },
  computed: {
    ...mapState({
      mandants: state => state.account.mandants,
      acl: state => state.account.acl
    }),
    currentOrgId() {
      return this.currentSelection.org || 0
    },
    currentUserId() {
      return this.currentSelection.user || 0
    },
    canSeeOrgTree() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_tree_show;
    },
    canEditOrgTree() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_tree_edit;
    },
    canSeeEmployeeData() {
      /**
       * 0 = no data
       * 1 = only own data
       * 2 = only own org member data
       * 3 = all data
       */
      if (!this.acl) {
        return 0;
      }
      if (this.acl.user_general_read) {
        return 3;
      }
      if (this.acl.user_organisation_read) {
        return 2;
      }
      if (this.acl.user_itself_read) {
        return 1;
      }
      return 0;
    },
  },
  watch: {
    mandants() {
      this.selectedUser = this.mandants.find(el => el.selected)
    },
    selectedUser() {
      /**
       * we get here by:
       *   1. login: loading mandant list and auto selecting the first user
       *   2. reloading page: loading mandant list and auto selecting the first user
       *   3. selecting a user from mandant select
       *
       * for 1 no route params are set and flag mandantChangedByUser is false
       * for 2 route params are set and flag mandantChangedByUser is false
       * for 3 route params are set and flag mandantChangedByUser is true
       *
       * with:
       *   1 we want to load selected org/user
       *   2 we want to reload route params data
       *     in this case at least org_id is set
       *     when user_id is empty do not load any user
       *   3 see 1
       */

      // get new token and acl set
      this.changeUser(this.selectedUser.user_id)
        .then(
          () => {
            // inform
            this.$store.dispatch('alert/success', `gewechselt zu ${this.selectedUser.org_name}`, {root: true});

            const org_id = this.$route.params.org_id && !this.mandantChangedByUser
              // on reload, route params are already set and flag mandantChangedByUser is false
              ? this.$route.params.org_id
              // else
              : this.selectedUser.org_id;

            const user_id =
              (this.$route.params.org_id || this.$route.params.user_id) && !this.mandantChangedByUser
              // on reload, route params are already set and flag mandantChangedByUser is false
              ? this.$route.params.user_id
              // else
              : this.selectedUser.user_id;

            // check if we came here by actively routing
            this.currentSelection = {
              user: parseInt(user_id),
              org: parseInt(org_id),
            }
            // inform org tree to update itself using the current mandant, AFTER changing the current users role
            this.currentMandant = this.selectedUser;
          })
    },
    currentSelection(val) {
      // begin load cascade if route changed
      if (
           this.$route.params.org_id
        && this.$route.params.user_id
        && (
             val.org !== parseInt(this.$route.params.org_id)
          || val.user !== parseInt(this.$route.params.user_id)
        )
      ) {
        this.$router.push({
          name: 'verzeichnis',
          params: {
            org_id: val.org,
            user_id: val.user,
          }
        })
      }
    },
    currentOrgId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.buildBreadcrumb(newVal, this.currentUserId);
      }
    },
    currentUserId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.buildBreadcrumb(this.currentOrgId, newVal);
        this.getChecked(!Number.isInteger(newVal) || 0 === newVal ? null : newVal)
      }
    },
  },
  methods: {
    ...mapActions('account', ['logout', 'changeUser', 'getMandants', 'getAcls']),
    ...mapActions('users', ['getChecked']),
    buildBreadcrumb (org_id, user_id) {
      if (Number.isInteger(user_id) && Number.isInteger(org_id)) {
        genericService.get(`organisations/breadcrumb/${org_id}/${user_id}.json`)
          .then(bc => {
            this.breadcrumb = bc;
          });
      } else {
        this.breadcrumb = '';
      }
    },
    mandantChanged() {
      this.mandantChangedByUser = true;
    },
    updateOrgId(id) {
      if (this.currentSelection.org != id && id !== 0) {
        this.$router.push({
          name: 'verzeichnis',
          params: {
            org_id: id,
            user_id: undefined
          }
        });
      }
    }
  },
  created() {
    this.getMandants();
    this.getAcls();
  }
}
</script>
