<template>
  <module-template :name="name" v-model="value">
    <!--v-row class="module-form-row">
      <v-col cols="12">
        <d-text-field
          label="Vertrag vom"
          hide-details
          disabled
          readonly
          v-model="moduleOptions.ProgramKdb.contractdate"
        ></d-text-field>
      </v-col>
    </v-row-->
    <v-row class="module-form-row">
      <v-col cols="12">
        <d-checkbox
          label="Angebotskonfig. zeigen"
          hide-details
          :disabled="!canEditPermissions"
          v-model="value.options.showOfferConfigurator"
        ></d-checkbox>
      </v-col>
    </v-row>
    <module-permissions
      v-for="(item, key) in filteredItem"
      :key="key"
      v-model="item.item"
      :role="value.role"
      :options="value.options"
      @role-changed="onRoleChanged"
    ></module-permissions>
  </module-template>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import moduleTemplate from './module-template'
import modulePermissions from './module-permissions'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    moduleTemplate,
    modulePermissions
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl
    }),
    ...mapGetters({
      moduleOptions: 'organisation/moduleOptions',
    }),
    canEditPermissions() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_authorization_edit;
    },
    filteredItem() {
      // returns objectified version of acl item definition
      return this.value.module.map((i) => {return {item: i}});
    }
  },
  methods: {
    onRoleChanged(localState) {
      this.value.role = localState.value
    }
  }
}
</script>
