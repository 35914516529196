<template>
  <module-template :name="name" v-model="value">
    <!--v-row class="module-form-row">
      <v-col cols="12">
        <d-text-field
          label="Vertrag vom"
          hide-details
          disabled
          readonly
          v-model="moduleOptions.ProgramKdbfmv.contractdate"
        ></d-text-field>
      </v-col>
    </v-row-->
    <module-permissions
      v-for="(item, key) in filteredItem"
      :key="key"
      v-model="item.item"
      :role="value.role"
      :options="value.options"
      @role-changed="onRoleChanged"
    >
      <template v-slot:groupTools="group">
        <div v-if="Number(group.item.role) === 5">
        <v-row class="item-layer-bottom module-form-row">
          <v-col cols="3">
            <d-select
              dense label="OV"
              v-model="value.options.ov"
              :items="fmvOvItems"
              :disabled="value.role != 5"
            ></d-select>
          </v-col>
        </v-row>
        </div>
      </template>
    </module-permissions>
  </module-template>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import moduleTemplate from './module-template'
import modulePermissions from './module-permissions'
import { genericService } from "../../../services";

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    moduleTemplate,
    modulePermissions
  },
  data () {
    return {
      fmvOvItems: []
    }
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl,
    }),
    ...mapGetters({
      moduleOptions: 'organisation/moduleOptions',
      org: 'organisation/fullRec',
    }),
    canEditPermissions() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_authorization_edit;
    },
    filteredItem() {
      return this.value.module.map((i) => {return {item: i}});
    }
  },
  methods: {
    onRoleChanged(localState) {
      this.value.role = localState.value
    }
  },
  watch: {
    'org.id.value': {
      immediate: true,
      handler: function() {
        const id = this.org.id.value
        if (id) {
          genericService.get(`organisations/listovs/${id}.json`)
            .then(
              ovs => {
                this.fmvOvItems = ovs
              }
            )
        }
      }
    }
  }
}
</script>
