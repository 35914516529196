import { authHeader, config, handleResponse } from '../helpers';

export const moduleService = {
  getKdbModule,
  getProgram,
  update
};

function getKdbModule(module) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/options/kdbmodule/${module}.json`, requestOptions).then(handleResponse);
}

function getProgram(module, user_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/programs/view/${module}/${user_id}.json`, requestOptions).then(handleResponse);
}

function update(module, options, user_id) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(options)
  };

  return fetch(`${config.apiUrl}/programs/edit/${module}/${user_id}.json`, requestOptions).then(handleResponse);
}
