import { authHeader, config, handleResponse/*, util*/ } from '../helpers';

export const passwordsService = {
  request,
  change,
  checkToken
};

function request(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/passwords/request.json`, requestOptions).then(handleResponse);
}

function change(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/passwords/change.json`, requestOptions).then(handleResponse);
}

function checkToken(token) {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${config.apiUrl}/passwords/checkToken/${token}`, requestOptions).then(handleResponse);
}
