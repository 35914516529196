import { regex } from './regex';

export const validation = {
  none: [],
  required: [
    v => !!v || 'Dieses Feld muss ausgefüllt sein'
  ],
  string: [
    v => (!v || v.length <= 255) || 'Dieses Feld darf maximal 255 Zeichen enthalten'
  ],
  stringRequired: [
    v => !!v || 'Dieses Feld muss ausgefüllt sein',
    v => (!v || v.length <= 255) || 'Dieses Feld darf maximal 255 Zeichen enthalten'
  ],
  digits: [
    v => (!v || regex.digits.test(v)) || 'Dieses Feld darf nur aus Ziffern bestehen'
  ],
  digitsRequired: [
    v => !!v || 'Dieses Feld muss ausgefüllt sein',
    v => (!v || regex.digits.test(v)) || 'Dieses Feld darf nur aus Ziffern bestehen'
  ],
  email: [
    v => (!v || regex.email.test(v)) || 'Dieses Feld muss eine gültige E-Mail-Adresse enthalten'
  ],
  emailRequired: [
    v => !!v || 'Dieses Feld muss ausgefüllt sein',
    v => (!v || regex.email.test(v)) || 'Dieses Feld muss eine gültige E-Mail-Adresse enthalten'
  ],
  url: [
    v => (!v || regex.url.test(v)) || 'Dieses Feld muss eine gültige URL enthalten'
  ],
  urlRequired: [
    v => !!v || 'Dieses Feld muss ausgefüllt sein',
    v => (!v || regex.url.test(v)) || 'Dieses Feld muss eine gültige URL enthalten'
  ],
  phone: [
    v => (!v || regex.phone.test(v)) || 'Dieses Feld muss eine gültige Telefonnummer enthalten'
  ],
  phoneRequired: [
    v => !!v || 'Dieses Feld muss ausgefüllt sein',
    v => (!v || regex.phone.test(v)) || 'Dieses Feld muss eine gültige Telefonnummer enthalten'
  ],
  zipcode: [
    v => regex.digits.test(v) || 'Die PLZ darf nur aus Ziffern bestehen',
    v => (v !== null && v.length === 5) || 'Die PLZ muss genau 5 Stellen enthalten'
  ],
  zipcodeRequired: [
    v => !!v || 'Dieses Feld muss ausgefüllt sein',
    v => regex.digits.test(v) || 'Die PLZ darf nur aus Ziffern bestehen',
    v => (v !== null && v.length === 5) || 'Die PLZ muss genau 5 Stellen enthalten'
  ]
};
