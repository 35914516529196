<style scoped>
.v-treeview {
  overflow-y: auto;
  background-color: white;
}
.treeview-headline {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}
.treeview-content {
  font-size: 14px;
}
.treeview-title {
  margin-left: 30px;
}
.treeview-cell-container {
  display: flex;
  flex-direction: row;
}
.treeview-cell {
  text-align: center;
  width: 80px;
}
.treeview-content >>> .v-treeview-node {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>

<template>
  <d-expander>
    <template slot="header">
      <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </template>
    <!--template slot="contextMenu">
      <v-list>
        <v-list-item>
          <v-list-item-title>
            Export
            <v-icon small style="padding: 3px;" class="ml-2">fas fa-file-export</v-icon>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            Drucken
            <v-icon class="ml-2">print</v-icon>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template-->
    <template>
      <div class="my-2" v-if="subtitle">{{ subtitle }}</div>
      <v-toolbar class="px-2 treeview-headline" flat dense>
        <div class="flex-grow-1 treeview-title">Gliederung</div>
        <div class="treeview-cell">Aktiv</div>
        <div class="treeview-cell">Gesamt</div>
      </v-toolbar>
      <v-treeview
        :active.sync="active"
        :open.sync="open"
        :load-children="fetchOrgs"
        :items="items"
        selection-type="independent"
        hoverable
        open-on-click
        color="primary"
        class="treeview-content"
        transition
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="item.children">
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
          <v-icon v-else>
            {{ 'mdi-domain' }}
          </v-icon>
        </template>
        <template v-slot:label="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">{{item.name}}</span>
            </template>
            <span>{{item.name}}</span>
          </v-tooltip>
        </template>
        <template v-slot:append="{ item }">
          <div class="treeview-cell-container">
            <div class="treeview-cell">{{ item.active.toLocaleString() }}</div>
            <div class="treeview-cell">{{ item.total.toLocaleString() }}</div>
          </div>
        </template>
      </v-treeview>
    </template>
  </d-expander>
</template>

<script>
import {genericService} from "../../services";

export default {
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      active: [],
      open: [],
      nodes: [],
    }
  },
  computed: {
    items () {
      return this.nodes
    }
  },
  methods: {
    async fetchOrgs (item) {
      return genericService.get(`statistics/nodes/${item.id}.json`)
        .then(json => {
          if (0 === this.nodes.length) {
            this.nodes.push(...json)
          } else {
            item.children.push(...json)
          }
        })
    },
  },
  created() {
    this.fetchOrgs({id: 0}).then(() => {this.open.push(1);});
  }
}
</script>
