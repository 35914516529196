const state = {
  type: null,
  message: null,
  color: null
};

const actions = {
  success({ commit }, message) {
    commit('clear');
    commit('success', message);
  },
  error({ commit }, message) {
    commit('clear');
    commit('error', message);
  },
  clear({ commit }) {
    commit('clear');
  }
};

const mutations = {
  success(state, message) {
    state.type = 'white--text';
    state.color = 'success';
    state.message = message;
  },
  error(state, message) {
    state.type = 'red--text';
    state.color = 'error';
    state.message = message;
  },
  clear(state) {
    state.type = null;
    state.color = null;
    state.message = null;
  }
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations
};
