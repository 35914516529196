import { statisticsService } from '../../services';
import Vue from "vue";

const state = {
  common: [],
  ddm: [],
  data: {},
  loading: {}
};

const actions = {
  getList({ dispatch, commit }, type) {
    return statisticsService.getList(type)
      .then(
        data => commit('getListSuccess', {type, data}),
        () => {
          dispatch('alert/error', `Fehler beim Laden der Statistiken.`, { root: true });
        }
      );
  },

  getData({ dispatch, commit }, {type, options}) {
    commit('dataSuccess', {type, data: null})
    commit('loading', {type, loading: true})
    statisticsService.getStatistic(type, options)
      .then(
        data => commit('dataSuccess', {type, data}),
        () => {
          dispatch('alert/error', `Fehler beim Laden der Statistik ${type}.`, { root: true });
        }
      )
      .finally(() => {
        commit('loading', {type, loading: false})
      });
  }
};

const mutations = {
  getListSuccess(state, {type, data}) {
    Vue.set(state, type, data);
  },
  dataSuccess(state, {type, data}) {
    Vue.set(state.data, type, data)
  },
  loading(state, {type, loading}) {
    Vue.set(state.loading, type, loading)
  }
};

export const statistics = {
  namespaced: true,
  state,
  actions,
  mutations
};
