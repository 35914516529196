<template>
<d-action-dialog v-bind:value="value" v-on:input="$emit('input', $event)" :onSave="onSave">
  <template slot="title">
    API-Schlüssel - Verwaltung dieser Gliederung
  </template>
  <template>
    <v-text-field
      label="API-Schlüssel"
      placeholder="kein API-Schlüssel vorhanden"
      :value="apiKey"
      outlined
      readonly
    ></v-text-field>
    <v-btn class="mr-3" @click="generateApiKey">
      <v-icon left>refresh</v-icon> Generieren
    </v-btn>
    <v-btn @click="deleteApiKey">
      <v-icon left>delete</v-icon> Entfernen
    </v-btn>
  </template>
</d-action-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { util } from '../../helpers'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    item: {
      required: true,
      default: ''
    },
    orgId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      working: false,
      apiKey: ''
    }
  },
  methods: {
    ...mapActions('organisation', ['updateApiKey']),
    onSave() {
      this.updateApiKey({id: this.orgId, key: this.apiKey});
    },
    generateApiKey() {
      this.apiKey = util.genHexString(32);
    },
    deleteApiKey() {
      this.apiKey = '';
    },
    closeDialog() {
      this.$emit('input', false);
    }
  },
  watch: {
    value (val) {
      if (val) {
        this.apiKey = this.item;
      }
    }
  }
}
</script>
