import {genericService} from "../../services";
import {filter, get, isEmpty, remove } from 'lodash'

const state = {
  appurls: [],
  helpurls: [],
  prefix: [],
  title: [],
  roles: [],
  function_title: [],
  contract_types: [],
  loading: [],
  typo3usergroups: [],
  tags: [],
  rate_groups: [],
  module_names: {},
};

const actions = {
  loadOption({ dispatch, commit }, { option, ...params }) {
    if (!isEmpty(state[option]) || state.loading.includes(option)) {
      return
    }
    commit('loading', option)
    genericService.get(`options/${option}.json`, params)
      .then(
        data => commit('loadSuccess', {option, data}),
        () => {
          dispatch('alert/error', 'Fehler beim Laden der Optionen.', { root: true });
        }
      );
  }
};

const mutations = {
  loading(state, option) {
    state.loading.push(option)
  },
  loadSuccess(state, {option, data}) {
    state[option] = data;
    remove(state.loading, el => el === option)
  }
};

const getters = {
  appurls: state => {
    return filter(state.appurls, appurl => appurl.short !== 'BVw')
  },
  helpurls: state => {
    return state.helpurls
  },
  prefix: state => id => {
    return get(state.prefix, `${id}`, '')
  },
  title: state => id => {
    return get(state.title, `${id}`, '')
  },
};

export const option = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
