<style scoped>
.v-app-bar {
  box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.25);
}
.v-app-bar >>> .v-toolbar__content {
  padding: 0 !important;
}
.app-bar-btn {
  height: 56px !important;
  width: 60px !important;
  min-width: unset !important;
}
</style>

<template>
  <v-app-bar height="56" app dense flat color="white">
    <v-menu offset-y open-on-click :nudge-width="150">
      <template v-slot:activator="{ on }">
        <d-button class="app-bar-btn" v-on="on">
          <v-icon dark>menu</v-icon>
        </d-button>
      </template>
      <v-card>
        <v-list color="#ECEFF1">
          <v-list-item>
            <v-list-item-avatar>
              <img :src="userImg" :alt="userName">
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ userName }}</v-list-item-title>
              <v-list-item-subtitle>ID: {{ userId }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense>
            <v-btn text small @click="handleEdit">
              BEARBEITEN
            </v-btn>
          </v-list-item>
          <v-list-item dense>
            <v-btn color="primary" tile small @click="handleLogout">
              ABMELDEN
            </v-btn>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-subheader>
            <span class="primary--text">Benutzerverwaltung</span>
          </v-subheader>
          <v-list-item
            dense
            v-for="(appurl, i) in appurls"
            :key="i"
            @click="handleSSOAppLinkClick(appurl)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="appurl.long"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list-item
          dense
          v-for="(helpurl, i) in helpurls"
          :key="i"
          @click="handleHelpLinkClick(helpurl)"
        >
          <v-list-item-content>
            <v-list-item-title v-text="helpurl.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <div
          style="background-color: #ECEFF1"
          class="font-weight-thin text-caption pa-1 d-flex flex-column align-end"
        >
          <span>ver {{ version }}</span>
        </div>
      </v-card>
    </v-menu>
    <v-tabs @change="handleChange($event)" hide-slider v-bind:value="value">
      <v-tab :to="{ name: 'verzeichnis' }">
        <v-icon left>perm_identity</v-icon>
        Verzeichnis
      </v-tab>
      <v-tab v-if="canSeeSearch" :to="{ name: 'suche' }">
        <v-icon left>search</v-icon>
        Suche
      </v-tab>
      <v-tab v-if="canSeeStatistics" :to="{ name: 'statistik' }">
        <v-icon left>signal_cellular_alt</v-icon>
        Statistik
      </v-tab>
      <v-tab v-if="canSeeDdmStatistics" :to="{ name: 'ddm-statistik' }">
        <v-icon left>assessment</v-icon>
        DDM Statistik
      </v-tab>
      <v-tab v-if="canSeeAdmin" :to="{ name: 'admin' }">
        <v-icon style="font-size: 18px; padding: 3px; margin-right: 8px;">fas fa-chess-queen</v-icon>
        Admin
      </v-tab>
    </v-tabs>
    <v-spacer></v-spacer>
    <v-img contain height="48" src="/img/home/logo.png"></v-img>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { genericService } from "../../services";

export default {
  props: ['value'],
  data() {
    return {
      userId: 0,
      userName: 'Dr. Kreuz',
      userImg: '/img/icon.png'
    }
  },
  computed: {
    ...mapState({
      mandants: state => state.account.mandants,
      acl: state => state.account.acl
    }),
    ...mapGetters({
      version: 'app/getVersionString',
      appurls: 'option/appurls',
      helpurls: 'option/helpurls',
    }),
    canSeeSearch() {
      if (!this.acl) {
        return false;
      }
      return this.acl.search_general_show;
    },
    canSeeStatistics() {
      if (!this.acl) {
        return false;
      }
      return this.acl.statistics_general_show;
    },
    canSeeDdmStatistics() {
      if (!this.acl) {
        return false;
      }
      return this.acl.statistics_ddm_show;
    },
    canSeeAdmin() {
      if (!this.acl) {
        return false;
      }
      return this.acl.ddm_admin_edit;
    }
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('option', ['loadOption']),
    handleLogout() {
      this.logout();
      this.$router.push('/login');
    },
    handleHelpLinkClick(item) {
      let link = document.createElement('a');
      link.href = item.url;
      link.target = item.text;

      const event = new MouseEvent('click', {
        'view': window,
        'bubbles': false,
        'cancelable': true
      });
      link.dispatchEvent(event);
    },
    handleSSOAppLinkClick(item) {
      genericService.get(`sessions/get-sso-token.json`)
        .then(
          data => {
            let link = document.createElement('a');
            link.href = item.url.replace('#ticket#', data.token);
            link.target = item.long;

            const event = new MouseEvent('click', {
              'view': window,
              'bubbles': false,
              'cancelable': true
            });
            link.dispatchEvent(event);
          },
          error => {
            this.$store.dispatch('alert/error', error, { root: true });
          }
        );
    },
    updateUserData () {
      genericService.get('sessions/get-user-data.json')
        .then(
          data => {
            this.userId = data.id;
            this.userName = data.name;
            this.userImg = '/img/people/' + data.img;
          }
        )
    },
    handleChange(event) {
      this.$emit('input', event);
    },
    handleEdit() {
      const user = this.mandants.find(el => el.selected)
      this.$router.push({
        name: 'verzeichnis',
        params: {
          org_id: user.org_id,
          user_id: user.user_id
        }
      })
    }
  },
  created () {
    this.loadOption({option: 'appurls'});
    this.loadOption({option: 'helpurls'})
    this.updateUserData();
  }
}
</script>
