<style scoped>
.statistic-row >>> .v-toolbar__content {
  padding: 0 !important;
}
</style>

<template>
  <div class="flex-grow-1 fill-height">
    <navbar />
    <div class="background-dark flex-grow-1 fill-height">
      <v-row class="pa-4 flex-grow-1 fill-width statistic-row">
        <v-col
          v-for="i in 4"
          :key="i"
          cols="12"
          sm="6"
          md="3"
        >
          <statistic-table
            v-for="(type, idx) in column(i - 1)"
            :statistic="type"
            :key="idx"
            class="mb-6"
            :title="type"
            subtitle="1"
            height="300"
          ></statistic-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import StatisticTable from "./statistics/StatisticTable";
import Navbar from "./layout/Navbar";
import {mapActions, mapState} from "vuex";

export default {
  name: "StatisticsPage",
  components: {
    StatisticTable,
    Navbar
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      statisticsList: state => state.statistics.common,
    })
  },
  methods: {
    ...mapActions('statistics', ['getList']),
    column: function(col) {
      return this.$_.filter(this.statisticsList, (val, idx) => {
        return col === idx % 4
      })
    }
  },
  created() {
    this.getList('common')
  }
}
</script>
