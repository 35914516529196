<template>
  <module-template :name="name" v-model="value">
    <v-row class="module-form-row">
      <v-col cols="12">
        <d-select
          label="Berechtigung"
          hide-details
          :disabled="!canEditPermissions"
          v-model="value.role"
          :items="permissionItems"
        ></d-select>
      </v-col>
    </v-row>
    <v-row class="module-form-row">
      <v-col cols="12">
        <d-autocomplete
          label="Funktionen"
          multiple
          hide-details
          :disabled="!canEditPermissions"
          v-model="value.options.group"
          :items="roleItems"
        ></d-autocomplete>
      </v-col>
    </v-row>
    <v-row class="module-form-row">
      <v-col cols="12">
        Hat zusätzlich Zugriff auf:
      </v-col>
    </v-row>
    <v-row class="module-form-row">
      <v-col cols="12">
        <d-checkbox
          label="Fachpublikationen"
          hide-details
          :disabled="!canEditPermissions"
          v-model="value.options.publication"
        ></d-checkbox>
      </v-col>
    </v-row>
    <v-row class="module-form-row">
      <v-col cols="12">
        <d-checkbox
          label="Bilddatenbank"
          hide-details
          :disabled="!canEditPermissions"
          v-model="value.options.picturedb"
        ></d-checkbox>
      </v-col>
    </v-row>
  </module-template>
</template>

<script>
import { mapState } from 'vuex'
import moduleTemplate from './module-template'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    moduleTemplate
  },
  data() {
    return {
      permissionItems: [
        {
          value: 10,
          text: 'Nutzer',
          requiresDDM: false,
        },
        {
          value: 20,
          text: 'Nutzer Download',
          requiresDDM: false,
        },
        {
          value: 30,
          text: 'Nutzer Upload',
          requiresDDM: false,
        },
        {
          value: 40,
          text: 'Admin Verlag',
          requiresDDM: true,
        },
        {
          value: 50,
          text: 'Admin DRK Service',
          requiresDDM: true,
        }
      ],
      roleItems: [
        {
          value: 1,
          text: 'Kreisgeschäftsführer'
        },
        {
          value: 2,
          text: 'Schwesternschaft'
        },
        {
          value: 3,
          text: 'Sonstige'
        },
        {
          value: 4,
          text: 'Bayern'
        },
        {
          value: 5,
          text: 'Auslandshilfe'
        },
        {
          value: 6,
          text: 'Nordrhein'
        },
        {
          value: 7,
          text: 'RD-NiSa'
        },
        {
          value: 8,
          text: 'Strategieverantwortliche'
        },
        {
          value: 9,
          text: 'LV-Leiter KOMA'
        },
        {
          value: 10,
          text: 'WB-Contentmanagement'
        },
        {
          value: 11,
          text: 'Hausnotruf-AG'
        },
        {
          value: 12,
          text: 'WB-Datenpfleger'
        },
        {
          value: 13,
          text: 'Multiplikator'
        },
        {
          value: 14,
          text: 'DRKS Verlag'
        },
        {
          value: 15,
          text: 'CRM-Beirat'
        }
      ]
    }
  },
  methods: {
    filterRoles() {
      this.permissionItems.forEach(item => {
        // disable selectable roles, based on currently logged in users permissions
        item.disabled = item.requiresDDM && this.currentMandant.role_id !== 1
      })
    },
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl,
      mandants: state => state.account.mandants,
    }),
    currentMandant() {
      return this.mandants.find(mandant => mandant.selected)
    },
    editingDisabled() {
      // disable editing, iff wd role is ddm, but currently logged in users isn't
      return [40, 50].indexOf(this.value.role) !== -1 && this.currentMandant.role_id !== 1
    },
    canEditPermissions() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_authorization_edit && !this.editingDisabled;
    }
  },
  watch: {
    mandants: {
      deep: true,
      immediate: true,
      handler () {
        this.filterRoles()
      }
    }
  }
}
</script>
