<style scoped>
.tab-container {
  overflow-y: auto;
  padding-right: 16px;
  padding-bottom: 16px;
  max-height: 70vh;
}
.v-expansion-panels >>> .v-expansion-panel-content {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}
.expander-no-padding >>> .v-expansion-panel-content {
  padding: 0px !important;
}
.one-line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<template>
  <div>
    <d-expander>
      <template slot="header">
        <v-toolbar-title>Organisation</v-toolbar-title>
        <v-spacer></v-spacer>
      </template>
      <template v-if="hasContextMenuEntries" slot="contextMenu">
        <v-list>
          <v-list-item v-if="canEditDatabaseAccess" @click="showDatabaseAccessDialog">
            <v-list-item-title>Datenbankzugriff verwalten</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="canEditApiKey" :disabled="!fullRec || !fullRec.apikey" @click="showApiKeyDialog">
            <v-list-item-title>API-Schlüssel bearbeiten</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
      <template>
        <v-tabs
          v-model="tab"
          background-color="blue-grey lighten-5"
          class="fill-height tab-container"
          show-arrows
        >
          <v-tab>Stammdaten</v-tab>
          <v-tab v-if="hasMoreInfoEntries">Zusatzinfo</v-tab>
          <v-tab v-if="canSeeContracts">Verträge</v-tab>

          <v-tabs-items
            v-model="tab"
            class="pt-4 blue-grey lighten-5 flex-grow-1"
          >
            <v-tab-item>
              <v-card
                v-if="coreDataLoaded"
                flat
                outlined
                class="flex-grow-1"
              >
                <v-toolbar dense flat class="px-3">
                  <v-toolbar-title class="body-1">Daten</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on"><v-icon>info</v-icon></v-btn>
                    </template>
                    <span v-if="fullRec">{{ fullRec.log.value }}</span>
                  </v-tooltip>
                  <v-btn v-if="canEdit" :disabled="!typesArray" icon>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon @click.stop="showBaseDataDialog" v-on="on">edit</v-icon>
                      </template>
                      <span>Stammdaten ändern</span>
                    </v-tooltip>
                  </v-btn>
                </v-toolbar>

                <v-container flat outlined class="flex-grow-1 pa-0">
                  <v-row
                    v-for="(item, key) in baseDataItem"
                    :key="key"
                    no-gutters
                    style="border-top: thin solid rgba(0,0,0,.1) !important;"
                    class="pa-2 body-2 text--secondary base-data-row"
                    align="stretch"
                    :class="{'form-row-even': key % 2 === 0}"
                  >
                    <v-col cols="4">{{ item.title }}</v-col>
                    <v-col v-if="item.link" cols="8">
                      <a :href="item.link">
                        {{ item.value }}
                      </a>
                    </v-col>
                    <v-col v-else cols="8">{{ item.value }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-row v-else justify="center">
                <v-icon large>fas fa-spinner fa-spin</v-icon>
              </v-row>
            </v-tab-item>
            <v-tab-item v-if="hasMoreInfoEntries">
              <v-card flat>
                <v-row
                  no-gutters
                  v-if="canSeeApiKey"
                  class="pa-2 body-2 text--secondary"
                >
                  <v-col cols="3">API-Schlüssel</v-col>
                  <v-col cols="9" v-if="fullRec && fullRec.apikey">{{fullRec.apikey.value}}</v-col>
                </v-row>
                <v-row
                  no-gutters
                  v-if="canSeeDDMFields"
                  class="pa-2 body-2 text--secondary"
                >
                  <v-col cols="3">Anmerkung</v-col>
                  <v-col cols="9" v-if="fullRec && fullRec.comment">{{fullRec.comment.value}}</v-col>
                </v-row>
                <v-row
                  no-gutters
                  v-if="canSeeDatabaseAccess"
                  class="pa-2 body-2 text--secondary"
                >
                  Zugang zu:
                </v-row>
                <v-row
                  no-gutters
                  v-if="canSeeDatabaseAccess && fullRec && fullRec.moduleOptions"
                  class="pa-2 pt-0 body-2 text--secondary"
                >
                  <v-chip
                    small
                    class="ma-1"
                    v-for="(item, index) in activeModules"
                    :key="index"
                  >
                    {{ item }}
                  </v-chip>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item v-if="canSeeContracts">
              <contracts v-bind:current-org-id="currentOrgId"></contracts>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </template>
    </d-expander>
    <edit-base-data
      v-if="fullRec && typesArray"
      v-model="baseDataDialog"
      :orgId="currentOrgId"
      :items="coreData"
      :types="typesArray"
      :comment="fullRec ? fullRec.comment : null"
    ></edit-base-data>
    <edit-database-access
      v-model="databaseAccessDialog"
    ></edit-database-access>
    <edit-api-key
      v-model="apiKeyDialog"
      v-if="fullRec"
      :item="fullRec.apikey.value"
      :orgId="currentOrgId"
    ></edit-api-key>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import EditBaseData from './EditBaseData'
import EditDatabaseAccess from './EditDatabaseAccess'
import EditApiKey from './EditApiKey'
import Contracts from "./Contracts";

export default {
  name: "CoreDataForm",
  components: {
    EditBaseData,
    EditDatabaseAccess,
    EditApiKey,
    Contracts
  },
  props: {
    currentOrgId: Number
  },
  data () {
    return {
      tab: null,
      baseDataDialog: false,
      databaseAccessDialog: false,
      apiKeyDialog: false,
    }
  },
  computed: {
    ...mapState({
      organisation: state => state.organisation,
      acl: state => state.account.acl,
      rateGroups: state => state.option.rate_groups,
    }),
    ...mapGetters({
      fullRec: 'organisation/fullRec',
      coreData: 'organisation/coreData',
      activeModules: 'organisation/activeModules',
      typesArray: 'organisation/typesArray'
    }),
    apikey() {
      if (this.fullRec) {
        return this.fullRec.apikey.value;
      }
      return '';
    },
    coreDataLoaded() {
      return Object.keys(this.coreData).length > 0;
    },
    canEdit() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_general_edit || this.acl.organisation_partly_edit;
    },
    canSeeApiKey() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_apikey_read;
    },
    canEditApiKey() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_apikey_create;
    },
    canSeeDatabaseAccess() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_modulpermission_read;
    },
    canEditDatabaseAccess() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_modulpermission_edit;
    },
    canSeeDDMFields() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_ddm_fields;
    },
    canSeeContracts() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_own_contract_management ||
             this.acl.organisation_all_contract_management;
    },
    hasContextMenuEntries() {
      return !!this.canEditApiKey || !!this.canEditDatabaseAccess;
    },
    hasMoreInfoEntries() {
      return !!this.canSeeApiKey || !!this.canSeeDDMFields || !!this.canSeeDatabaseAccess;
    },
    baseDataItem() {
      if (!this.coreDataLoaded) {
        return null;
      }
      let data = !this.canEditApiKey ? [] : [{
        title: 'ID',
        value: this.fullRec.id.value
      }];
      if (this.canSeeDDMFields) {
        data = data.concat([{
          title: 'Debitorennummer',
          value: this.coreData.debitor.value
        }])
      }
      data = data.concat([
        {
          title: 'Nummer',
          value: this.coreData.code.value
        },
        {
          title: 'Name',
          value: this.coreData.name.value
        },
        {
          title: 'Straße',
          value: this.coreData.street.value
        },
        {
          title: 'Postleitzahl',
          value: this.coreData.zipcode.value
        },
        {
          title: 'Ort',
          value: this.coreData.city.value
        },
        {
          title: 'Telefon',
          value: this.coreData.phone.value
        },
        {
          title: 'Fax',
          value: this.coreData.fax.value
        },
        {
          title: 'E-Mail',
          value: this.coreData.email.value,
          link: this.coreData.email.value ? 'mailto:' + this.coreData.email.value : undefined
        },
        {
          title: 'Website',
          value: this.coreData.webpage.value,
          link: this.coreData.webpage.value
        },
        {
          title: 'Gliederungstyp',
          value: this.coreData.type_name.value
        }
      ])
      if (this.canSeeApiKey) {
        const val = this.$_.nth(this.rateGroups, this.coreData.rate_group.value)
        data = data.concat([{
          title: 'Tarifgruppe',
          value: val
        }])
      }
      data = data.concat([{
        title: 'Bearbeiter Anmeldungen',
        value: this.coreData.application_reviser_name.value
      }])
      return data
    }
  },
  watch: {
    currentOrgId: {
      immediate: true,
      handler(newId) {
        if (0 < newId) {
          this.loadById(newId)
        }
      }
    }
  },
  mounted() {
    this.loadTypes();
    this.loadOption({option: 'rate_groups'});
  },
  methods: {
    ...mapActions('option', ['loadOption']),
    ...mapActions('organisation', ['loadById', 'loadTypes']),
    showBaseDataDialog() {
      this.baseDataDialog = true;
    },
    showDatabaseAccessDialog() {
      this.databaseAccessDialog = true;
    },
    showApiKeyDialog() {
      this.apiKeyDialog = true;
    }
  }
}
</script>
