<template>
  <d-expander>
    <template v-slot:header>
      <v-toolbar-title v-if="data.title">{{ data.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </template>
    <template v-slot:contextMenu v-if="data.export">
      <v-list>
        <v-list-item @click="$emit('export')">
          <v-list-item-title>
            Export
            <v-icon small style="padding: 3px;" class="ml-2">fas fa-file-export</v-icon>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <template>
      <div class="my-2" v-if="data.subtitle">{{ data.subtitle }}</div>
      <v-data-table
        dense
        hide-default-footer
        :items-per-page="-1"
        :headers="data.headers"
        :items="data.items"
        :height="height || null"
        :loading="loading"
      >
        <template v-slot:item="{ item, headers }">
          <tr>
            <td
              v-for="(column, idx) in headers"
              :key="idx"
              :class="`text-${column.align || 'start'}`"
            >
              <span :class="classFromMeta(item)">{{item[column.value]}}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </d-expander>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  props: {
    statistic: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    height: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      default: {
        headers: [{text: '', value: 'value'}],
        items: [{value: ''}]
      }
    }
  },
  computed: {
    ...mapState({
      statisticsData: state => state.statistics.data,
      loadingState: state => state.statistics.loading
    }),
    data () {
      if (!this.statisticsData[this.statistic]) {
        return this.default
      }
      return this.statisticsData[this.statistic]
    },
    loading () {
      if (!this.loadingState[this.statistic]) {
        return false
      }
      return this.loadingState[this.statistic]
    },
  },
  methods: {
    ...mapActions('statistics', ['getData']),
    classFromMeta (item) {
      let classes = []
      if (item._meta) {
        if (item._meta.summary) {
          classes.push('font-weight-black')
        }
      }
      return classes.join(' ')
    }
  },
  created() {
    this.getData({type: this.statistic})
  }
}
</script>
