import {contractService} from "../../services";
import { filter, get, unset, isEmpty, some } from 'lodash';
import Vue from "vue";

const state = {
  contracts: {},
  messages: []
};

const actions = {
  loadContracts({ dispatch, commit }, org_id) {
    contractService.index(org_id)
      .then(
        data => commit('loadSuccess', data),
        () => {
          dispatch('alert/error', 'Fehler beim Laden der Verträge.', { root: true });
        }
      );
  },
  uploadContract({ dispatch, commit }, {org_id, type, files, is_contract, skip_messages}) {
    contractService.upload(org_id, type, files, is_contract, skip_messages)
      .then(
        newFiles => {
          commit('uploadSuccess', { newFiles, type })
        },
        () => {
          dispatch('alert/error', `Fehler beim Hochladen.`, { root: true });
        }
      )
      .then(
        () => {
          dispatch('contracts/loadMessages', org_id, { root: true })
        }
      )
  },
  deleteContract({dispatch, commit}, {org_id, id, type}) {
    return contractService.delete(id)
      .then(
        () => {
          commit('deleteSuccess', { id, type })
        },
        () => {
          dispatch('alert/error', `Fehler beim Löschen.`, { root: true });
        }
      )
      .then(
        () => {
          dispatch('contracts/loadMessages', org_id, { root: true })
        }
      )
  },
  loadMessages({ dispatch, commit }, org_id) {
    contractService.messages(org_id)
      .then(
        messages => commit('getMessagesSuccess', messages),
        () => {
          dispatch('alert/error', 'Fehler beim Laden der Vetragsnachrichten.', { root: true });
        }
      )
  },
  sendMessage({ dispatch/*, commit*/ }, { organisation_id, message }) {
    contractService.sendMessage(organisation_id, message)
      .then(
        //message => commit('sendMessageSuccess', message),
        () => {},
        () => {
          dispatch('alert/error', 'Fehler beim Senden der Vetragsnachricht.', { root: true });
        }
      )
      .then(
        () => {
          dispatch('contracts/loadMessages', organisation_id, { root: true })
        }
      )
  },
  newContract({ commit }, contract) {
    commit('newContract', contract)
  },
  cleanUpContracts({ commit }) {
    commit('cleanUp')
  }
};

const mutations = {
  loadSuccess(state, data) {
    if (!isEmpty(data)) {
      state.contracts = data;
    } else {
      state.contracts = {};
    }
  },
  uploadSuccess(state, { newFiles, type }) {
    /*
    let files = state.contracts
      .find(contract => contract.type === type)
      .files;
    */
    let files = state.contracts[type].files,
      containsContract = some(newFiles, ['is_contract', true])

    files.forEach(file => {
      if (containsContract) {
        file.is_contract = false
      }
    })
    files.unshift(...newFiles)
  },
  deleteSuccess(state, { id, type }) {
    // at this point auto cleanup may have already removed contract of the given type
    if (!state.contracts[type]) {
      return
    }

    const contract = state.contracts[type],
          files = contract.files.filter(file => file.id !== id)

    Vue.set(state.contracts[type], 'files', files)
  },
  getMessagesSuccess(state, messages) {
    state.messages = messages.map(msg => {
      return {...msg, show_all: msg.auto_generated}
    });
  },
  sendMessageSuccess(state, message) {
    state.messages.unshift(message)
  },
  newContract(state, contract) {
    Vue.set(state.contracts, contract.type, contract)
  },
  cleanUp(state) {
    // remove all contract with empty files list
    const cs = Object.assign({}, state.contracts);
    for (let type in cs) {
      if (0 === cs[type].files.length) {
        unset(cs, type)
      }
    }
    Vue.set(state, 'contracts', cs)
  }
};

const getters = {
  contractTypeFiles: state => type => {
    return get(
      filter(state.contracts, contract => contract.type === type),
      '0.files',
      []
    )
  }
}

export const contracts = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
