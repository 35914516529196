import {genericService} from "../../services";
import {map} from 'lodash';

const state = {
  slides: []
};

const actions = {
  load({ dispatch, commit }) {
    genericService.get('slides.json', null, {auth: false})
      .then(
        slides => commit('loadSuccess', slides),
        () => {
          dispatch('alert/error', 'Fehler beim Laden der Bilder.', { root: true });
        }
      );
  }
};

const mutations = {
  loadSuccess(state, slides) {
    state.slides = map(slides.slides, slide => `${slides.baseDir}${slide}` );
  }
};

export const slides = {
  namespaced: true,
  state,
  actions,
  mutations
};
