import { authHeader, config, handleResponse } from '../helpers';

export const tasksService = {
  view,
  update,
  list,
};

function view(userId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),

  };

  return fetch(`${config.apiUrl}/tasks/${userId}.json`, requestOptions).then(handleResponse);
}

function update(userId, tasks) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(tasks)
  };

  return fetch(`${config.apiUrl}/tasks/${userId}.json`, requestOptions).then(handleResponse);
}

function list() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(`${config.apiUrl}/tasks/list-all.json`, requestOptions).then(handleResponse);
}
