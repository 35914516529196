<template>
  <div class="mr-4 d-flex">
    <d-checkbox
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      label="Auf alle anwenden"
      hide-details
      dense
      @click.native.stop
    ></d-checkbox>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          class="ml-1"
          small
          v-bind="attrs"
          v-on="on"
        >help</v-icon>
      </template>
      <span>Soll die Einstellung beim Speichern für alle Nutzer der Gliederung übernommen werden?</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: ['value']
}
</script>
