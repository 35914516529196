<style scoped>
.tab-container {
  overflow-y: auto;
  padding-right: 16px;
  padding-bottom: 16px;
  max-height: 70vh;
}
/* target only expansion panel content from main expander component */
.employee-container >>> .employee-panel > .v-expansion-panel > .v-expansion-panel-content {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

.employee-display-name {
  font-size: 20px;
}
.employee-display-details {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}
</style>

<template>
  <div class="employee-container">
    <d-expander class="employee-panel">
      <template slot="header">
        <v-toolbar-title>Mitarbeiterdaten</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch
          v-if="checked !== null"
          :disabled="checked === null"
          @click.stop="changeUserIsActive"
          hide-details
          v-model="isActive.value"
        ></v-switch>
      </template>
      <template slot="contextMenu">
        <v-list>
          <v-list-item @click="showEditProfilePictureDialog">
            <v-list-item-title>Profilbild ändern</v-list-item-title>
          </v-list-item>
          <v-list-item @click="deleteProfilePicture">
            <v-list-item-title>Profilbild löschen</v-list-item-title>
          </v-list-item>
          <v-list-item :disabled="checked === null" @click="deleteUser">
            <v-list-item-title>Benutzer löschen</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
      <template>
        <div v-if="checked !== null && Object.keys(coreData).length > 0" class="tab-container">
          <div class="d-flex ma-2">
            <v-avatar class="mr-4">
              <img v-bind:src="avatar" />
            </v-avatar>
            <div class="d-flex flex-column flex-grow-1">
              <div class="employee-display-name">{{ checked.person.firstname.value }} {{ checked.person.lastname.value }}</div>
              <div class="employee-display-details">ID {{ checked.person.id.value }} - {{ coreData.name.value }}</div>
            </div>
          </div>
          <v-tabs
            v-model="tab"
            background-color="blue-grey lighten-5"
            class="fill-height"
          >
            <v-tab>Person</v-tab>
            <v-tab>Funktion</v-tab>
            <v-tab v-if="canSeeModules">Zugriff</v-tab>
            <v-tab v-if="canSeeDdm">DDM Box</v-tab>

            <v-tabs-items
              v-model="tab"
              class="pt-1 blue-grey lighten-5 flex-grow-1"
            >
              <v-tab-item>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-card
                      flat
                      outlined
                      class="flex-grow-1"
                    >
                      <v-toolbar dense flat class="px-3">
                        <v-toolbar-title class="body-1">Stammdaten</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on"><v-icon>info</v-icon></v-btn>
                          </template>
                          <span>{{ mainDataLog }}</span>
                        </v-tooltip>
                        <v-btn v-if="canEditBaseData" icon>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon @click.stop="showEditBaseDataDialog" v-on="on">edit</v-icon>
                            </template>
                            <span>Stammdaten ändern</span>
                          </v-tooltip>
                        </v-btn>
                      </v-toolbar>
                      <v-row
                        v-for="(item, idx) in baseData"
                        :key="idx"
                        no-gutters
                        style="border-top: thin solid rgba(0,0,0,.1) !important;"
                        class="pa-2 body-2 text--secondary base-data-row"
                        align="stretch"
                        :class="{'form-row-even': idx % 2 === 0}"
                      >
                        <v-col cols="4">{{ item.title }}</v-col>
                        <v-col v-if="item.link" cols="8">
                          <a :href="item.link">
                            {{ item.value }}
                          </a>
                        </v-col>
                        <v-col v-else cols="8">{{ item.value }}</v-col>
                      </v-row>
                    </v-card>
                    <v-card
                      flat
                      outlined
                      class="flex-grow-1 mt-6"
                    >
                      <v-toolbar dense flat class="px-3">
                        <v-toolbar-title class="body-1">Einverständnis</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on"><v-icon>info</v-icon></v-btn>
                          </template>
                          <span>{{ checked.person.log.value }}</span>
                        </v-tooltip>
                      </v-toolbar>
                      <v-row
                        no-gutters
                        style="border-top: thin solid rgba(0,0,0,.1) !important;"
                        class="pa-2 body-2 text--secondary base-data-row form-row-even"
                        align="stretch"
                      >
                        <d-switch
                          v-for="(item, idx) in consent"
                          :key="idx"
                          v-model="item.value"
                          hide-details
                          class="ma-2"
                          :label="item.title"
                          @click.stop="changeUserConsent(idx, item)"
                        ></d-switch>
                      </v-row>
                    </v-card>
                    <v-card
                      flat
                      outlined
                      class="flex-grow-1 mt-6"
                    >
                      <v-toolbar dense flat class="px-3">
                        <v-toolbar-title class="body-1">RotkreuzAPP</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click.stop="rkAppExpanded = !rkAppExpanded">
                          <v-icon v-if="!rkAppExpanded">expand_more</v-icon>
                          <v-icon v-else-if="rkAppExpanded">expand_less</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-card-text
                        v-if="rkAppExpanded"
                        style="border-top: thin solid rgba(0,0,0,.1) !important;"
                        class="pa-3 body-2 text--secondary form-row-even"
                      >
                        <p class="text--primary">Hinweis für Ansprechpartner</p>
                        <p>Um dem Kunden oder Interessenten die Kontaktaufnahme mit seinem Ansprechpartner vom Roten Kreuz zu erleichtern, werden in der Rotkreuz-App der Name, Aufgaben und Kontaktdaten des Ansprechpartners mitgeteilt. Dazu müssen im Feld „Ansprechpartner für“ eine oder mehrere Aufgaben eingetragen sein. Wird für den gleichen Zweck ein Benutzerbild in der BVw bereitgestellt, so ist dieses auch für den Kunden und Interessenten sichtbar.</p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-card
                      flat
                      outlined
                      class="flex-grow-1"
                    >
                      <v-toolbar dense flat class="px-3">
                        <v-toolbar-title class="body-1">Kontaktdaten Dienstlich</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on"><v-icon>info</v-icon></v-btn>
                          </template>
                          <span>{{ checked.person.log.value }}</span>
                        </v-tooltip>
                        <v-btn v-if="canEditContact" icon>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon @click.stop="showEditContactDataDialog" v-on="on">edit</v-icon>
                            </template>
                            <span>Kontaktdaten ändern</span>
                          </v-tooltip>
                        </v-btn>
                      </v-toolbar>
                      <v-row
                        v-for="(item, idx) in contact"
                        :key="idx"
                        no-gutters
                        style="border-top: thin solid rgba(0,0,0,.1) !important;"
                        class="pa-2 body-2 text--secondary base-data-row"
                        align="stretch"
                        :class="{'form-row-even': idx % 2 === 0}"
                      >
                        <v-col cols="4">{{ item.title }}</v-col>
                        <v-col v-if="item.link" cols="8">
                          <a :href="item.link">
                            {{ item.value }}
                          </a>
                        </v-col>
                        <v-col v-else cols="8">{{ item.value }}</v-col>
                      </v-row>
                    </v-card>
                    <v-card
                      flat
                      outlined
                      class="flex-grow-1 mt-6"
                    >
                      <v-toolbar dense flat class="px-3">
                        <v-toolbar-title class="body-1">ZLO Telefonbuch</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click.stop="zloExpanded = !zloExpanded">
                          <v-icon v-if="!zloExpanded">expand_more</v-icon>
                          <v-icon v-else-if="zloExpanded">expand_less</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-card-text
                        v-if="zloExpanded"
                        style="border-top: thin solid rgba(0,0,0,.1) !important;"
                        class="pa-3 body-2 text--secondary form-row-even"
                      >
                        <p class="text--primary">Hinweis</p>
                        <p>Damit die Beratungszentren in der Lage sind, Tickets an die Mitarbeiter einer DRK Gliederung oder GmbH zu senden, können diese Name, Funktion, Aufgabe und Kontaktdaten per Telefonbuch in der ZLO recherchieren. Dieses Telefonbuch steht auch Ihren Kolleginnen und Kollegen zur Verfügung – immer strikt begrenzt auf die eigene DRK Gliederung oder GmbH.</p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <roles :active="1===tab || false"></roles>
              </v-tab-item>
              <v-tab-item v-if="canSeeModules">
                <modules :active="2===tab || false"></modules>
              </v-tab-item>
              <v-tab-item v-if="canSeeDdm">
                <ddm :active="3===tab || false"></ddm>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </div>
      </template>
    </d-expander>
    <create-user
      v-model="editBaseDataDialog"
      :item="baseDataDialogItem"
      :persontype_id="persontype_id"
    ></create-user>
    <confirm-delete-user
      v-model="confirmDeleteDialog"
      v-if="checked !== null"
      :id="checked.user.id.value"
      :name="checked.person.fullname.value"
    ></confirm-delete-user>
    <edit-contact-data v-model="editContactDataDialog" :item="contactDataDialogItem"></edit-contact-data>
    <edit-profile-picture
      v-model="editProfilePictureDialog"
      v-if="checked !== null"
      :id="checked.user.id.value"
    ></edit-profile-picture>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { config } from '../../helpers'
import { find } from 'lodash'
import CreateUser from '../user/CreateUser'
import ConfirmDeleteUser from '../user/ConfirmDeleteUser'
import EditContactData from './EditContactData'
import EditProfilePicture from './EditProfilePicture'
import Roles from './Roles'
import Modules from './Modules'
import Ddm from './DDM'

export default {
  components: {
    CreateUser,
    ConfirmDeleteUser,
    EditContactData,
    EditProfilePicture,
    Roles,
    Modules,
    Ddm
  },
  data() {
    return {
      tab: null,
      editBaseDataDialog: false,
      confirmDeleteDialog: false,
      editContactDataDialog: false,
      editProfilePictureDialog: false,
      baseDataDialogItem: null,
      contactDataDialogItem: null,
      persontype_id: 1,
      rkAppExpanded: true,
      zloExpanded: true
    };
  },
  mounted() {
    // Clear old data from store
    if (this.$route.params.user_id === undefined && this.checked !== null) {
      this.getChecked(null);
    }
  },
  computed: {
    ...mapState({
      checked: state => state.users.checked,
      acl: state => state.account.acl,
      prefixValues: state => state.option.prefix,
      titleValues: state => state.option.title,
      roleValues: state => state.option.roles
    }),
    ...mapGetters({
      coreData: 'organisation/coreData'
    }),
    isActive() {
      return {
        value: this.checked && this.checked.user.isActive.value
      }
    },
    avatar() {
      return `${config.avatarBaseUrl}` + (!this.checked ? `${config.emptyAvatar}` : this.checked.person.picture.value);
    },
    baseData() {
      if (!this.checked || !this.prefixValues || !this.titleValues || !this.roleValues) {
        return null;
      } else {
        const role = find(this.roleValues, {
          'role_id': this.checked.user.role_id.value,
          'persontype_id': this.checked.person.persontype_id.value
        });
        return [
          {
            title: 'Anrede',
            value: this.prefixValues[this.checked.person.prefix.value]
          },
          {
            title: 'Titel',
            value: this.titleValues[this.checked.person.title.value]
          },
          {
            title: 'Vorname',
            value: this.checked.person.firstname.value
          },
          {
            title: 'Nachname',
            value: this.checked.person.lastname.value
          },
          {
            title: 'E-Mail',
            value: this.checked.person.office_email.value,
            link: this.checked.person.office_email.value ? 'mailto:' + this.checked.person.office_email.value : undefined
          },
          {
            title: 'Nutzername',
            value: this.checked.person.username.value
          },
          {
            title: 'Passwort',
            value: '(versteckt)'
          },
          {
            title: 'Berechtigungen',
            value: role ? role.description : 'fehlerhaft'
          },
          {
            title: 'Benutzerprofil',
            value: this.checked.user.isActive.value ? 'Aktiv' : 'Inaktiv'
          }
        ];
      }
    },
    contact() {
      if (!this.checked) {
        return null;
      } else {
        return [
          {
            title: 'Einricht./Gliederg.',
            value: this.checked.person.office_organisation.value
          },
          {
            title: 'Strasse',
            value: this.checked.person.office_street.value
          },
          {
            title: 'Postleitzahl',
            value: this.checked.person.office_zipcode.value
          },
          {
            title: 'Ort',
            value: this.checked.person.office_city.value
          },
          {
            title: 'Bereich / Team',
            value: this.checked.person.office_division.value ? (
              this.checked.person.office_team.value ?
                this.checked.person.office_division.value + ' / ' + this.checked.person.office_team.value :
                this.checked.person.office_division.value + ' / -'
            ) : (
              this.checked.person.office_team.value ?
                '- / ' + this.checked.person.office_team.value :
                undefined
            )
          },
          {
            title: 'Haus / Etage / Raum',
            value: this.checked.person.office_room.value
          },
          {
            title: 'Telefon',
            value: this.checked.person.office_phone.value
          },
          {
            title: 'Mobil',
            value: this.checked.person.office_mobile.value
          },
          {
            title: 'Fax',
            value: this.checked.person.office_fax.value
          },
          {
            title: 'Website',
            value: this.checked.person.webpage.value,
            link: this.checked.person.webpage.value
          },
          {
            title: 'Erreichbarkeit',
            value: this.checked.person.office_time.value
          }/*,
          {
            title: 'Berechtigungen'
          }*/
        ];
      }
    },
    consent() {
      if (!this.checked) {
        return null;
      } else {
        return [
          {
            title: 'Infobrief Wissensdatenbank - E-Mailzusendung',
            key: 'receive_newsletter',
            value: this.checked.person.receive_newsletter.value
          },
          {
            title: 'FFI (Fach- und Führungskräfte Informationen) - E-Mailzusendung',
            key: 'receive_newsletter_ddm',
            value: this.checked.person.receive_newsletter_ddm.value
          }
        ];
      }
    },
    mainDataLog() {
      let ulog = this.checked.user.log_ts.value
      let plog = this.checked.person.log_ts.value
      return this.checked[ulog > plog ? 'user' : 'person'].log.value
    },
    canEditBaseData() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.user_maindata_edit;
    },
    canEditContact() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.user_contactdata_edit;
    },
    canSeeModules() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_general_read;
    },
    canSeeDdm() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.ddm_box_edit;
    }
  },
  methods: {
    ...mapActions('users', ['getChecked', 'update', 'deleteAvatar']),
    ...mapActions('option', ['loadOption']),
    showEditBaseDataDialog() {
      if (!this.checked) {
        this.baseDataDialogItem = null;
      } else {
        this.baseDataDialogItem = {
          'person.id': null,
          id: this.checked.user.id.value,
          prefix: this.checked.person.prefix.value,
          title: this.checked.person.title.value,
          firstname: this.checked.person.firstname.value,
          lastname: this.checked.person.lastname.value,
          office_email: this.checked.person.office_email.value,
          username: this.checked.person.username.value,
          password: this.checked.person.password_set.value ? '*****' : null,
          role_id: this.checked.user.role_id.value,
          isActive: this.checked.user.isActive.value
        };
        this.persontype_id = this.checked.person.persontype_id.value;
      }
      this.editBaseDataDialog = true;
    },
    showEditContactDataDialog() {
      if (!this.checked) {
        this.contactDataDialogItem = null
      } else {
        this.contactDataDialogItem = {
          id: this.checked.user.id.value,
          office_organisation: this.checked.person.office_organisation.value,
          office_street: this.checked.person.office_street.value,
          office_zipcode: this.checked.person.office_zipcode.value,
          office_city: this.checked.person.office_city.value,
          office_division: this.checked.person.office_division.value,
          office_team: this.checked.person.office_team.value,
          office_room: this.checked.person.office_room.value,
          office_phone: this.checked.person.office_phone.value,
          office_mobile: this.checked.person.office_mobile.value,
          office_fax: this.checked.person.office_fax.value,
          office_time: this.checked.person.office_time.value,
          webpage: this.checked.person.webpage.value
        }
      }
      this.editContactDataDialog = true;
    },
    showEditProfilePictureDialog() {
      this.editProfilePictureDialog = true;
    },
    deleteUser() {
      this.confirmDeleteDialog = true;
    },
    changeUserIsActive() {
      this.update({
        id: this.checked.user.id.value,
        isActive: 1 - this.checked.user.isActive.value
      });
    },
    changeUserConsent(idx, item) {
      this.update({
        id: this.checked.user.id.value,
        // cast to int
        [item.key]: item.value ? 1 : 0
      });
    },
    deleteProfilePicture() {
      this.deleteAvatar(this.checked.user.id.value);
    }
  },
  created () {
    this.loadOption({option: 'prefix'});
    this.loadOption({option: 'title'});
    this.loadOption({option: 'roles'});
  }
}
</script>
