<style scoped>
.drawer-container {
  position: relative;
  transition-duration: 0.2s;
  transition-property: transform, visibility, width, min-width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.container-open {
  min-width: 400px;
  width: 400px;
}
.container-closed {
  min-width: 0px;
  width: 0px;
}
.nav-drawer {
  min-width: 400px;
  width: 400px;
  position: fixed;
  top: 56px !important;
  bottom: 0px !important;
  height: auto !important;
}
.nav-drawer >>> .v-treeview-node {
  border-top: 1px solid rgba(0, 0, 0, .2);
  cursor: pointer;
}
.nav-drawer >>> .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}
.v-toolbar > {
  flex-grow: 0;
}
.v-treeview {
  overflow-y: auto;
  background-color: white;
}
.drop-allowed {
  color: green;
  text-shadow: 0 0 5px green, 0 0 1px black
}
.drop-forbidden {
  text-decoration: line-through red 3px;
  text-shadow: 0 0 5px red;
}
.drop {
}
.drop-in {
  border: 1px solid black;
}
</style>

/** @todo make it scoped somehow */
<style>
.v-list-group__header__prepend-icon {
  margin: 8px 32px 8px 0;
}
.v-list-group__header {
  min-height: 40px;
}
</style>

<template>
  <div class="drawer-container" :class="{'container-open': value, 'container-closed': !value}">
    <v-navigation-drawer
      v-bind:value="value"
      color="blue-grey lighten-5"
      class="nav-drawer"
    >
      <v-toolbar
        flat
        tile
        color="blue-grey lighten-5"
        class="pa-3"
        style="flex-grow: 0;"
      >
        <v-toolbar-title class="font-weight-bold">DRK Struktur</v-toolbar-title>
        <!--v-spacer></v-spacer-->
        <template v-slot:extension class="px-0">
          <v-text-field
            append-icon="search"
            clearable
            @keyup.enter="filterOrgs"
            @click:append="filterOrgs"
            @click:clear="resetFilter"
            single-line
            solo
            flat
            dense
            label="Suchen..."
            v-model="search"
          ></v-text-field>
        </template>
      </v-toolbar>

      <v-treeview
        :active.sync="active"
        :load-children="fetchOrgs"
        :open.sync="open"
        :items="items"
        selection-type="independent"
        activatable
        hoverable
        dense
        color="primary"
        transition
        style="flex-grow: 1;"
      >
        <template v-slot:label="{ item }">
            <drop
              v-if="canEditTree"
              @drop="onDrop(item)"
              @dragover="onDragover(item)"
              mode="cut"
              class="drop"
              :accepts-data="draggedNode => acceptDropOnto(draggedNode, item)"
            >
              <drag
                :key="item.id"
                :data="item"
                @cut="onCut"
                @dragstart="onDragstart"
                @dragend="onDragend"
                :disabled="item.id < 2"
                go-back
              >
               <span class="pl-1">{{item.name}}</span>
              </drag>
            </drop>
            <span
              v-else
            >{{item.name}}</span>
        </template>
        <template v-slot:append="{ item }">
          <v-menu
            class="nav-drawer-menu"
            v-if="hasContextMenuEntries"
            offset-y
            v-model="contextMenu[item.id]"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click.stop
                icon
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-group
                v-if="canDeleteOrganisation || canAddOrganisation"
                prepend-icon="edit"
              >
                <template v-slot:activator>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>Bearbeiten</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list-item
                  dense
                  v-if="canAddOrganisation"
                  @click="showAddOrgDialog(item.id)"
                >
                  <v-list-item-content>
                    <v-list-item-title>Hinzufügen</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  v-if="canDeleteOrganisation"
                  :disabled="!!item.children || 1 === item.id"
                  @click="showDeleteOrgDialog(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>Löschen</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-list-group
                v-if="canImportOrganisation"
                prepend-icon="file_upload"
              >
                <template v-slot:activator>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>Import</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list-item
                  dense
                  @click="showImportDialog(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>Mitarbeiter</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-list-group
                v-if="canExportOrganisation"
                prepend-icon="file_download"
              >
                <template v-slot:activator>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>Export</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list-item
                  dense
                  @click="showExportDialog(item, 'orgById')"
                >
                  <v-list-item-content>
                    <v-list-item-title>Gliederung</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  @click="showExportDialog(item, 'usersByOrgId')"
                >
                  <v-list-item-content>
                    <v-list-item-title>Mitarbeiter</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-menu>
        </template>
      </v-treeview>
      <v-footer>
        <v-spacer></v-spacer>
        <v-btn text @click="exportRec = null; exportDialog = true">Exporte</v-btn>
      </v-footer>
    </v-navigation-drawer>
    <add-org-dialog
      v-if="addParentId !== null"
      v-model="addDialog"
      :parent-id="addParentId"
      @org-created="reload"
    ></add-org-dialog>
    <delete-org-dialog
      v-if="deleteNode !== null"
      v-model="deleteDialog"
      :id="deleteNode.id"
      :name="deleteNode.name"
      @org-deleted="reload"
    ></delete-org-dialog>
    <move-org-dialog
      v-if="dropNode !== null && draggedNode !== null"
      v-model="moveDialog"
      :src="draggedNode"
      :dest="dropNode"
      @org-moved="reload"
    ></move-org-dialog>
    <export-dialog
      v-model="exportDialog"
      :exportItem="exportRec"
    ></export-dialog>
    <import-dialog
      v-model="importDialog"
      :importItem="importRec"
    ></import-dialog>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import { genericService } from "../../services";
import AddOrgDialog from '../organisation/AddOrgDialog'
import DeleteOrgDialog from '../organisation/DeleteOrgDialog'
import ExportDialog from "../ExportDialog";
import ImportDialog from "../ImportDialog";
import {Drop, Drag} from "vue-easy-dnd";
import MoveOrgDialog from "../organisation/MoveOrgDialog";

export default {
  props: ['value', 'selectedUser'],
  components: {
    ImportDialog,
    ExportDialog,
    AddOrgDialog,
    DeleteOrgDialog,
    MoveOrgDialog,
    Drag,
    Drop,
  },
  data () {
    return {
      active: [],
      open: [],
      search: '',
      items: [],
      addDialog: false,
      deleteDialog: false,
      importDialog: false,
      exportDialog: false,
      addParentId: null,
      deleteNode: null,
      exportRec: null,
      importRec: {name: '', id: 0},
      contextMenu: {},
      draggedNode: null,
      dragoverNode: null,
      dropNode: null,
      moveDialog: false,
    }
  },
  created() {
    this.debouncedOnDragoverOpen = this.$_.debounce(this.onDragoverOpen, 500)
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl
    }),
    canAddOrganisation() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_create;
    },
    canDeleteOrganisation() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_delete;
    },
    canImportOrganisation() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_import;
    },
    canExportOrganisation() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_export;
    },
    canEditTree() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_tree_edit;
    },
    hasContextMenuEntries() {
      return !!this.canAddOrganisation || !!this.canExportOrganisation || !!this.canDeleteOrganisation;
    }
  },
  watch: {
    value () {
      this.resetFilter()
    },
    selectedUser () {
      this.resetFilter();
    },
    active(val) {
      const id = 1 === val.length
        ? val[0]
        : 0;

      // at least in dev mode after first selection of a node id = 0 is send twice
      if (0 < id) {
        this.$emit('updateActive', id);
      }
    }
  },
  methods: {
    acceptDropOnto(src, dest) {
      // never drop on root node
      const onRootNode = 0 == dest.id
      // dragged node mustn't be dropped on itself
      const onItself = src.id == dest.id
      // dragged node mustn't be dropped on own children
      const onOwnChild = src.lft < dest.lft && src.rght > dest.rght
      return !onRootNode && !onItself && !onOwnChild
    },
    // eslint-disable-next-line no-unused-vars
    onCut(e) {
      // remove src node
    },
    onDragend() {
      //this.draggedNode = null
      this.dragoverNode = null
    },
    onDragstart(e) {
      // remember dragged node
      this.draggedNode = e.data
    },
    onDrop(dropDest) {
      this.dropNode = dropDest
      this.moveDialog = true
    },
    onDragover(dragOverItem) {
      if (
        // trigger opening the currently dragged over node,
        // if its different to the last dragged over node
        !this.dragoverNode
        || (this.dragoverNode.id != dragOverItem.id)
      ) {
        this.debouncedOnDragoverOpen()
      }
      // remember current dragged over node
      this.dragoverNode = dragOverItem
    },
    onDragoverOpen() {
      if (this.dragoverNode) {
        this.open.push(this.dragoverNode.id)
      }
    },
    reload() {
      this.filterOrgs()
    },
    async fetchOrgs (item = null) {
      // if item is given, we look for children of item
      // else we want to load root
      const id = null === item ? 0 : item.id
      return genericService.get(`organisations/nodes/${id}.json`)
        .then(json => {
          item ? item.children.push(...json) : this.items = json
        });
    },
    filterOrgs() {
      if (!this.$_.isEmpty(this.search)) {
        genericService.get(`organisations/search/${this.search}.json`)
          .then(json => {
            this.items = json;
          });
      } else {
        this.resetFilter()
      }
    },
    resetFilter () {
      this.items = [];
      this.fetchOrgs().then(() => {
        if (0 < this.items.length) {
          this.open.push(this.items[0].id);
        }
      })
    },
    showAddOrgDialog(id) {
      this.addParentId = id;
      this.addDialog = true;
    },
    showDeleteOrgDialog(node) {
      this.deleteNode = node;
      this.deleteDialog = true;
    },
    showExportDialog(node, type) {
      this.exportRec = {
        name: node.name,
        id: node.id,
        type: type,
      }
      this.exportDialog = true
    },
    showImportDialog(node) {
      this.importRec = {
        name: node.name,
        id: node.id
      }
      this.importDialog = true
    }
  }
}
</script>
