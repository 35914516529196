<template>
<v-row justify="center">
  <v-dialog
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    max-width="750"
  >
    <v-card>
      <v-card-title>
        Mitarbeiterimport für {{importItem.name}}
      </v-card-title>
      <d-hr />
      <v-card-text class="card-text">
        <p>
          Dieser Import ermöglicht Benutzer aus einer Xlsx-Datei in die Benutzerverwaltung zu importieren.
        </p>
        <p>
          Halten Sie sich dabei bitte an das Format dieser Beispieldatei: <a @click.stop="downloadExample">example.xlsx</a>
        </p>
        <v-row>
          <v-file-input
            label="Datei"
            v-model="file"
            :rules="rules"
            :loading="uploading"
            accept=".xlsx"
            show-size
            clearable
            outlined
          ></v-file-input>
          <v-btn
            :disabled="!file"
            @click.stop="uploadFile"
            class="ma-3"
          >Hochladen</v-btn>
        </v-row>
        <v-row
          v-if="null !== errorMsg"
          class="pl-7 mt-0"
        >
          <ul>
            <li>{{errorMsg}}</li>
            <li v-if="errorFile">Hier können Sie die <a @click.stop="downloadErrorFile">Fehlerdatei</a> herunterladen</li>
          </ul>
        </v-row>
        <v-row class="pl-7">
          <v-radio-group
            v-model="importRec.existing"
            row
          >
            <v-radio
              label="Neue Benutzer hinzufügen UND vorhandene Benutzer aktualisieren"
              value="update"
            ></v-radio>
            <v-radio
              label="Neue Benutzer hinzufügen, vorhandene Benutzer NICHT aktualisieren"
              value="skip"
            ></v-radio>
          </v-radio-group>
        </v-row>
      </v-card-text>
      <d-hr />
      <v-card-actions>
        <span class="text-caption">{{formattedStatus}}</span>
        <v-spacer />
        <v-btn class="form-btn" color="primary" :disabled="!valid" @click="startImport">
          Import starten
        </v-btn>
        <v-btn text class="form-btn" @click="$emit('input', false)">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-row>
</template>

<script>
import {genericService} from "../services";

export default {
  name: "ImportDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    importItem: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      rules: [
        value => !value || value.size < 5<<20 || 'Datei sollte kleiner als 5MB sein!',
      ],
      file: null,
      uploading: false,
      valid: false,
      errorMsg: null,
      errorFile: null,
      importRec: this.defaultImportRec(),
      updater: null,
      status: null,
    }
  },
  computed: {
    formattedStatus() {
      if (null === this.status) {
        return ''
      }
      let status = this.status.status,
          action = 1 === this.status.running ? 'gestartet' : 'beendet'

      if (!this.$_.isEmpty(this.status.message)) {
        status = `${status}/${this.status.message}`
      }
      return `${action} ${this.status.date} von ${this.status.user} (Status ${status})`
    }
  },
  methods: {
    defaultImportRec: () => {
      return {
        import: 0,
        existing: 'update'
      }
    },
    downloadErrorFile() {
      genericService.get(`import/error-file.json`, {file: this.errorFile}, {blob: true})
        .then(
          blob => {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'errors.xlsx'
            link.click()
            URL.revokeObjectURL(link.href)
          },
          () => {
            this.$store.dispatch('alert/error', 'Fehler beim Download', { root: true})
          }
        )
    },
    downloadExample() {
      genericService.get(`import/example.json`, null, {blob: true})
        .then(
          blob => {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'example.xlsx'
            link.click()
            URL.revokeObjectURL(link.href)
          },
          () => {
            this.$store.dispatch('alert/error', 'Fehler beim Download', { root: true})
          }
        )
    },
    uploadFile() {
      this.reset(true)
      this.uploading = true

      let data = new FormData()
      data.append('org_id', this.importItem.id)
      data.append('file', this.file)
      genericService.post('import/upload.json', data, {encode: false})
      .then(
        response => {
          // general error
          if (!this.$_.isEmpty(response.error) && 0 === response.import) {
            this.errorMsg = response.error
          }
          // faulty file
          if (!this.$_.isEmpty(response.file)) {
            this.errorFile = response.file
          }
          // we got an import id
          if (0 < response.import) {
            this.valid = true
            this.importRec.import = response.import
          }
        },
        response => {
          this.$store.dispatch('alert/error', `Fehler beim Hochladen (${response.msg}).`, {root: true});
        }
      )
      .finally(
        () => {
          this.uploading = false
        }
      )
    },
    startImport() {
      if (this.valid) {
        genericService.post('/import/start.json', this.importRec)
          .then(
            () => {
              this.getStatus()
            }
          )
      }
    },
    getStatus() {
      genericService.get('import/status.json', {org_id: this.importItem.id})
        .then(
          response => this.status = response
        )
    },
    reset(keepFile = false) {
      if (!keepFile) {
        this.file = null
      }
      this.valid = false
      this.errorMsg = null
      this.errorFile = null
      this.importRec = this.defaultImportRec()
    }
  },
  watch: {
    value: function (newVal) {
      // on open
      if (true === newVal) {
        this.getStatus()
        this.updater = setInterval(() => {
          this.getStatus()
        }, 15*1000)
      } else {
        this.reset()
        clearInterval(this.updater)
      }
    }
  }
}
</script>

<style scoped>
.card-text {
  padding: 10px 24px !important;
}
.form-btn {
  padding: 0 16px !important;
  margin: 0 16px 0 0 !important;
  min-width: 120px !important;
}
</style>
