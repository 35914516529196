<style scoped>
.v-file-input >>> .v-input__slot {
  background-color: white;
}
.form-btn {
  padding: 0px 16px !important;
  margin: 0px 16px 0px 0px !important;
  min-width: 120px !important;
}
</style>

<template>
<div>
<d-dialog
  v-bind:value="value"
  v-on:input="$emit('input', $event)"
  :max-width="750"
  :dark-bg="true"
>
  <template slot="title">
    Vertragsdaten - Kundendatenbank
  </template>
  <template>
    <v-row>
      <v-col cols="9">
        <v-file-input
          label="Datei(en) durch Klick hinzufügen"
          v-model="uploaded"
          accept=".doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          clearable
          outlined
          show-size
          multiple
          counter
          small-chips
          hide-details
        ></v-file-input>
      </v-col>
      <v-col class="d-flex align-center" cols="3">
        <v-btn
          :disabled="!hasUploadedFile"
          class="flex-grow-1"
          color="primary"
          @click.stop="uploadFile"
        >Hochladen</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="isContract"
          label="aktuell gültige(r) Vertrag/Verträge"
        ></v-checkbox>
        <v-checkbox
          v-if="canManageAllContracts"
          v-model="skipMessages"
          label="Senden von Benachrichtigung bei Upload unterbinden"
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(item, key) in items" :key="key" cols="12">
        <v-card class="d-flex align-center">
          <div class="flex-grow-1">
            <v-card-title>{{ item.name }}</v-card-title>
            <v-card-subtitle>{{ item.edited }}</v-card-subtitle>
            <v-card-text v-if="item.is_contract" class="red--text">aktuell gültiger Vertrag</v-card-text>
          </div>
          <v-btn v-if="canDeleteContracts" icon x-large>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click.stop="confirmDelete(item)"
                >delete</v-icon>
              </template>
              <span>Datei löschen</span>
            </v-tooltip>
          </v-btn>
          <v-btn icon x-large>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click.stop="downloadFile(item)"
                >get_app</v-icon>
              </template>
              <span>Datei herunterladen</span>
            </v-tooltip>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </template>
  <template slot="actions">
    <v-spacer></v-spacer>
    <v-btn text class="form-btn" @click="closeDialog">Schliessen</v-btn>
  </template>
</d-dialog>
<confirm-delete-contract
  v-model="confirmDeleteDialog"
  :id="item.id"
  :name="item.name"
  v-on:deletetion-confirmed="deleteFile()"
>
</confirm-delete-contract>
</div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import { contractService} from "../../services";
import ConfirmDeleteContract from "./ConfirmDeleteContract";

export default {
  components: {ConfirmDeleteContract},
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      uploaded: undefined,
      isContract: false,
      skipMessages: false,
      confirmDeleteDialog: false,
      item: {id: 0, name: ''},
    }
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl,
      contracts: state => state.contracts
    }),
    ...mapGetters({
      fullRec: 'organisation/fullRec',
      contractTypeFiles: 'contracts/contractTypeFiles'
    }),
    items () {
      return this.contractTypeFiles(this.type)
    },
    hasUploadedFile() {
      return this.uploaded !== undefined;
    },
    canManageAllContracts() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_all_contract_management;
    },
    canDeleteContracts() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_all_contract_management;
    },
  },
  methods: {
    ...mapActions('contracts', ['uploadContract', 'loadMessages', 'deleteContract']),
    closeDialog() {
      this.$emit('input', false);
    },
    resetForm() {
      this.uploaded = undefined;
      this.isContract = false;
      this.skipMessages = false;
    },
    uploadFile() {
      this.uploadContract({
        org_id: this.fullRec.id.value,
        type: this.type,
        files: this.uploaded,
        is_contract: this.isContract ? 1 : 0,
        skip_messages: this.skipMessages ? 1 : 0,
      });
      this.resetForm()
    },
    confirmDelete(item) {
      this.item = item
      this.confirmDeleteDialog = true
    },
    deleteFile() {
      this.deleteContract({
        org_id: this.fullRec.id.value,
        type: this.type,
        id: this.item.id
      })
      this.item = {id: 0, name: ''}
    },
    downloadFile(item) {
      contractService.download(item.id)
        .then(
          blob => {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = item.name
            link.click()
            URL.revokeObjectURL(link.href)
          },
          msg => {
            this.$store.dispatch('alert/error', msg, { root: true})
          }
        )
        .then(
          () =>  {
            const org_id = this.fullRec.id.value
            this.loadMessages(org_id);
          }
        )
    }
  }
}
</script>
