import { store } from "../store";

export function handleResponse(response) {
  return response.text().then(
    text => {
      let data = null;
      try {
        data = text && JSON.parse(text);
      } catch(e) {
        // eslint-disable-next-line no-empty
      }
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          store.dispatch('account/logoutBySessionDeath')
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    }
  );
}

export function handleBlobResponse(response) {
  return response.blob().then(
    async blob => {
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          store.dispatch('account/logoutBySessionDeath')
        }

        let text = await blob.text();
        text = text && JSON.parse(text)

        const error = text.message || response.statusText;
        return Promise.reject(error);
      }

      return blob;
    }
  );
}
