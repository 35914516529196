<style scoped>
.category-expander:not(:last-child) {
  margin-bottom: 16px;
}
</style>

<template>
<d-action-dialog
  v-bind:value="value"
  v-on:input="$emit('input', $event)"
  :onSave="onSave"
  :validate="onValidate"
  :max-width="1200"
  :dark-bg="true"
>
  <template slot="title">
    Zugriff auf Datenbanken
  </template>
  <template>
    <v-form ref="form" v-model="valid">
    <v-row v-if="item !== undefined">
      <v-col cols="12" md="6">
        <d-expander class="category-expander">
          <template slot="header">
            <span>Kundendatenbank</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramKdb.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input v-model="item.ProgramKdb.contractdate" :clearable="true"></d-date-input>
            <v-switch label="BZ-Ansicht" v-model="item.ProgramKdb.actasbz"></v-switch>
            <div class="ml-1 mb-4">Zusätzlich wird auf Kunden folgender Mandanten Zugriff erlaubt:</div>
            <d-autocomplete
              multiple
              chips
              deletable-chips
              label="Verband"
              :items="orgItems"
              v-model="item.ProgramKdb.organisations"
            ></d-autocomplete>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>KDB-Modul Fördermitgliederverwaltung (FMV)</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramKdbfmv.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramKdbfmv.contractdate" :clearable="true"></d-date-input>
            <v-switch label="Standalone" v-model="item.ProgramKdbfmv.standalone"></v-switch>
            <v-switch label="Nimmt am SEPA Vorlauf teil" v-model="item.ProgramKdbfmv.sepaNotice"></v-switch>
            <v-switch label="Kundentransfer ermöglichen" v-model="item.ProgramKdbfmv.clientTransfer"></v-switch>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>KDB-Modul Kursteilnehmerverwaltung (KTV)</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramKdbktv.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramKdbktv.contractdate" :clearable="true"></d-date-input>
            <v-switch label="Standalone" v-model="item.ProgramKdbktv.standalone"></v-switch>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>KDB-Modul Aktivenverwaltung (AV)</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramKdbav.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramKdbav.contractdate" :clearable="true"></d-date-input>
            <v-switch label="Standalone" v-model="item.ProgramKdbav.standalone"></v-switch>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>KDB-Modul KTV für Kinder und Familien</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramKdbkifam.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramKdbkifam.contractdate" :clearable="true"></d-date-input>
            <v-switch label="Standalone" v-model="item.ProgramKdbkifam.standalone"></v-switch>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>KDB-Modul KTV für Schulen</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramKdbls.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramKdbls.contractdate" :clearable="true"></d-date-input>
            <v-switch label="Standalone" v-model="item.ProgramKdbls.standalone"></v-switch>
          </template>
        </d-expander>
        <!--d-expander class="category-expander">
          <template slot="header">
            <span>Zusatzmodul RK-Veranstaltungen mit AGs oder WS</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramKdbrkk.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramKdbrkk.contractdate" :clearable="true"></d-date-input>
            <v-switch label="Standalone" v-model="item.ProgramKdbrkk.standalone"></v-switch>
          </template>
        </d-expander-->
        <d-expander class="category-expander">
          <template slot="header">
            <span>Zusatzmodul Digitale Unterschrift</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramKdbds.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramKdbds.contractdate" :clearable="true"></d-date-input>
            <v-switch label="Standalone" v-model="item.ProgramKdbds.standalone"></v-switch>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>Zusatzmodul Anonymisierung</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramKdbanon.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramKdbanon.contractdate" :clearable="true"></d-date-input>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>Zusatzmodul Termintool</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramKdbtt.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramKdbtt.contractdate" :clearable="true"></d-date-input>
            <v-switch label="Standalone" v-model="item.ProgramKdbtt.standalone"></v-switch>
          </template>
        </d-expander>
      </v-col>
      <v-col cols="12" md="6">
        <d-expander class="category-expander">
          <template slot="header">
            <span>Dienstleistungsdatenbank</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramDldb.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramDldb.contractdate" :clearable="true"></d-date-input>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>Orgavision</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramOrgavision.active"></v-switch>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>Medienportal</span>
            <v-spacer></v-spacer>
            <apply-to-all v-model="item.ProgramMeportal.applyToAll.active"></apply-to-all>
            <v-switch @click.native.stop hide-details v-model="item.ProgramMeportal.active"></v-switch>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>Wissensdatenbank</span>
            <v-spacer></v-spacer>
            <apply-to-all v-model="item.ProgramWd.applyToAll.active"></apply-to-all>
            <v-switch @click.native.stop hide-details v-model="item.ProgramWd.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramWd.contractdate" :clearable="true"></d-date-input>
          </template>
          <template>
            <v-switch label="Fachpublikationen" v-model="item.ProgramWd.publication"></v-switch>
            <v-row>
              <v-col cols="6">
                <v-switch label="Bilddatenbank Download" v-model="item.ProgramWd.picturedb"></v-switch><div class="mt-4"></div>
              </v-col>
              <v-col cols="6">
                <d-date-input label="Vertrag vom" v-model="item.ProgramWd.pdb_contractdate" :clearable="true"></d-date-input>
              </v-col>
            </v-row>
            <apply-to-all v-model="item.ProgramWd.applyToAll.picturedb"></apply-to-all>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>ZLO für BZ</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramZlo.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input v-model="item.ProgramZlo.contractdate" :clearable="true"></d-date-input>
            <d-checkbox label="Einschränkung auf Kundendaten von:" v-model="item.ProgramZlo.restrict"></d-checkbox>
            <d-autocomplete
              multiple
              chips
              deletable-chips
              label="Verband"
              :items="orgItems"
              v-model="item.ProgramZlo.organisations"
              :disabled="!item.ProgramZlo.restrict"
            ></d-autocomplete>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>Leben Plus</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramLp.active"></v-switch>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>Planungstools</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramKdbschedtool.active"></v-switch>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>DRKS-Mitgliederbrief</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramMemberletter.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramMemberletter.contractdate" :clearable="true"></d-date-input>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>DRK KV Portal</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramKvportal.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramKvportal.contractdate" :clearable="true"></d-date-input>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>Styleguide</span>
            <v-spacer></v-spacer>
            <apply-to-all v-model="item.ProgramStyleguide.applyToAll.active"></apply-to-all>
            <v-switch @click.native.stop hide-details v-model="item.ProgramStyleguide.active"></v-switch>
          </template>
          <template>
            <v-switch label="Anmeldung von unbestätigten Nutzern erlauben." v-model="item.ProgramStyleguide.allowNYALogin"></v-switch>
            <d-date-input label="Vertrag vom" v-model="item.ProgramStyleguide.contractdate" :clearable="true"></d-date-input>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>Einkaufsportal</span>
            <v-spacer></v-spacer>
            <apply-to-all v-model="item.ProgramEkportal.applyToAll.active"></apply-to-all>
            <v-switch @click.native.stop hide-details v-model="item.ProgramEkportal.active"></v-switch>
          </template>
          <template>
            <v-switch label="Anmeldung von unbestätigten Nutzern erlauben." v-model="item.ProgramEkportal.allowNYALogin"></v-switch>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>DRK-Plattform</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramDrkplatform.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramDrkplatform.contractdate" :clearable="true"></d-date-input>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>Firmenportal</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramFirmenportal.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramFirmenportal.contractdate" :clearable="true"></d-date-input>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>PrintShop</span>
            <v-spacer></v-spacer>
            <v-switch
              @click.native.stop
              hide-details
              v-model="item.ProgramPrintshop.active"
              :disabled="!item.ProgramPrintshop.customerid"
            ></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramPrintshop.contractdate" :clearable="true"></d-date-input>
            <d-text-field
              v-model="item.ProgramPrintshop.customerid"
              :loading="programIdChecking && programIdChecking.Printshop"
              :rules="rules.pscustomerid"
            ></d-text-field>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>Rotkreuz Shop</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramDrkshop.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramDrkshop.contractdate" :clearable="true"></d-date-input>
          </template>
        </d-expander>
        <d-expander class="category-expander">
          <template slot="header">
            <span>FormApp</span>
            <v-spacer></v-spacer>
            <v-switch @click.native.stop hide-details v-model="item.ProgramFormapp.active"></v-switch>
          </template>
          <template>
            <div class="mt-4"></div>
            <d-date-input label="Vertrag vom" v-model="item.ProgramFormapp.contractdate" :clearable="true"></d-date-input>
          </template>
        </d-expander>
      </v-col>
    </v-row>
    </v-form>
  </template>
</d-action-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ApplyToAll from '../form/ApplyToAll'
import {organisationService} from "../../services";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: {
    ApplyToAll
  },
  data() {
    return {
      working: false,
      item: undefined,
      rules: {
        pscustomerid: [
          //v => 'string' === typeof v && 3 < v.length || 'Min. 4 Zeichen.',
          //v => 'string' === typeof v && 11 > v.length || 'Max. 10 Zeichen.',
          v => (!v || !!this.programIdUnique['Printshop']) || 'Diese Kundennummer ist schon vergeben.',
        ]
      },
      programIdChecking: {},
      programIdUnique: {},
      valid: true,
      orgItems: [
        {
          value: 0,
          text: 'Musterstadt'
        },
        {
          value: 1,
          text: 'Musterdorf'
        },
        {
          value: 2,
          text: 'Musterhausen'
        },
        {
          value: 3,
          text: 'Mustertal'
        },
        {
          value: 4,
          text: 'Musterberg'
        },
        {
          value: 5,
          text: 'Musterort'
        },
        {
          value: 6,
          text: 'Musterwald'
        },
        {
          value: 7,
          text: 'Teststadt'
        },
        {
          value: 8,
          text: 'Testdorf'
        },
        {
          value: 9,
          text: 'Testhausen'
        },
        {
          value: 10,
          text: 'Testtal'
        },
        {
          value: 11,
          text: 'Testberg'
        },
        {
          value: 12,
          text: 'Testort'
        },
        {
          value: 13,
          text: 'Testwald'
        },
        {
          value: 14,
          text: 'Org ABC'
        },
        {
          value: 15,
          text: 'Org DEF'
        },
        {
          value: 16,
          text: 'Org GHI'
        },
        {
          value: 17,
          text: 'Org JKL'
        },
        {
          value: 18,
          text: 'Org MNO'
        },
        {
          value: 19,
          text: 'Org PQR'
        },
        {
          value: 20,
          text: 'Org STU'
        },
        {
          value: 21,
          text: 'Org VWX'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      fullRec: 'organisation/fullRec'
    })
  },
  methods: {
    ...mapActions('organisation', ['update']),
    reset() {
      this.valid = true
      this.programIdChecking = {}
      this.programIdUnique = {}
      this.$refs.form.reset()
    },
    updateVisibleItem() {
      let result = {};
      for (let [key, value] of Object.entries(this.fullRec.moduleOptions.value)) {
        // Clone object to avoid mutating vuex store
        let itemClone = Object.assign({}, value);

        // Convert to boolean to handle "0" correctly
        itemClone.active = !(itemClone.active === '0' || !itemClone.active);
        if (itemClone.actasbz) {
          itemClone.actasbz = !(itemClone.actasbz === '0' || !itemClone.actasbz);
        }
        if (itemClone.standalone) {
          itemClone.standalone = !(itemClone.standalone === '0' || !itemClone.standalone);
        }
        if (itemClone.picturedb) {
          itemClone.picturedb = !(itemClone.picturedb === '0' || !itemClone.picturedb);
        }
        if (itemClone.publication) {
          itemClone.publication = !(itemClone.publication === '0' || !itemClone.publication);
        }
        if (itemClone.restrict) {
          itemClone.restrict = !(itemClone.restrict === '0' || !itemClone.restrict);
        }

        // Append option to apply to all users for certain items
        itemClone.applyToAll = {};
        result[key] = itemClone;
      }
      this.item = result;
    },
    onValidate() {
      this.valid = this.$refs.form.validate();
      return this.valid;
    },
    onSave() {
      if (this.valid) {
        this.update({
          id: this.fullRec.id.value,
          moduleOptions: this.item
        })
      }
    },
    closeDialog() {
      this.$emit('input', false);
    },
    checkIdIsUnique(program_id, program) {
      this.programIdUnique[program] = true
      if (program_id && 0 < program_id.length) {
        this.programIdChecking[program] = true
        organisationService.programIdUnique({program, program_id, id: this.fullRec.id.value})
          .then(
            res => {
              this.programIdUnique[program] = res
            }
          )
          .finally(() => {
            this.programIdChecking[program] = false
            this.onValidate()
          })
      }
    },
  },
  created () {
    this.debouncedCheckIdIsUnique = this.$_.debounce(this.checkIdIsUnique, 500)
  },
  watch: {
    value: function(newVal) {
      if (newVal) {
        this.updateVisibleItem();
      } else {
        this.reset()
      }
    },
    'item.ProgramPrintshop.customerid' (newVal) {
      if (!newVal) {
        this.item.ProgramPrintshop.active = 0
      }
      this.debouncedCheckIdIsUnique(newVal, 'Printshop')
    }
  }
}
</script>
