<template>
  <div class="flex-grow-1 fill-height">
    <navbar />
    <div class="background-dark flex-grow-1 fill-height">
      <v-row class="pa-4 flex-grow-1 fill-width statistic-row">
        <v-col cols="12" md="3">
          <statistic-treeview
            title="Mitarbeiter (Benutzer)"
            subtitle="Mitarbeiter (Benutzer) pro Gliederung"
          ></statistic-treeview>
        </v-col>
        <v-col
          v-for="i in 3"
          :key="i"
          cols="12"
          sm="6"
          md="3"
        >
          <statistic-table
            v-for="(type, idx) in column(i - 1)"
            :statistic="type"
            :key="idx"
            class="mb-6"
            :title="type"
            subtitle="1"
            @export="onExport(type)"
          ></statistic-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import StatisticTable from "./statistics/StatisticTable";
import StatisticTreeview from "./statistics/StatisticTreeview";
import Navbar from "./layout/Navbar";
import {mapActions, mapState} from "vuex";
import {genericService} from "../services";

export default {
  name: "DdmStatisticsPage",
  components: {
    StatisticTable,
    StatisticTreeview,
    Navbar
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      statisticsList: state => state.statistics.ddm,
    })
  },
  methods: {
    ...mapActions('statistics', ['getList']),
    column: function(col) {
      return this.$_.filter(this.statisticsList, (val, idx) => {
        return col === idx % 3
      })
    },
    onExport: function(type) {
      genericService.get(`statistics/download.json`, {type: type}, {blob: true})
        .then(
          blob => {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'statistics.xlsx'
            link.click()
            URL.revokeObjectURL(link.href)
          },
          () => {
            this.$store.dispatch('alert/error', 'Fehler beim Download', { root: true})
          }
        )
    }
  },
  created() {
    this.getList('ddm')
  }
}
</script>
