<template>
  <div class="flex-grow-1 fill-height">
    <d-action-dialog
      v-bind:value="value"
      :onSave="onSend"
      :onClose="onClose"
      :validate="validate"
      confirmText="Passwort festlegen"
      denyText="Abbrechen"
    >
      <template slot="title">Neues Passwort festlegen</template>
      <template>
        <p class="font-weight-bold">min. 9 Zeichen, Umlaute erlaubt, Sonderzeichen nicht</p>
        <v-form
          ref="form"
          v-model="valid"
        >
          <d-text-field
            label="Passwort"
            counter
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
          ></d-text-field>
          <d-text-field
            label="Passwort zur Bestätigung"
            counter
            v-model="checkPassword"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min, rules.equal]"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
          ></d-text-field>
        </v-form>
      </template>
    </d-action-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { validation } from "../helpers";

export default {
  data() {
    return {
      value: true,
      validation: validation,
      valid: false,
      show: false,
      rules: {
        required: value => !!value || 'Notwendig.',
        min: v => v.length >= 9 || 'Min 9 Zeichen',
        equal: () => this.checkPassword === this.password || 'Die Eingaben müssen identisch sein.'
      },
      password: '',
      checkPassword: '',
      token: ''
    }
  },
  computed: {
    ...mapState({
      tokenValid: state => state.passwords.tokenValid,
      changeSuccess: state => state.passwords.changeSuccess
    })
  },
  methods: {
    ...mapActions('passwords', ['checkToken', 'change']),
    validate() {
      this.valid = this.tokenValid && this.$refs.form.validate();
      return this.valid;
    },
    onSend() {
      if (this.valid) {
        this.change({
          token: this.token,
          password: this.password
        })
      }
    },
    validateToken() {
      const token = this.$route.params.token
      if (null !== token) {
        this.token = token
        this.checkToken(token)
      }
    }
  },
  created() {
    this.validateToken()
  },
  watch: {
    $route: 'validateToken',
    changeSuccess(val) {
      if (val) {
        this.$router.push({name: 'login'})
      }
    }
  }
}
</script>
