<template>
<d-action-dialog
  v-bind:value="value"
  v-on:input="$emit('input', $event)"
  :onSave="onSave"
  :onClose="onClose"
  :validate="validate"
>
  <template slot="title">
    {{ isEdit ? 'Stammdaten bearbeiten' : 'Benutzer anlegen' }}
  </template>
  <template>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row v-if="item">
        <v-col cols="6">
          <d-select label="Anrede" v-model="item.prefix" :items="prefixItems" :rules="validation.required"></d-select>
        </v-col>
        <v-col cols="6">
          <d-select label="Titel" v-model="item.title" :items="titleItems"></d-select>
        </v-col>
        <v-col cols="6">
          <d-text-field label="Vorname" v-model="item.firstname" :rules="validation.stringRequired"></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-combobox
            label="Nachname"
            v-model="item.lastname"
            :rules="validation.stringRequired"
            :loading="lastnameACLoading"
            :items="lastnameACResult"
            item-text="acValue"
            :search-input.sync="lastnameACSearch"
            hide-no-data
            hide-details
            @input="usernameACSelect"
          ></d-combobox>
        </v-col>
        <v-col cols="6">
          <d-text-field
            label="E-Mail"
            v-model="item.office_email"
            :rules="rules.emailValid"
            :loading="emailChecking"
          ></d-text-field>
        </v-col>
        <v-col cols="6"></v-col>
        <v-col cols="6">
          <d-text-field
            v-if="useUserAutoGenerateField"
            label="Nutzername"
            v-model="generatedUsername"
            :append-icon="canEditLoginName ? 'edit' : undefined"
            @click:append="setManualName"
            :readonly=true
            :loading="checkingUsername"
            :rules="usernameRules"
          ></d-text-field>
          <d-text-field
            v-else
            label="Nutzername"
            v-model="item.username"
            append-icon="clear"
            @click:append="setAutoName"
            :loading="checkingUsername"
            :disabled="!canEditLoginName"
            :rules="usernameRules"
          ></d-text-field>
        </v-col>
        <v-col cols="6">
          <d-password
            label="Passwort"
            v-model="item.password"
            :rules="passwordRules"
            :disabled="!canEditPassword"
          ></d-password>
        </v-col>
        <v-col cols="6">
          <d-select
            label="Berechtigungen"
            v-model="item.role_id"
            :items="roleItems"
            :disabled="!canEditRole"
          ></d-select>
        </v-col>
        <v-col cols="6">
          <d-select
            label="Benutzerprofil"
            v-model="item.isActive"
            :items="activeItems"
            :disabled="!canEditActiveState"
          ></d-select>
        </v-col>
      </v-row>
    </v-form>
  </template>
</d-action-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { validation } from '../../helpers'
import { reduce } from 'lodash'
import { userService } from '../../services'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    persontype_id: {
      type: Number,
      required: true,
      default: 1
    },
    item: {
      type: Object,
      required: false,
      default: () => {
        return {
          'person.id': null,
          id: null,
          prefix: 1,
          title: 0,
          firstname: null,
          lastname: null,
          office_email: null,
          username: null,
          password: null,
          role_id: 99,
          isActive: 0
        }
      }
    }
  },
  data() {
    return {
      validation: validation,
      valid: true,

      defaultItem: {
        'person.id': null,
        id: null,
        prefix: 1,
        title: 0,
        firstname: null,
        lastname: null,
        office_email: null,
        username: null,
        password: null,
        role_id: 99,
        isActive: 0
      },
      rules: {
        emailValid: validation.emailRequired.concat([
          () => this.emailUnique || 'Die E-Mailadresse ist schon vergeben.'
        ]),
      },

      emailChecking: false,
      emailErrors: [],
      emailTimeout: null,

      generateName: true,
      checkingUsername: false,
      usernameUnique: true,

      lastnameACLoading: false,
      lastnameACResult: [],
      lastnameACSearch: null,

      activeItems: [
        {
          value: 0,
          text: 'Inaktiv'
        },
        {
          value: 1,
          text: 'Aktiv'
        }
      ],
      roleItems: null
    }
  },
  methods: {
    ...mapActions('users', ['create', 'update']),
    ...mapActions('option', ['loadOption']),
    validate() {
      this.valid = this.$refs.form.validate();
      return this.valid;
    },
    onSave() {
      // when a user edits the lastname and, without blurring the field, directly
      // clicks the save button, search and validation will not happen before getting here
      // this will lead to not updating the associated combo model with the user data
      // lucky for us this.lastnameACSearch will contain the value, as it would have been
      // used for looking up existing user with this lastname
      this.item.lastname = this.lastnameACSearch
      if (this.valid) {
        if (this.isEdit) {
          this.update(this.item);
        } else {
          if (null === this.item.username) {
            this.setManualName();
          }
          this.create({
            organisation_id: this.fullRec.id.value,
            persontype_id: this.persontype_id,
            ...this.item
          });
        }
      }
      // afterwards onClose gets called automatically
    },
    onClose () {
      // is called after onSave automatically
      this.setAutoName();
      this.lastnameACResult = [];
      this.lastnameACSearch = null;
      //this.item['person.id'] = null;
      //this.item['user.id'] = null;
      //this.$refs.form.reset()
      this.item = {...this.defaultItem};
    },
    setManualName() {
      if (this.canEditLoginName) {
        if (this.generatedUsername) {
          this.item.username = this.generatedUsername;
        }
        this.generateName = false;
      }
    },
    setAutoName() {
      if (this.generatedUsername) {
        this.item.username = this.generatedUsername;
      }
      this.generateName = true;
    },
    checkUsername(val) {
      if (val && 4 < val.length) {
        this.usernameUnique = true;
        this.checkingUsername = true;
        let id = this.item.id
          ? {'user.id': this.item.id}
          : (
            this.item['person.id']
              ? {'person.id': this.item['person.id']}
              : null
          )
        userService.isUsernameUnique({...id, username: val})
          .then(
            res => {
              this.usernameUnique = res
            }
          )
          .finally(() => {
            this.checkingUsername = false
            this.validate()
          })
      }
    },
    usernameACSelect (val) {
      // on selecting an ac result
      if (val && val.acValue) {
        this.setManualName();
        delete val.acValue;
        Object(val).key
        this.item = {...this.defaultItem, ...val};
      }
    },
    checkEmailIsUnique() {
      const email = this.item.office_email;
      if (email && 5 < email.length) {
        let param = {email}

        if (this.item.id && 0 < this.item.id) {
          param.userId = this.item.id
        }
        if (this.item['person.id'] && 0 < this.item['person.id']) {
          param.personId = this.item['person.id']
        }

        this.emailChecking = true
        userService.emailUnique(param)
          .then(
            res => {
              this.emailUnique = res
            }
          )
          .finally(() => {
            this.emailChecking = false
            this.validate()
          })
      }
    },
    lastnameAutocomplete (val) {
      if (val && 2 < val.length) {
        this.lastnameACLoading = true;
        userService.autocomplete(val, this.persontype_id, this.fullRec.id.value)
          .then((res) => {
            this.lastnameACResult = res;
          })
          .finally(() => {
            this.lastnameACLoading = false;
          })
      }
    }
  },
  asyncComputed: {
    generatedUsername () {
      if (!(
        // if everything is empty (on new user)
        this.fullRec && this.fullRec.id.value > 0
        && this.item && this.item.lastname && this.item.firstname
      ) || (
        // or on existing user
        this.item && this.item.id
      ) || (
        // or if we do not want autogeneration
        !this.useUserAutoGenerateField
      )) {
        // autogenerated username is existing username or empty string
        return new Promise((resolve) => {
            resolve(this.item ? this.item.username : '')
          }
        )
      }
      // else (new user, lastname and firstname given) generate by using backend
      const param = {
        lastname: this.item.lastname,
        firstname: this.item.firstname,
        organisation_id: this.fullRec.id.value
      }
      return userService.getUniqueUsername(param)
        .then(res => {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.item.username = res;
          return res
        })
    }
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl,
      mandants: state => state.account.mandants,
      prefixValues: state => state.option.prefix,
      titleValues: state => state.option.title,
      roleValues: state => state.option.roles
    }),
    ...mapGetters({
      fullRec: 'organisation/fullRec'
    }),
    passwordRules() {
      return this.item['person.id'] || this.item.id ? this.validation.string : this.validation.stringRequired;
    },
    usernameRules() {
      return [
        v => !!v || 'Nutzername muss angegeben werden',
        v => !!v && v.length > 4 || 'Nutzername muss mindestens 5 Zeichen lang sein',
        () => this.usernameUnique || 'Nutzername ist schon vergeben.'
      ]
    },
    currentMandant() {
      return this.mandants.find(mandant => mandant.selected)
    },
    canEditActiveState() {
      if (!this.acl) {
        return false;
      }
      return this.acl.user_general_activate;
    },
    useUserAutoGenerateField() {
      // username may be autogenerated iff:
      //   logged in user is admin (canEditLoginName)
      //   logged in user is non admin and username is not set already (!canEditLoginName && !isEdit)
      return (this.generateName && this.canEditLoginName) || (this.generateName && !this.canEditLoginName && !this.isEdit)
      //return this.generateName || !this.canEditLoginName
    },
    canEditLoginName() {
      if (!this.acl) {
        return false;
      }
      return this.acl.user_login_edit;
    },
    canEditPassword() {
      if (!this.currentMandant) {
        return false;
      }
      // every user must be able to edit own password
      if (this.currentMandant.user_id === this.item.id) {
        return true;
      }
      // editing user
      if (this.isEdit) {
        // non admins must not edit password of existing users
        return this.canEditLoginName
      }
      // creating new user
      return true
    },
    canEditRole() {
      if (!this.currentMandant) {
        return false;
      }
      return this.currentMandant.role_id <= this.item.role_id;
    },
    canRoleUpgrade() {
      if (!this.acl) {
        return false;
      }
      return this.acl.user_role_upgrade
    },
    canRoleDowngrade() {
      if (!this.acl) {
        return false;
      }
      return this.acl.user_role_downgrade
    },
    isEdit() {
      return this.item && this.item.id !== undefined && this.item.id !== null;
    },
    prefixItems() {
      if (!this.prefixValues) {
        return [];
      }
      let items = [];
      for (const [key, item] of Object.entries(this.prefixValues)) {
        items.push({
          value: 1*key,
          text: item
        });
      }
      return items;
    },
    titleItems() {
      if (!this.titleValues) {
        return [];
      }
      return this.titleValues.map((item, key) => {
        return {
          value: key,
          text: item
        };
      });
    }
  },
  created () {
    this.loadOption({option: 'prefix'});
    this.loadOption({option: 'title'});
    this.loadOption({option: 'roles'});
    // wait for 500ms after last keystroke before sending requests
    this.debouncedCheckUsernameIsUnique = this.$_.debounce(this.checkUsername, 500)
    this.debouncedCheckEmailIsUnique = this.$_.debounce(this.checkEmailIsUnique, 500)
    this.debouncedLastnameAutocomplete = this.$_.debounce(this.lastnameAutocomplete, 500)
  },
  watch: {
    /**
     * whenever this component changes its visibility state (although on becoming visible would be enough)
     * we want to check the list of available roles (koop vs. regular)
     */
    value (val) {
      if (val) {
        // if current user role <= 3, disable all roles, that are lower than currently logged in users role
        // if current user role >= 4, disable all roles, that are lower than currently loaded users role
        let isRoleDisabled = role => {
          return role < (
            this.canRoleUpgrade
              ? this.currentMandant.role_id
              : this.item.role_id
          )
        }

        this.roleItems = reduce(
            this.roleValues,
            (roles, role) => {
              if (this.persontype_id === role.persontype_id) {
                roles.push({
                  value: role.role_id,
                  text: role.description,
                  disabled: isRoleDisabled(role.role_id)
                });
              }
              return roles;
            },
            []
          )
          .sort(
            (r1, r2) => r1.value - r2.value
          );
      }
    },
    generatedUsername (val) {
      this.debouncedCheckUsernameIsUnique(val)
    },
    'item.username' (val) {
      this.debouncedCheckUsernameIsUnique(val)
    },
    'item.office_email' () {
      this.debouncedCheckEmailIsUnique();
    },
    item: {
      deep: true,
      handler (val) {
        if ((val.id && 0 < val.id) || (val['person.id'] && 0 < val['person.id'])) {
          //this.setManualName();
        }
      }
    },
    lastnameACSearch (val) {
      this.debouncedLastnameAutocomplete(val)
    },
  }
}
</script>
