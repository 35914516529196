import { authHeader, config, handleResponse } from '../helpers';

export const tagsService = {
  view,
  update,
};

function view(userId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/tags/${userId}.json`, requestOptions).then(handleResponse);
}

function update(userId, tasks) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(tasks)
  };

  return fetch(`${config.apiUrl}/tags/${userId}.json`, requestOptions).then(handleResponse);
}

