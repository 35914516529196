<style scoped>
.user-inactive {
  color: #c0c0c0;
}
.user-text {
  max-width: 200px;
}
.text-two-lines {
  display: inline-block;
  overflow: hidden;
  max-height: 3em;
  line-height: 1.5em;
  margin-top: 3px;
  margin-bottom: -3px;
}
.tab-container {
  overflow-y: auto;
  padding-right: 16px;
  padding-bottom: 16px;
  max-height: 70vh;
}
.v-expansion-panels >>> .v-expansion-panel-content {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}
.v-expansion-panels >>> tbody {
  cursor: pointer;
}
</style>

<template>
  <div>
    <d-expander>
      <template slot="header">
        <v-toolbar-title>Personalverwaltung</v-toolbar-title>
        <v-spacer></v-spacer>
      </template>
      <template>
        <v-tabs
          v-model="tab"
          background-color="blue-grey lighten-5"
          class="fill-height tab-container"
        >
          <v-tab>DRK</v-tab>
          <v-tab>KOOP</v-tab>

          <v-tabs-items
            v-model="tab"
            class="pt-4 blue-grey lighten-5 flex-grow-1"
          >
            <v-tab-item
              v-for="(tab, idx) in tabs"
              :key="idx"
            >
              <v-text-field
                append-icon="search"
                clearable
                single-line
                solo
                flat
                dense
                label="Suchen..."
                v-model="search"
              ></v-text-field>
              <v-card
                flat
                outlined
                class="flex-grow-1"
              >
                <v-toolbar dense flat class="px-3">
                  <v-toolbar-title class="body-1">Mitarbeiter</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn v-if="canCreate" icon>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon @click.stop="addUser(tab.persontype_id)" v-on="on">person_add</v-icon>
                      </template>
                      <span>Neuen Nutzer anlegen</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn v-if="canDelete" icon :disabled="!canBeDeleted">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon @click.stop="deleteUser" v-on="on">delete</v-icon>
                      </template>
                      <span>Gewählte Nutzer löschen</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn v-if="canActivate" icon :disabled="!canBeActivated">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon @click.stop="enableUser" v-on="on">mdi-eye</v-icon>
                      </template>
                      <span>Gewählte Nutzer aktivieren</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn v-if="canActivate" icon :disabled="!canBeDisabled">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon @click.stop="disableUser" v-on="on">mdi-eye-off</v-icon>
                      </template>
                      <span>Gewählte Nutzer deaktivieren</span>
                    </v-tooltip>
                  </v-btn>
                </v-toolbar>

                <v-data-table
                  single-select
                  v-model="selected"
                  item-key="id"
                  :loading="loading"
                  :items-per-page="15"
                  :search="search"
                  :headers="headers"
                  :items="tab.items"
                  @click:row="checkEmployee"
                >
                  <template v-slot:item.name="{ item }">
                    <span class="text-two-lines" :class="{'user-inactive': !item.active}">{{item.name}}</span>
                  </template>
                  <template v-slot:item.function="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span
                          v-on="on"
                          class="d-inline-block user-text text-two-lines"
                          :class="{'user-inactive': !item.active}"
                        >{{item.function}}</span>
                      </template>
                      <span v-html="item.function.split(', ').join('<br/>')"></span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </template>
    </d-expander>
    <create-user
      v-model="createDialog"
      :persontype_id="persontype_id"
    ></create-user>
    <confirm-delete-user
      v-model="confirmDeleteDialog"
      :id="selected.length > 0 ? selected[0].id : undefined"
      :name="selected.length > 0 ? selected[0].name : undefined"
    ></confirm-delete-user>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CreateUser from './CreateUser'
import ConfirmDeleteUser from './ConfirmDeleteUser'

export default {
  name: "UserList",
  components: {
    CreateUser,
    ConfirmDeleteUser
  },
  props: {
    currentOrgId: Number
  },
  data () {
    return {
      tab: null,
      persontype_id: 1,
      createDialog: false,
      confirmDeleteDialog: false,
      search: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Funktion(en)', value: 'function', sortable: false }
      ]
    }
  },
  computed: {
    ...mapState({
      drkusers: state => state.users.drk,
      koopusers: state => state.users.koop,
      loading: state => state.users.loading,
      acl: state => state.account.acl
    }),
    tabs () {
      return [{
        title: 'DRK',
        persontype_id: 1,
        items: this.drkusers
      }, {
        title: 'KOOP',
        persontype_id: 2,
        items: this.koopusers
      }];
    },
    canActivate() {
      if (!this.acl) {
        return false;
      }
      return this.acl.user_general_activate;
    },
    canCreate() {
      if (!this.acl) {
        return false;
      }
      return this.acl.user_create;
    },
    canDelete() {
      if (!this.acl) {
        return false;
      }
      return this.acl.user_delete;
    },
    canBeDeleted() {
      return this.selected.length > 0;
    },
    canBeActivated() {
      return this.selected.length > 0 && !this.selected[0].active;
    },
    canBeDisabled() {
      return this.selected.length > 0 && this.selected[0].active;
    }
  },
  watch: {
    currentOrgId: {
      immediate: true,
      handler(id) {
        if (id > 0) {
          this.getAll({id, type: 'drk'})
            .then( () => {
              this.getAll({id, type: 'koop'});
              if (this.$route.params.user_id !== undefined && this.$route.params.org_id !== undefined) {
                //this.getChecked(Number.isInteger(this.$route.params.user_id) ? this.$route.params.user_id : parseInt(this.$route.params.user_id));
              }
            })
        }
      }
    },
    drkusers(items) {
      if (this.$route.params.user_id !== undefined && this.$route.params.org_id !== undefined) {
        let matchingItem = items.find(i => i.id == this.$route.params.user_id);
        if (matchingItem) {
          this.selected = [matchingItem];
        }
      }
    },
    koopusers(items) {
      if (this.$route.params.user_id !== undefined && this.$route.params.org_id !== undefined) {
        let matchingItem = items.find(i => i.id == this.$route.params.user_id);
        if (matchingItem) {
          this.selected = [matchingItem];
        }
      }
    }
  },
  methods: {
    ...mapActions('users', ['getAll', 'getChecked', 'update']),
    addUser(persontype_id) {
      this.persontype_id = persontype_id;
      this.createDialog = true;
    },
    deleteUser() {
      this.confirmDeleteDialog = true;
    },
    enableUser() {
      if(this.selected.length > 0) {
        this.update({id: this.selected[0].id, isActive: 1});
      }
    },
    disableUser() {
      if(this.selected.length > 0) {
        this.update({id: this.selected[0].id, isActive: 0});
      }
    },
    checkEmployee(item, data) {
      const org_id = this.currentOrgId.toString()
      if (!data.isSelected) {
        this.selected = [item];
        this.$router.push({ name: 'verzeichnis', params: {org_id: org_id, user_id: item.id} });
      } else {
        this.selected = [];
        this.$router.push({ name: 'verzeichnis', params: {org_id: org_id} });
      }
    }
  }
}
</script>
