<template>
  <div>
    <v-card>
      <v-card-title class="align-start">
      <v-row>
        <v-col cols="1">Änderungen</v-col>
        <v-col cols="4"></v-col>
        <v-col cols="7">
          <div class="d-flex flex-column">
            <v-autocomplete
              label="Organisationen für Wer"
              v-model="selectedOrgs"
              :items="availableOrgs"
              dense
              chips
              small-chips
              deletable-chips
              multiple
            ></v-autocomplete>
            <v-select
              label="Änderung"
              v-model="selectedTypes"
              :items="availableTypes"
              dense
              chips
              small-chips
              deletable-chips
              multiple
            ></v-select>
            <v-autocomplete
              label="Quelle"
              v-model="selectedSources"
              :items="availableSources"
              dense
              chips
              small-chips
              deletable-chips
              multiple
            ></v-autocomplete>
            <v-btn @click="readLogs">Filter</v-btn>
          </div>
        </v-col>
      </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="logs"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [25, 50, 100]
        }"
        :items-per-page="25"
        loading-text="wird geladen"
        dense
      >
        <template v-slot:item.who="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span
                v-on="on"
                class="d-inline-block text-truncate"
                style="width: 230px;"
              >{{item.who}}</span>
            </template>
            <span v-html="item.whoOrgs.join('<br/>')"></span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {genericService} from "../../services";

export default {
  name: "ChangeLogs",
  data () {
    return {
      availableOrgs: [],
      selectedOrgs: [],
      selectedTypes: [],
      availableTypes: [{
        value: 'create',
        text: 'Angelegt',
      },{
        value: 'change',
        text: 'Geändert',
      },{
        value: 'delete',
        text: 'Gelöscht',
      },],
      availableSources: [],
      selectedSources: [],
      loading: false,
      headers: [{
        text: 'Wer',
        align: 'start',
        sortable: false,
        value: 'who',
      },{
        text: 'Wen',
        align: 'start',
        sortable: false,
        value: 'whom',
      },{
        text: 'Was',
        align: 'start',
        sortable: false,
        value: 'msgType',
      },{
        text: 'Daten',
        align: 'start',
        sortable: true,
        value: 'tablename',
      },{
        text: 'Wann',
        align: 'start',
        sortable: true,
        value: 'creationdate',
      },],
      total: 0,
      options: {},
      logs: []
    }
  },
  methods: {
    readLogs () {
      this.loading = true
      this.logs = []
      genericService.post('logs/read-all.json', {
        options: {...this.options},
        orgs: this.selectedOrgs,
        types: this.selectedTypes,
        sources: this.$_.flattenDeep(this.$_.map(this.selectedSources, item => item.split(',')))
      })
        .then(data => {
          this.total = data.total
          this.logs = data.logs
        })
      .finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    genericService.get('logs/available-orgs.json')
      .then(data => {
        this.availableOrgs = data
      })
    genericService.get('logs/available-sources.json')
      .then(data => {
        this.availableSources = data
      })
  },
  watch: {
    options: {
      handler () {
        this.readLogs()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
