<template>
  <div>
  <d-action-dialog
    ref="dialog"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :validate="validate"
  >
    <template slot="title">Mitarbeiteranmeldung</template>
    <template>
      <v-card flat>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row v-if="item">
            <v-col cols="12">
              <v-card flat class="flex-grow-1">
                <v-row>
                  <v-col cols="12" class="pa-2">
                    <span class="font-weight-bold">Anmeldung vom:</span> {{ applicationDate }}
                  </v-col>
                </v-row>
                <v-row><v-col cols="12" class="background-dark white--text pa-2">Allgemeine Daten</v-col></v-row>
                <v-row>
                  <v-col cols="6">
                    <d-select label="Anrede" v-model="item.prefix" :items="prefixItems" :rules="validation.required"></d-select>
                  </v-col>
                  <v-col cols="6">
                    <d-select label="Titel" v-model="item.title" :items="titleItems"></d-select>
                  </v-col>
                  <v-col cols="6">
                    <d-text-field label="Vorname" v-model="item.firstname" :rules="validation.stringRequired"></d-text-field>
                  </v-col>
                  <v-col cols="6">
                    <d-text-field label="Nachname" v-model="item.lastname" :rules="validation.stringRequired"></d-text-field>
                  </v-col>
                  <v-col cols="6">
                    <d-autocomplete
                      label="Funktion"
                      v-model="item.funktion_id"
                      hide-no-data
                      hide-selected
                      item-text="name"
                      item-value="id"
                      :items="funktions"
                    ></d-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <d-autocomplete
                      label="Aufgabenbereich"
                      hide-no-data
                      hide-selected
                      v-model="item.task_id"
                      item-text="name"
                      item-value="id"
                      :items="tasks"
                    ></d-autocomplete>
                  </v-col>
                </v-row>
                <v-row><v-col cols="12" class="background-dark white--text pa-2">Adresse Dienstlich</v-col></v-row>
                <v-row>
                  <v-col cols="6">
                    <d-text-field
                      label="E-Mail"
                      v-model="item.office_email"
                      :rules="rules.emailValid"
                      :loading="emailChecking"
                    ></d-text-field>
                  </v-col>
                  <v-col cols="6">
                    <d-text-field label="Straße" v-model="item.office_street" :rules="validation.stringRequired"></d-text-field>
                  </v-col>
                  <v-col cols="6">
                    <d-text-field label="PLZ" v-model="item.office_zipcode" :counter="5" :rules="validation.zipcodeRequired"></d-text-field>
                  </v-col>
                  <v-col cols="6">
                    <d-text-field label="Ort" v-model="item.office_city" :rules="validation.stringRequired"></d-text-field>
                  </v-col>
                  <v-col cols="6">
                    <d-text-field label="Mobil" v-model="item.office_mobile" :rules="validation.phone"></d-text-field>
                  </v-col>
                  <v-col cols="6">
                    <d-text-field label="Telefon" v-model="item.office_phone" :rules="validation.phone"></d-text-field>
                  </v-col>
                </v-row>
                <v-row><v-col cols="12" class="background-dark white--text pa-2">Bei ehrenamtlicher Mitarbeit</v-col></v-row>
                <v-row>
                  <v-col cols="6">
                    <d-radio-group label="Ehrenamt" v-model="item.honorary" row>
                      <d-radio label="Ja" :value="1"></d-radio>
                      <d-radio label="Nein" :value="0"></d-radio>
                    </d-radio-group>
                  </v-col>
                  <v-col cols="6">
                    <d-text-field label="Meine Einrichtung" v-model="item.office_organisation" :rules="validation.string"></d-text-field>
                  </v-col>
                </v-row>
                <v-row><v-col cols="12" class="background-dark white--text pa-2">Weitere Informationen</v-col></v-row>
                <v-row>
                  <v-col cols="6">
                    <d-autocomplete
                      label="Ihre Rotkreuz Gliederung"
                      hint="Tippen um Suche zu starten"
                      v-model="item.organisation_id"
                      hide-no-data
                      item-text="name"
                      item-value="id"
                      :return-object="false"
                      :items="organisationItems"
                      :loading="orgSearching"
                      :rules="[
                        v => !!v || 'Tragen Sie den Namen ohne Gliederungstyp ein.'
                      ]"
                    ></d-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <div class="requested_access">
                      <div>Zugriff benötigt auf:</div>
                      <v-checkbox
                        label="DRK-interne Datenbanken der Gliederung"
                        hide-details="auto"
                        value="intern"
                        v-model="item.requested_access"
                      ></v-checkbox>
                      <v-checkbox
                        label="DRK-Styleguide"
                        hide-details="auto"
                        value="styleguide"
                        v-model="item.requested_access"
                      ></v-checkbox>
                      <v-checkbox
                        label="DRK-Einkaufsportal"
                        hide-details="auto"
                        value="ekportal"
                        v-model="item.requested_access"
                      ></v-checkbox>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <d-text-field
                      readonly
                      :error-messages="usernameErrors"
                      :rules="validation.stringRequired"
                      v-model="item.username"
                      error-count="2"
                      label="Login"
                    ></d-text-field>
                  </v-col>
                  <v-col cols="6">
                    <d-password
                      :readonly="passwordReadonly"
                      :rules="validation.stringRequired"
                      v-model="item.password"
                      :showPassword="showPassword"
                      label="Passwort"
                    ></d-password>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pa-2">
                    <d-select
                      label="Bearbeitungsstatus"
                      item-value="id"
                      item-text="value"
                      :items="applicationStatus"
                      v-model="item.status"
                    ></d-select>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </template>
    <template slot="actions">
      <v-spacer />
      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="form-btn mr-2" v-bind="attrs" v-on="on" :disabled="working">
            <i class="fas fa-spinner fa-spin fa-lg" v-if="working"></i>
            <span v-else>E-Mail</span>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group v-model="emailMode">
          <v-list-item @click="requestEmail">
            <v-list-item-icon color="green"><v-icon>mdi-help</v-icon></v-list-item-icon>
            <v-list-item-title>Anfrage</v-list-item-title>
          </v-list-item>
          <v-list-item @click="refusalEmail">
            <v-list-item-icon color="red"><v-icon>mdi-cancel</v-icon></v-list-item-icon>
            <v-list-item-title>Absage</v-list-item-title>
          </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn class="form-btn mr-2" color="primary" :disabled="working" @click="submitForm">
        <i class="fas fa-spinner fa-spin fa-lg" v-if="working"></i>
        <span v-else>Änderungen übernehmen</span>
      </v-btn>
      <v-btn class="form-btn mr-2" @click="deleteApplication">Löschen</v-btn>
      <v-btn class="form-btn" @click="closeDialog">Schließen</v-btn>
    </template>
  </d-action-dialog>

  <v-dialog v-model="showEmailSentDialog" max-width="500">
    <v-card>
      <v-card-title class="headline">Gesendet</v-card-title>
      <v-card-text>Email erfolgreich gesendet.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" small @click="showEmailSentDialog = false">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!--v-dialog
    v-model="emailDialog"
    max-width="500"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">Anmeldedaten</v-card-title>
      <v-card-subtitle class="grey lighten-2 pt-3">Bitte alles auswählen, kopieren und in E-Mail einfügen</v-card-subtitle>
      <v-card-text class="pt-4">
        <table style="border-collapse: collapse; table-layout: auto; width: 100%; border: 2px solid rgb(200, 200, 200);">
          <tbody>
            <tr
              v-for="(row, key) in emailItem"
              :key="key"
            >
              <td style="border: 1px solid rgb(190, 190, 190); padding: 5px 10px;">{{row.key}}</td>
              <td style="border: 1px solid rgb(190, 190, 190); padding: 5px 10px;">{{row.value}}</td>
            </tr>
          </tbody>
        </table>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="emailDialog = false"
        >
          Schliessen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog-->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { validation } from "../../helpers";
import { applicationService, organisationService } from "../../services";
import { userService } from "../../services";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    item: {
      type: Object,
      required: false,
      default: () => {
        return {
          prefix: 1,
          title: 0,
          firstname: '',
          lastname: '',
          username: '',
          password: '',
          funktion_id: '',
          task_id: '',
          office_email: '',
          office_street: '',
          office_zipcode: '',
          office_city: '',
          office_mobile: '',
          office_phone: '',
          honorary: 0,
          office_organisation: '',
          organisation_id: 0,
          requested_access: ['intern'],
        }
      }
    }
  },
  data() {
    return {
      working: false,
      validation: validation,
      valid: false,
      organisationItems: [],
      orgSearching: false,
      emailChecking: false,
      emailUnique: true,
      rules: {
        emailValid: validation.emailRequired.concat([
          () => this.emailUnique || 'Die E-Mailadresse ist schon vergeben.'
        ]),
      },
      passwordReadonly: false,
      showPassword: false,
      usernameErrors: [],
      emailDialog: false,
      emailMode: null,
      showEmailSentDialog: false,
    }
  },
  computed: {
    ...mapState({
      prefixValues: state => state.option.prefix,
      titleValues: state => state.option.title,
      tasks: state => state.tasks.rawList,
      funktions: state => state.functions.rawList,
      applicationStatus: state => state.applications.stati
    }),
    ...mapGetters({
      status: 'applications/status',
      funktion: 'functions/funktion',
      task: 'tasks/task',
      prefix: 'option/prefix',
      title: 'option/title',
    }),
    defaultItem: () => ({
      prefix: 1,
      title: 0,
      firstname: '',
      lastname: '',
      username: '',
      password: '',
      funktion_id: '',
      task_id: '',
      office_email: '',
      office_street: '',
      office_zipcode: '',
      office_city: '',
      office_mobile: '',
      office_phone: '',
      honorary: 0,
      office_organisation: '',
      organisation_id: 0,
      requested_access: 'intern'
    }),
    emailItem () {
      if (!this.item) {
        return []
      }
      return [{
        key: 'Anrede',
        value: this.prefix(this.item.prefix)
      },{
        key: 'Titel',
        value: this.title(this.item.title)
      },{
        key: 'Vorname',
        value: this.item.firstname,
      },{
        key: 'Nachname',
        value: this.item.lastname,
      },{
        key: 'Funktion',
        value: this.funktion(this.item.funktion_id),
      },{
        key: 'Aufgabe',
        value: this.task(this.item.task_id)
      },{
        key: 'E-Mail',
        value: this.item.office_email,
      },{
        key: 'Straße',
        value: this.item.office_street,
      },{
        key: 'PLZ',
        value: this.item.office_zipcode,
      },{
        key: 'Ort',
        value: this.item.office_city,
      },{
        key: 'Mobil',
        value: this.item.office_mobile,
      },{
        key: 'Telefon',
        value: this.item.office_phone,
      },{
        key: 'Ehrenamtlich',
        value: this.item.honorary ? 'Ja' : 'Nein',
      },{
        key: 'Meine Einrichtung/Gliederung',
        value: this.item.office_organisation,
      },{
        key: 'DRK Gliederung',
        value: this.item.typed_org,
      },{
        key: 'Passwort',
        value: '*****' === this.item.password ? '(selbst vergeben)' : this.item.password,
      },{
        key: 'Bearbeitungsstatus',
        value: this.status(this.item.status),
      },]
    },
    prefixItems() {
      if (!this.prefixValues) {
        return [];
      }
      let items = [];
      for (const [key, item] of Object.entries(this.prefixValues)) {
        items.push({
          value: 1*key,
          text: item
        });
      }
      return items;
    },
    titleItems() {
      if (!this.titleValues) {
        return [];
      }
      return this.titleValues.map((item, key) => {
        return {
          value: key,
          text: item
        };
      });
    },
    applicationDate () {
      if (this.item && this.item.fromdate) {
        /**
         * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
         */
        return new Intl.DateTimeFormat('de-DE',{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }).format(Date.parse(this.item.fromdate))
      }
      return 'keine Angabe'
    }
  },
  methods: {
    ...mapActions('option', ['loadOption']),
    ...mapActions('tasks', {taskList: 'list'}),
    ...mapActions('functions', {fktList: 'list'}),
    ...mapActions('applications', ['refuse', 'edit', 'localEdit']),
    validate() {
      if (this.$refs.form) {
        this.valid = this.$refs.form.validate();
      }
      return this.valid;
    },
    closeDialog () {
      this.showPassword = false
      this.passwordReadonly = false
      this.$emit('input', false)
    },
    async submitForm() {
      if (!this.working && this.validate() && this.valid) {
        this.working = true;

        const res = this.edit(this.item)
        this.working = false;
        if (false !== res) await this.closeDialog();
      }
    },
    deleteApplication () {
      if (this.item && this.item.id) {
        this.refuse(this.item.id)
        this.closeDialog()
      }
    },
    checkEmailIsUnique() {
      const email = this.item.office_email;
      if (email && 5 < email.length) {
        this.emailChecking = true
        userService.emailUnique({
          email,
          applicantId: this.item.id
        })
          .then(
            res => {
                this.emailUnique = res
            }
          )
          .finally(() => {
            this.emailChecking = false
            this.validate()
          })
      }
    },
    searchOrg (force = false) {
      if (force || (this.orgSearch && 3 < this.orgSearch.length)) {
        this.orgSearching = true;
        organisationService.listByName(this.orgSearch)
          .then(
            orgs => {
              this.organisationItems = orgs
            },
            () => {
              this.organisationItems = []
            }
          )
          .finally(() => {
            this.orgSearching = false;
          })
      }
    },
    async refusalEmail () {
      this.working = true
      const sn = {
        id: this.item.id,
        status: 2,
        status_name: this.applicationStatus.find(s => s.id === 2).value
      }
      applicationService.sendRefusalEmail(this.item.id)
        .then(
          () => {
            this.localEdit(sn)
            this.showEmailSentDialog = true
          },
          () => {
            this.$store.dispatch('alert/error', 'Fehler beim Senden der Absagemail', {root: true})
          }
        )
        .finally(() => {
          this.working = false
        })
    },
    async requestEmail () {
      this.working = true
      const sn = {
        id: this.item.id,
        status: 1,
        status_name: this.applicationStatus.find(s => s.id === 1).value
      }
      applicationService.sendRequestEmail(this.item.id)
        .then(
          () => {
            this.localEdit(sn)
            this.showEmailSentDialog = true
          },
          () => {
            this.$store.dispatch('alert/error', 'Fehler beim Senden der Anfrageemail', {root: true})
          }
        )
        .finally(() => {
          this.working = false
        })
    }
  },
  created () {
    // wait for 500ms after last keystroke before sending requests
    this.debouncedCheckEmailIsUnique = this.$_.debounce(this.checkEmailIsUnique, 500)
  },
  watch: {
    'item.org': {
      immediate: true,
      handler () {
        if (this.item && this.item.org) {
          this.orgSearch = this.item.org
          this.searchOrg()
        }
      }
    },
    'item.office_email' () {
      this.debouncedCheckEmailIsUnique();
    },
    'item.organisation_id' () {
      userService.getUniqueUsername({
        firstname: this.item.firstname,
        lastname: this.item.lastname,
        organisation_id: this.item.organisation_id
      })
      .then(
        username => {
          this.item.username = username
          this.usernameErrors = []
        },
        error => {
          this.usernameErrors.push(error);
        }
      )
    },
    value () {
      this.searchOrg(true)
      this.loadOption({option: 'prefix'});
      this.loadOption({option: 'title'});
      this.taskList();
      this.fktList();
    }
  }
}
</script>

<style scoped>
  .requested_access {
    margin-top: 0;
    padding-top: 0;
  }
  .requested_access >>> legend {
    margin-bottom: 5px;
  }
  .requested_access >>> .v-radio {
    margin-bottom: 0 !important;
  }
</style>
