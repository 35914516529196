<template>
  <module-template :name="name" v-model="value">
    <v-row class="module-form-row">
      <v-col cols="12">
        <d-switch
          label="Download"
          hide-details
          :disabled="!canEditPermissions"
          v-model="value.isActive"
        ></d-switch>
      </v-col>
    </v-row>
  </module-template>
</template>

<script>
import { mapState } from 'vuex'
import moduleTemplate from './module-template'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    moduleTemplate
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl
    }),
    canEditPermissions() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_authorization_edit;
    }
  }
}
</script>
