<template>
  <div>
    <d-expander class="mb-4 expander-no-padding">
      <template slot="header">
        <v-toolbar-title>Verträge</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu>
          <template v-slot:activator="{ on:menu }">
            <v-tooltip top>
              <template v-slot:activator="{ on:tooltip }">
                <v-btn
                  icon
                  v-on="{...tooltip, ...menu}"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              <span>Vertrag hinzufügen</span>
            </v-tooltip>
          </template>
          <v-list dense>
            <v-list-item
              dense
              v-for="(item, index) in contractTypes"
              :key="index"
              @click="addContract(item)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template>
        <v-container flat outlined class="flex-grow-1 pa-0">
          <v-row
            v-for="(item, key) in contractItems"
            :key="key"
            no-gutters
            style="border-top: thin solid rgba(0,0,0,.1) !important;"
            class="px-2 body-2 text--secondary base-data-row flex-nowrap"
            align="stretch"
            :class="{'form-row-even': key % 2 === 0}"
          >
            <div class="d-flex align-center py-2">{{ item.name }}</div>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on"><v-icon>info</v-icon></v-btn>
              </template>
              <span>{{ item.edited }}</span>
            </v-tooltip>
            <v-btn @click.stop="showContractFilesDialog(item)" icon>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">edit</v-icon>
                </template>
                <span>Dateien</span>
              </v-tooltip>
            </v-btn>
          </v-row>
        </v-container>
      </template>
    </d-expander>
    <d-expander class="expander-no-padding">
      <template slot="header">
        <v-toolbar-title>Nachrichten</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="!contractMessageWrite" @click.stop="writeContractMessage" icon>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">add</v-icon>
            </template>
            <span>Nachricht verfassen</span>
          </v-tooltip>
        </v-btn>
      </template>
      <template>
        <v-container flat outlined class="flex-grow-1 pa-0">
          <v-row v-if="contractMessageWrite" class="mx-0" style="border-top: thin solid rgba(0,0,0,.1) !important;">
            <v-col cols="12" class="pa-0">
              <v-textarea
                v-model="contractMessageContent"
                rows="1"
                auto-grow
                autofocus
                solo
                flat
                hide-details
                class="flex-grow-1"
              >
                <template slot="append">
                  <v-btn :disabled="contractMessageContent.length <= 0" @click.stop="sendContractMessage" icon>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">send</v-icon>
                      </template>
                      <span>Nachricht absenden</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn @click.stop="deleteContractMessage" icon>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">clear</v-icon>
                      </template>
                      <span>Abbrechen</span>
                    </v-tooltip>
                  </v-btn>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
          <v-row
            v-for="(item, key) in contractMessages"
            :key="key"
            no-gutters
            style="border-top: thin solid rgba(0,0,0,.1) !important;"
            class="px-2 body-2 text--secondary base-data-row flex-nowrap"
            align="stretch"
            :class="{'form-row-even': key % 2 === 0}"
            :style="{'border-left': item.auto_generated ? 'solid red 2px !important' : 'inherit'}"
          >
            <div class="d-flex align-center flex-grow-1 py-2" style="min-width: 0;">
              <div :class="{'text-truncate': !item.show_all}">{{ item.message }}</div>
            </div>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on"><v-icon>info</v-icon></v-btn>
              </template>
              <span>{{ item.created }}</span>
            </v-tooltip>
            <v-btn @click.stop="item.show_all = !item.show_all" icon>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">{{ item.show_all ? 'visibility_off' : 'visibility' }}</v-icon>
                </template>
                <span>{{ item.show_all ? 'Gekürzte Nachricht anzeigen' : 'Vollständige Nachricht anzeigen' }}</span>
              </v-tooltip>
            </v-btn>
          </v-row>
        </v-container>
      </template>
    </d-expander>

    <contract-files
      v-model="contractFilesDialog"
      :type="contractType"
    ></contract-files>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import ContractFiles from "./ContractFiles";
import { map } from 'lodash'

export default {
  name: "Contracts",
  components: {
    ContractFiles
  },
  props: {
    currentOrgId: Number
  },
  data () {
    return {
      contractFilesDialog: false,
      contractType: '',

      contractMessageWrite: false,
      contractMessageContent: ''
    }
  },
  computed: {
    ...mapState({
      organisation: state => state.organisation,
      acl: state => state.account.acl,
      contractTypeItems: state => state.option.contract_types,
      contractItems: state => state.contracts.contracts,
      contractMessages: state => state.contracts.messages,
    }),
    ...mapGetters({
      fullRec: 'organisation/fullRec',
    }),
    contractTypes () {
      const defined = map(this.contractItems, item => item.type)
      return this.contractTypeItems.filter(item => -1 === defined.indexOf(item.type))
    },
  },
  methods: {
    ...mapActions('option', ['loadOption']),
    ...mapActions('contracts', [
      'loadContracts', 'newContract', 'cleanUpContracts', 'loadMessages', 'sendMessage'
    ]),
    addContract(contract) {
      const item = {
        name: contract.name,
        type: contract.type,
        edited: 'noch nicht gespeichert',
        files: []
      }
      this.newContract(item)
      this.showContractFilesDialog(item)
    },
    showContractFilesDialog(item) {
      this.contractType = item.type;
      this.contractFilesDialog = true;
    },
    writeContractMessage() {
      this.contractMessageWrite = true;
      this.contractMessageContent = '';
    },
    sendContractMessage() {
      this.sendMessage({
        organisation_id: this.fullRec.id.value,
        message: this.contractMessageContent
      });
      this.deleteContractMessage();
    },
    deleteContractMessage() {
      this.contractMessageWrite = false;
      this.contractMessageContent = '';
    },
    canSeeContracts() {
      if (!this.acl) {
        return false;
      }
      return this.acl.organisation_own_contract_management ||
             this.acl.organisation_all_contract_management;
    }
  },
  created() {
    this.loadOption({option: 'contract_types'})
  },
  watch: {
    currentOrgId: {
      immediate: true,
      handler(newId) {
        if (0 < newId && this.canSeeContracts()) {
          this.loadContracts(this.currentOrgId)
          this.loadMessages(this.currentOrgId)
        }
      }
    },
    contractFilesDialog() {
      if (!this.contractFilesDialog) {
        this.cleanUpContracts()
      }
    }
  }
}
</script>

<style scoped>
.v-expansion-panels >>> .v-expansion-panel-content {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}
.expander-no-padding >>> .v-expansion-panel-content {
  padding: 0px !important;
}
</style>
