<style scoped>
.search-row {
  align-items: center;
  justify-content: center;
}
.text-search {
  width: 35vw;
  min-width: 200px;
}
.btn-search {
  width: 100px;
  top: -15px;
}
</style>

<template>
  <div class="flex-grow-1 fill-height">
    <navbar />
    <div class="background-dark flex-grow-1 fill-height">
      <v-form
        ref="form"
        v-model="valid"
      >
      <div class="d-flex pa-5 search-row">
        <div class="text-search">
            <d-text-field
              label="Suchbegriff"
              v-model="query"
              :rules="[rules.required, rules.min]"
              :loading="searching"
              @keydown.enter.stop.prevent="onKeydown"
            ></d-text-field>
        </div>
        <v-btn class="btn-search ml-5" color="primary" @click.stop="doSearch">Suchen</v-btn>
      </div>
      </v-form>
      <v-row class="ma-2">
        <v-col cols="12">
          <v-card
            flat
            outlined
          >
            <v-toolbar dense flat>
              <v-toolbar-title class="body-1 flex-grow-1">
                <v-row class="search-row">
                  <v-col cols="6" sm="7" md="8" lg="9">
                    Suchergebnisse
                  </v-col>
                  <v-col cols="6" sm="5" md="4" lg="3">
                    <d-select hide-details dense v-model="orderSelected" :items="orderModes"></d-select>
                  </v-col>
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-tabs
              v-model="tab"
              background-color="blue-grey lighten-5"
              class="fill-height tab-container"
            >
              <v-tab>Personen</v-tab>
              <v-tab>DRK-Gliederung</v-tab>

              <v-tabs-items
                v-model="tab"
                class="pt-4 blue-grey lighten-5 flex-grow-1"
              >
                <v-tab-item class="px-3">
                  <v-row>
                    <v-col cols="12" md="6" lg="4" v-for="(item, key) in sortedUsers" :key="key">
                      <v-card>
                        <v-card-title>{{ item.name }}</v-card-title>
                        <v-card-text>
                          <p>{{ 'ID: ' + item.id + ' - ' + item.type }}</p>
                          <p>E-Mail: <a v-if="item.email" :href="'mailto:' + item.email">{{ item.email }}</a><span v-else>n.a.</span></p>
                          <p v-for="(subitem, subkey) in item.users" :key="subkey">
                            <v-icon>save</v-icon>
                            <a @click.stop="toUser(subitem)">{{ subitem.orgName }} (Code: {{subitem.orgCode}})</a>
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="px-3">
                  <v-row>
                    <v-col cols="12" md="6" lg="4" v-for="(item, key) in sortedOrgs" :key="key">
                      <v-card>
                        <v-card-title>{{ item.name }}</v-card-title>
                        <v-card-text>
                          <p>E-Mail: <a v-if="item.email" :href="'mailto:' + item.email">{{ item.email }}</a><span v-else>n.a.</span></p>
                          <p>{{ 'Code: ' + item.code }}</p>
                          <p>{{ 'Stadt: ' + item.city }}</p>
                          <v-icon>save</v-icon>
                          <a @click.stop="toOrg(item)">{{ item.name }}</a>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Navbar from "./layout/Navbar";
import {mapActions, mapState} from "vuex";

export default {
  name: "SearchPage",
  components: {
    Navbar
  },
  data() {
    return {
      query: null,
      orderSelected: 0,
      orderModes: [
        {
          value: 0,
          text: '-',
          sort() {
            return 0;
          }
        },
        {
          value: 1,
          text: 'Alphabetisch A-Z',
          sort(a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }
        },
        {
          value: 2,
          text: 'Alphabetisch Z-A',
          sort(a, b) {
            if (a.name < b.name) {
              return 1;
            }
            if (a.name > b.name) {
              return -1;
            }
            return 0;
          }
        },
        {
          value: 3,
          text: 'ID / Code aufsteigend',
          sort(a, b) {
            if (a.id !== undefined) {
              if (a.id < b.id) {
                return -1;
              }
              if (a.id > b.id) {
                return 1;
              }
            } else {
              if (a.code < b.code) {
                return -1;
              }
              if (a.code > b.code) {
                return 1;
              }
            }
            return 0;
          }
        },
        {
          value: 4,
          text: 'ID / Code absteigend',
          sort(a, b) {
            if (a.id !== undefined) {
              if (a.id < b.id) {
                return 1;
              }
              if (a.id > b.id) {
                return -1;
              }
            } else {
              if (a.code < b.code) {
                return 1;
              }
              if (a.code > b.code) {
                return -1;
              }
            }
            return 0;
          }
        }
      ],
      tab: 0,
      valid: false,
      rules: {
        required: value => !!value || 'Notwendig.',
        min: v => v && v.length >= 4 || 'Min 4 Zeichen',
      }
    }
  },
  computed: {
    ...mapState({
      foundUsers: state => state.search.users,
      foundOrgs: state => state.search.orgs,
      searching: state => state.search.searching
    }),
    sortingMode() {
      let mode = this.orderModes.find(mode => mode.value === this.orderSelected);
      if (mode === undefined) {
        return undefined;
      }
      return mode.sort
    },
    sortedUsers() {
      return [...this.foundUsers].sort(this.sortingMode);
    },
    sortedOrgs() {
      return [...this.foundOrgs].sort(this.sortingMode);
    }
  },
  methods: {
    ...mapActions('search', ['search']),
    doSearch() {
      if (this.valid) {
        this.search({query: this.query});
      }
    },
    toUser(user) {
      this.$router.push({
        name: 'verzeichnis',
        params: {
          org_id: user.orgId,
          user_id: user.id
        }
      });
    },
    toOrg(org) {
      this.$router.push({
        name: 'verzeichnis',
        params: {
          org_id: org.id
        }
      });
    },
    onKeydown(event) {
      if ('Enter' === event.key) {
        this.doSearch()
      }
    }
  }
}
</script>
